import { Grid } from '@mui/material'
import React from 'react'
import Header from '../Component/FillForm/Header'
import Forms from '../Component/FillForm/Forms'

const FillForm = () => {
  return (
    <Grid>
        <Header />
        <Forms />
    </Grid>
  )
}

export default FillForm