import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React from 'react';
import { useListResponse } from '../../Context/ListResponse';
import { convertToPersianDate } from "../../helpers/ConvertDate";
import { convertToPersianTime } from "../../helpers/ConvertTime";
import Loading from '../Loading/Loading';

const ResponseFormList = () => {
    const { listResponse } = useListResponse();

    return (
        <>
            <Loading />
            <TableContainer component={Paper} sx={{ maxWidth: '70%', mx: 'auto', mt: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{fontSize:'12px'}}><strong>ID</strong></TableCell>
                            <TableCell align="center" style={{fontSize:'12px'}}><strong>تاریخ تکمیل فرم</strong></TableCell>
                            <TableCell align="center" style={{fontSize:'12px'}}><strong>زمان تکمیل فرم</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listResponse?.map((response, index) => (
                            <TableRow key={index} sx={{ cursor: 'pointer' }}>
                                <TableCell align="center" style={{fontSize:'10px'}}>{response.id}</TableCell>
                                <TableCell align="center" style={{fontSize:'10px'}}>{convertToPersianDate(response.submittedAt.split(' ')[0])}</TableCell>
                                <TableCell align="center" style={{fontSize:'10px'}}>{convertToPersianTime(response.submittedAt.split(' ')[1])}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ResponseFormList;
