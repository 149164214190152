import axios from "axios";
import {toast} from "react-toastify";
import CustomToast from "../Component/CustomToast/CustomToast";
import React from "react";
import {actionUrls} from "../api/GetMainPageFormApi";

export default function handleUpdateStatusForm(formIdUpdateStatus , status) {


    let data = {
        status:status
    }

    const token = localStorage.getItem('authToken');

    axios.patch(`${actionUrls.updateStatus}${formIdUpdateStatus}/status`, data , {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }).then((response) => {
    }).catch((error) => {
        console.error('Error sending data to the server:', error);
    });

}