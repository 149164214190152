import { Box, Button, Checkbox, Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-modern-drawer/dist/index.css'
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { DrawerContext } from '../Context/DrawerContext'
import { toast } from 'react-toastify'
import { animated, useTransition } from '@react-spring/web';
import EditIcon from '@mui/icons-material/Edit';
import { useFormContext } from '../Context/Context'
import { useEditMode } from '../Context/EditModeContext'
import { useTitleContext } from '../Context/TitleContext'
import axios from 'axios'
import { useRemovedItems } from '../Context/RemovedItemsContext'
import { useArchivedItems } from '../Context/ArchivedItemsContext'
import { useFavoriteItems } from '../Context/FavoriteItemsContext'
import MainMessage from './MainMessage'
import { sectionUrls, useSectionUrls } from "../api/GetMainPageFormApi";
import { sortItems } from "../Constant/MainNavbarSortItems";
import CustomToast from "./CustomToast/CustomToast";
import MobileMainDrawer from "./MobileMainDrawer";
import MainNavbarButtonAction from "./MainNavbarButtonAction/MainNavbarButtonAction";
import Loading from "./Loading/Loading";
import { useList } from "../Context/ListContext";
import { useStatusForm } from "../Context/StatusFormContext";
import { useLoading } from "../Context/LoadingContext";
import PaginationForms from "./Pagination/PaginationForms";
import { usePageCount } from "../Context/PageCount";
import { useActiveFilter } from '../Context/ActiveFilter';
import { handleGetListResponse, useHandleGetListResponse } from '../helpers/HandleGetListResponse';
import { useListResponse } from '../Context/ListResponse';


const MainNavbar = () => {




    const { selectDrawerItem, setSelectDrawerItem } = useContext(DrawerContext);
    const { drawerData, setDrawerData } = useFormContext();
    const { setEditMode } = useEditMode();
    const { title, setTitle } = useTitleContext();
    const { favoriteItems, setFavoriteItems } = useFavoriteItems();
    const { list, setList } = useList();
    const { statusForms, setStatusForms, saveStatusForm } = useStatusForm();
    const sectionUrls = useSectionUrls();
    const { setLoading } = useLoading()
    const { pageCount, setPageCount } = usePageCount()
    const { activeFilter, setActiveFilter } = useActiveFilter();
    const { setListResponse } = useListResponse();



    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const [checked, setChecked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [active, setActive] = useState(false);
    const [heading, setHeading] = useState('');
    const [error, setError] = useState(null);
    const [formFullDetails, setFormFullDetails] = useState(null)
    const [switchSection, setSwitchSection] = useState('all')
    const [filteredListData, setFilteredListData] = useState(null)
    const [activeForm, setActiveForm] = useState([])
    const [count, setCount] = useState(null);

    const dropdownRef = useRef(null);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }


    const transitions = useTransition(active, {
        from: { opacity: 0, transform: 'translateY(-20px)' },
        enter: { opacity: 1, transform: 'translateY(0)' },
        leave: { opacity: 0, transform: 'translateY(-20px)' },
    });



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const token = localStorage.getItem('authToken');


    const getCurrentListSetter = () => {
        return setList;
    };


    const getCurrentList = () => {
        return list;
    };


    const handleFilterForm = (heading, status, id) => {
        setHeading(heading)
        saveStatusForm(status)
        setActiveFilter(id)
        localStorage.setItem('activeFilter', id)
    }

    const handleGetListForm = () => {

        setLoading(true);

        axios
            .get(sectionUrls.AllForm, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log(response, 'response')
                setLoading(false)
                setList(response.data.output.forms);
                setCount(response.data.output.pageCount);
            })
            .catch((err) => {
                setError(err); // Handle the error
                console.error("Error fetching data:", err);
            });
    }

    const handleChangeCheckBox = (id) => (event) => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const updatedList = targetList.map(item =>
            item.id === id ? { ...item, checked: event.target.checked } : item
        );

        const activeFormIds = updatedList
            .filter(item => item.checked) // Only keep checked items
            .map(item => item.id);       // Extract their IDs

        setActiveForm(activeFormIds);
        setTargetList(updatedList);
    };



    const handleChangeStarCheckBox = (id) => (event) => {

        setLoading(true)

        const apiUrl = `https://formmaker-api.altynlogistics.com/api/form/${id}/bookmark`


        axios.post(apiUrl, {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.output.bookmark) {
                setLoading(false)
                toast(<CustomToast message={'فرم مورد نظر به لیست علاقمندی ها اضافه شد'} state={'success'} />);
                handleGetListForm()
            } else {
                toast(<CustomToast message={'فرم مورد نظر از لیست علاقمندی ها حذف شد'} state={'success'} />);
                handleGetListForm()
            }
        }).catch((error) => {
            console.error('Error sending data to the server:', error);
        });

    };


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };


    const isAnyItemChecked = getCurrentList()?.some(item => item.checked);




    const handleGetFormDetails = async (id, status) => {

        if (!token) {
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(
                `https://formmaker-api.altynlogistics.com/api/form/${id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            const { content } = response.data.output;

            let parsedForms = null;
            try {
                parsedForms = JSON.parse(content.forms);
            } catch (parseError) {
                console.error("Error parsing forms JSON:", parseError);
                setError("Invalid form data received from the server");
                return;
            }

            if (!parsedForms || !parsedForms.sendToServerData) {
                console.error("Parsed forms structure is invalid");
                setError("Form data is incomplete");
                return;
            }

            setFormFullDetails(parsedForms);
            setDrawerData(parsedForms.sendToServerData);
            setTitle(content.title);
            setEditMode(true);
            if (status === 'edit') {
                navigate('/build');
            } else if (status === 'fillForm') {
                navigate(`/User/FillForm/${id}`);
                localStorage.setItem('FormId', id)
            }
        } catch (err) {
            setError(err.message || "An error occurred"); // Handle and display error
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };


    const handleGetListResponseForm = (id)=>{
        setLoading(true);
        const { data, error } =  handleGetListResponse(id, setListResponse);
        if (error) setError(error);
        setLoading(false);
        navigate(`/Form/ResponseList/${id}`);
    }


    const copyFormLink = (id) => {
        const formLink = `${window.location.origin}/User/FillForm/${id}`;
        navigator.clipboard.writeText(formLink)
            .then(() => { toast(<CustomToast message={'لینک با موفقیت کپی شد'} state={'success'} />) })
            .catch(err => { toast(<CustomToast message={'کپی لینک ناموفق بود'} state={'not'} />) });
    };



    const initializeListData = (data) => {
        return data?.map(item => ({
            ...item,
            checked: item.checked || false // Default to false if `checked` doesn't exist
        }));
    };


    useEffect(() => {
        const initializedData = initializeListData(list);
        let filter = initializedData?.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredListData(filter)
    }, [list, searchTerm]);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        setFavoriteItems(favoriteItems);
    }, [favoriteItems, setFavoriteItems]);


    useEffect(() => {
        setSwitchSection(sectionUrls[selectDrawerItem] || '');
    }, [selectDrawerItem]);


    useEffect(() => {

        handleGetListForm()

    }, [switchSection, statusForms, pageCount]);



    useEffect(() => {
        setPageCount(1)
    }, [])


    return (
        <Grid width={{ xs: '100%' }} display={'block'}>
            <Loading />
            <Grid width={{ xs: '100%' }} p={{ xs: '14px 10px', md: '9px 13px' }} display={'flex'} alignItems={'center'} justifyContent={{ xs: 'space-between', md: 'flex-end' }} bgcolor={'white'}>
                <Box display={{ xs: 'block', md: 'none' }}>
                    <Grid onClick={toggleDrawer} display={'flex'} alignItems={'center'} p={'0px 10px'} border={'1px solid rgba(200,206,237,1)'} width={'40px'} height={'40px'} borderRadius={'4px'}>
                        <img src='/assets/images/threeDots.svg' alt='' style={{ width: '100%', height: '100%' }} />
                    </Grid>
                </Box>
                <MobileMainDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
                {
                    isAnyItemChecked ?
                        (
                            <MainNavbarButtonAction selectDrawerItem={selectDrawerItem} listId={activeForm} />
                        )
                        :
                        (

                            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                <Grid sx={{ position: 'relative' }}>
                                    <input
                                        style={{
                                            height: '40px',
                                            borderRadius: '4px',
                                            color: 'rgba(10,21,81,1)',
                                            border: '1px solid rgba(200,206,237,1)',
                                            backgroundColor: 'rgba(255,255,255,1)',
                                            outline: 'rgba(0,0,0,1)',
                                            padding: '0px 32px'
                                        }}
                                        placeholder='جستجو'
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <Grid width={'20px'} height={'20px'} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                                        <img src='/assets/images/magnifyingGlass.svg' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                </Grid>
                                <Grid style={{ position: 'relative', cursor: 'pointer' }} ref={dropdownRef}>
                                    <Grid p={'0px 10px'} border={active ? '1px solid rgb(44, 51, 69)' : '1px solid rgba(200,206,237,1)'} width={'40px'} height={'40px'} borderRadius={'4px 4px 4px 0px'} onClick={() => { setActive(!active) }} bgcolor={active ? 'rgb(44, 51, 69)' : 'white'}>
                                        <img src='/assets/images/sort.svg' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                    {transitions(
                                        (styles, item) =>
                                            item && (
                                                <animated.div
                                                    style={{
                                                        ...styles,
                                                        position: 'absolute',
                                                        top: '38px',
                                                        left: '0px',
                                                        zIndex: 100,
                                                        backgroundColor: 'rgb(44, 51, 69)',
                                                        borderRadius: '0px 4px 4px 4px',
                                                        padding: '8px',
                                                        width: '244px',
                                                    }}
                                                >
                                                    {sortItems.map((item) => (
                                                        <Button
                                                            onClick={() => handleFilterForm(item.title, item.status, item.id)}
                                                            key={item.id}
                                                            sx={{
                                                                backgroundColor: item.id === activeFilter ? 'white' : 'transparent',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                color: item.id === activeFilter ? 'rgb(44, 51, 69)' : 'white',
                                                                width: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                '&:hover': { backgroundColor: 'white', color: 'rgb(44, 51, 69)' },
                                                            }}
                                                        >
                                                            {item.title}
                                                        </Button>
                                                    ))}
                                                </animated.div>
                                            )
                                    )}
                                </Grid>
                            </Box>
                        )
                }

            </Grid>
            <Grid style={{ height: '600px', maxHeight: '600px', overflowY: 'auto' }}>
                {
                    filteredListData?.length > 0
                        ?
                        <>
                            {
                                filteredListData.map(
                                    (item, index) =>
                                        <Grid key={index} width={'100%'} px={'14px'}>
                                            <Button sx={{
                                                p: '14px 23px',
                                                m: '18px 0px',
                                                bgcolor: 'transparent',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                '&:hover': { bgcolor: 'white' }
                                            }}>
                                                <Grid sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    gap: '24px'
                                                }}>
                                                    <input
                                                        className='check'
                                                        type='checkbox'
                                                        style={{
                                                            width: checked ? '18px' : '18px',
                                                            height: checked ? '18px' : '18px',
                                                            margin: '0px',
                                                            display: selectDrawerItem === 'Favorite' ? 'none' : 'block'
                                                        }}
                                                        checked={item.checked || false}
                                                        onChange={handleChangeCheckBox(item.id)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                    <Grid width={'36px'} height={'36px'}
                                                        display={selectDrawerItem === 'AllForm' ? 'block' : 'none'}>
                                                        <Checkbox
                                                            style={{ width: '36px', height: '36px' }}
                                                            {...label}
                                                            icon={<StarOutlineIcon fontSize='large' />}
                                                            checkedIcon={<StarIcon style={{ color: '#ffb629' }}
                                                                fontSize='large' />}
                                                            checked={item.bookmark}
                                                            onChange={handleChangeStarCheckBox(item.id)}
                                                        />
                                                    </Grid>
                                                    <Grid width={'32px'} height={'32px'}>
                                                        <img src='/assets/images/checklist.png' alt=''
                                                            style={{ width: '100%', height: '100%' }} />
                                                    </Grid>
                                                    <Grid pt={'4px'}>
                                                        <Typography variant='h4' color={'rgb(10, 21, 81)'}
                                                            fontWeight={700}>{item.title}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                    <Button sx={{
                                                        display: selectDrawerItem === 'AllForm' || selectDrawerItem === 'Drafts' ? 'flex' : 'none',
                                                        alignItems: "center",
                                                        justifyContent: 'center',
                                                        width: '24px',
                                                        height: '48px',
                                                        '&:hover': { variant: 'contained' }
                                                    }}
                                                        onClick={() => {
                                                            copyFormLink(item.id);
                                                        }}
                                                    >
                                                        <Grid width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                            <Tooltip
                                                                title={<Typography variant='subtitle1'>کپی لینک فرم</Typography>}
                                                                arrow>
                                                                <img src="./assets/images/copy-link.svg" alt="" style={{ width: '32px', height: '32px' }} />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Button>
                                                    <Button sx={{
                                                        display: selectDrawerItem === 'AllForm' || selectDrawerItem === 'Drafts' ? 'flex' : 'none',
                                                        alignItems: "center",
                                                        justifyContent: 'center',
                                                        width: '24px',
                                                        height: '48px',
                                                        '&:hover': { variant: 'contained' }
                                                    }}
                                                        onClick={() => {
                                                            handleGetFormDetails(item.id, 'fillForm');
                                                        }}
                                                    >
                                                        <Grid width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                            <Tooltip
                                                                title={<Typography variant='subtitle1'>پر کردن فرم</Typography>}
                                                                arrow>
                                                                <img src="./assets/images/fillForm.svg" alt="" style={{ width: '32px', height: '32px' }} />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Button>
                                                    <Button sx={{
                                                        display: selectDrawerItem === 'AllForm' || selectDrawerItem === 'Drafts' ? 'flex' : 'none',
                                                        alignItems: "center",
                                                        justifyContent: 'center',
                                                        width: '24px',
                                                        height: '48px',
                                                        '&:hover': { variant: 'contained' }
                                                    }} onClick={() => {
                                                        handleGetFormDetails(item.id, 'edit')
                                                    }}>
                                                        <Grid width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                            <Tooltip
                                                                title={<Typography variant='subtitle1'>ویرایش</Typography>}
                                                                arrow>
                                                                <EditIcon fontSize='large' />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Button>
                                                    <Button sx={{
                                                        display: selectDrawerItem === 'AllForm' || selectDrawerItem === 'Drafts' ? 'flex' : 'none',
                                                        alignItems: "center",
                                                        justifyContent: 'center',
                                                        width: '24px',
                                                        height: '48px',
                                                        '&:hover': { variant: 'contained' }
                                                    }} onClick={() => {
                                                        handleGetListResponseForm(item.id)
                                                    }}>
                                                        <Grid width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                            <Tooltip
                                                                title={<Typography variant='subtitle1'>لیست پاسخ های فرم</Typography>}
                                                                arrow>
                                                                <img src="./assets/images/list-response.svg" alt="" style={{ width: '32px', height: '32px' }} />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Button>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                )
                            }
                            <Grid mt={'10%'} display={'flex'} justifyContent={'center'} alignItems={'center'} dir={'rtl'}>
                                <PaginationForms count={count} />
                            </Grid>
                        </>
                        :
                        <Grid width={'300px'} display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>
                            <MainMessage search={searchTerm} />
                        </Grid>
                }
            </Grid>

        </Grid>
    )
}

export default MainNavbar