import axios from "axios";
import { useState } from "react";
import { useList } from "../Context/ListContext";
import { useSectionUrls } from "../api/GetMainPageFormApi";

export function useHandleGetListForm() {
    const sectionUrls = useSectionUrls();
    const { setList } = useList();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchList = async () => {
        setLoading(true);
        setError(null);

        const token = localStorage.getItem("authToken");

        try {
            const response = await axios.get(sectionUrls.AllForm, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("Data successfully fetched from the server:", response.data);
            setList(response.data.output.forms);
        } catch (err) {
            console.error("Error fetching data:", err);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { fetchList, loading, error };
}
