import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../Component/CustomToast/CustomToast";

export default function handleUnArchiveForm(formIdArchived, fetchList, setLoading) {

    const token = localStorage.getItem("authToken");

    if (!token) {
        toast(<CustomToast message="خطا: شما وارد سیستم نشده‌اید!" state="error" />);
        return;
    }

    const forms = Array.isArray(formIdArchived)
        ? formIdArchived.map((id) => ({ id, status: "published" }))
        : [];

    setLoading(true);

    axios
        .patch("https://formmaker-api.altynlogistics.com/api/form/status", {forms}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            console.log(response.data)
            toast(<CustomToast message="فرم / فرم های مورد نظر شما با موفقیت از آرشیو خارج شد" state={"success"} />);
            if (fetchList) fetchList();
            setLoading(false);
        })
        .catch((error) => {
            console.error("Error deleting form:", error);
        })
        .finally(() => {
            setLoading(false);
        });
}
