import React, { useEffect, useRef, useState } from 'react'
import { Grid } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'; // Generate unique IDs
import axios from 'axios';
import Loading from "./Loading/Loading";

const Image = ({ id }) => {


    const { drawerData } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;

    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    const token = localStorage.getItem('authToken');
    const FormId = localStorage.getItem('createFormId');


    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [image, setImage] = useState(null);
    const [formId] = useState(() => uuidv4());
    const [imageUrl, setImageUrl] = useState(null); // Image URL for display
    const [uploading, setUploading] = useState(false);
    const containerRefs = useRef(null);
    const [loading , setLoading] = useState(false);
    const [imageId , setImageId] = useState(drawerData[existingComponentIndex]?.state.mainImage);


    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm" || currentPage.startsWith("/User/FillForm/")





    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.mainImage = imageId
    }




    const handleImageChange = (e) => {

        if (currentPage === '/Publish') {
            const file = e.target.files[0];

            if (file) {

                const reader = new FileReader();

                reader.onload = () => {
                    // Set the Base64 string as the image data
                    setImage(reader.result);
                };

                reader.readAsDataURL(file);  // Read the file as Base64
            }
        }
    };



    const handleStoreImage = ()=>{


        console.log(image , 'image')

        setLoading(true);


        let content = {
            image:image
        }



        axios.post(`https://formmaker-api.altynlogistics.com/api/form/${FormId}/image`, content , {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            console.log('image successfully sent to the server:', response.data);
            setImageId(response.data.output.id)
            setLoading(false);
        }).catch((error) => {
            console.error('Error sending data to the server:', error);
        });

    }



    const handleGetImageId=()=>{

        axios.get(`https://formmaker-api.altynlogistics.com/api/form/11/image` , {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            console.log('image successfully get to the server:', response.data);
            setImageId(response.data.output.id)
            setLoading(false);
        }).catch((error) => {
            console.error('Error sending data to the server:', error);
        });

    }




    const handleClickOutside = (event) => {
        if (containerRefs.current && !containerRefs.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };




    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, imageId]);



    useEffect(() => {
        if(image){
            handleStoreImage()
        }
    }, [image]);


    useEffect(() => {
        handleGetImageId()
    }, []);


    return (
        <>
            {
                loading
                    ?
                    <Loading />
                    :
                    <Grid border={`2px solid ${isHeadingFocused  && !isReadOnly ? '#0293e2' : 'transparent'}`}
                          onClick={handleClickOutside}
                          mb={currentPage === '/build'|| currentPage === '/Publish' ? '28px' : '50px'}
                          p={currentPage === '/build'|| currentPage === '/Publish' ? '12px 10px' : '24px 36px'} borderRadius={'3px'}
                          mx={currentPage === '/build'|| currentPage === '/Publish' ? '4px' : '0px'} ref={containerRefs}
                          display={'flex'} alignItems={'center'} justifyContent={'center'}
                    >
                        {
                            isReadOnly || !isReadOnly ?
                                <Grid width={'120px'} height={'100px'}>
                                    <label htmlFor="imageUpload" style={{ cursor: 'pointer' }}>
                                        <img src={image || '/assets/images/image_placeholder.png'} alt="" style={{ width: '120px', height: '100px' }} />
                                    </label>
                                    <input
                                        type="file"
                                        id="imageUpload"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                </Grid>
                                :
                                <Grid width={'120px'} height={'100px'}>
                                    <label style={{ cursor: 'pointer' }}>
                                        <img src={image || '/assets/images/image_placeholder.png'} alt="" style={{ width: '120px', height: '100px' }} />
                                    </label>
                                    <input
                                        type="file"
                                        id="imageUpload"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                </Grid>
                        }

                    </Grid>
            }
        </>

    )
}
export default Image
