import React, { useContext, useEffect, useState } from 'react'
import CreateFormNavbar from '../Component/CreateFormNavbar'
import { Box, Button, Grid, Typography } from '@mui/material'
import MainHeader from '../Component/MainHeader'
import SearchIcon from '@mui/icons-material/Search';
import { useFormContext } from '../Context/Context';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useTitleContext } from "../Context/TitleContext";
import { useEditMode } from "../Context/EditModeContext";
import Loading from "../Component/Loading/Loading";
import PaginationForms from '../Component/Pagination/PaginationForms';
import MainMessage from '../Component/MainMessage';
import { useList } from '../Context/ListContext';
import { DrawerContext } from '../Context/DrawerContext';
import { useSectionUrls } from '../api/GetMainPageFormApi';
import { usePageCount } from '../Context/PageCount';
import { useLoading } from '../Context/LoadingContext';
import { useStatusForm } from '../Context/StatusFormContext';

const CloneExistingForm = () => {

    const token = localStorage.getItem('authToken');

    const navigate = useNavigate()


    const { setDrawerData } = useFormContext();
    const { setTitle } = useTitleContext();
    const { setEditMode } = useEditMode();
    const sectionUrls = useSectionUrls();
    const { pageCount, setPageCount } = usePageCount()
    const { setLoading } = useLoading()
    const { statusForms , setStatusForms } = useStatusForm();






    const [active, setActive] = useState(null)
    const [formFullDetails, setFormFullDetails] = useState(null)
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [count, setCount] = useState(null);
    const { list, setList } = useList();
    const [filteredListData, setFilteredListData] = useState(null)


    const handleActiveForm = (id) => {
        setActive(id)
    }


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };


    const handleGetListForm = () => {

        setLoading(true);
        axios
            .get(sectionUrls.Clone, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setLoading(false)
                setList(response.data.output.forms);
                setCount(response.data.output.pageCount);
            })
            .catch((err) => {
                setError(err); // Handle the error
                console.error("Error fetching data:", err);
            });
    }


    const initializeListData = (data) => {
        return data?.map(item => ({
            ...item,
            checked: item.checked || false // Default to false if `checked` doesn't exist
        }));
    };


    const handleCloneExistingForm = async (id) => {

        if (!token) {
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(
                `https://formmaker-api.altynlogistics.com/api/form/${id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            const { content } = response.data.output;

            let parsedForms = null;
            try {
                parsedForms = JSON.parse(content.forms);
            } catch (parseError) {
                console.error("Error parsing forms JSON:", parseError);
                setError("Invalid form data received from the server");
                return;
            }

            if (!parsedForms || !parsedForms.sendToServerData) {
                console.error("Parsed forms structure is invalid");
                setError("Form data is incomplete");
                return;
            }

            setFormFullDetails(parsedForms);
            setDrawerData(parsedForms.sendToServerData);
            setTitle(content.title);
            setEditMode(true);
            navigate('/build');
        } catch (err) {
            setError(err.message || "An error occurred"); // Handle and display error
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };


    useEffect(() => {
        setStatusForms('all')
        handleGetListForm()
    }, [])


    useEffect(() => {
        const initializedData = initializeListData(list);
        let filter = initializedData?.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredListData(filter)
    }, [list, searchTerm]);



    useEffect(() => {

        handleGetListForm()

    }, [pageCount]);



    return (
        <Grid width={'100%'} height={'100vh'} bgcolor={'white'}>
            <Loading />
            <MainHeader />
            <Grid p={'28px 32px'}>
                <CreateFormNavbar title={"فرم موجود خود را شبیه‌سازی کنید"} subTitle={"یک نسخه از یکی از فرم‌های موجود خود ایجاد کنید"} back={'/createForm/ImportForm'} flag={true} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={'720px'} mx={'auto'}>
                    <Grid className='searchInput' width={'100%'} sx={{ position: 'relative' }} mb={'24px'}>
                        <input
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder='در فرم های خود جستجو کنید'
                            style={{
                                color: '#0a1551',
                                backgroundColor: '#f3f3fe',
                                width: '100%',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '1rem 3.5rem 1rem 1rem',
                                fontSize: '16px',
                                outline: 'none'
                            }}
                        />
                        <Grid sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                            <SearchIcon style={{ color: '#686D76', rotate: '90deg' }} fontSize='large' />
                        </Grid>
                    </Grid>
                    <Grid className='formList' width={'100%'} maxHeight={'325px'} overflow={'auto'}>
                        {
                            filteredListData?.length > 0
                                ?
                                <>
                                    {
                                        filteredListData.map(
                                            (item, index) =>
                                                <Grid key={index} width={'100%'} bgcolor={active === item.id ? '#F2E2B1':'transparent'}>
                                                    <Button
                                                        onClick={() => { handleActiveForm(item.id) }}
                                                        sx={{
                                                            p: '14px 23px',
                                                            m: '18px 0px',
                                                            bgcolor: 'transparent',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Grid sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            gap: '24px'
                                                        }}>
                                                            <Grid width={'32px'} height={'32px'}>
                                                                <img src='/assets/images/checklist.png' alt=''
                                                                    style={{ width: '100%', height: '100%' }} />
                                                            </Grid>
                                                            <Grid pt={'4px'}>
                                                                <Typography variant='h4' color={'rgb(10, 21, 81)'}
                                                                    fontWeight={700}>{item.title}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Button>
                                                </Grid>
                                        )
                                    }
                                    <Grid mt={'10%'} display={'flex'} justifyContent={'center'} alignItems={'center'} dir={'rtl'}>
                                        <PaginationForms count={count} />
                                    </Grid>
                                </>
                                :
                                <Grid width={'300px'} display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>
                                    <MainMessage search={searchTerm} />
                                </Grid>
                        }
                    </Grid>
                    <Grid mt={'40px'}>
                        <Button onClick={() => { handleCloneExistingForm(active) }} disabled={active === null ? true : false} variant='contained' style={{ backgroundColor: '#4675e2', padding: '12px 28px', borderRadius: '4px', cursor: 'pointer', opacity: active === null ? 0.5 : 1 }}>
                            <Typography color={'#fff'} variant='h6' pt={'2px'} fontWeight={700}>
                                فرم ایجاد کنید
                            </Typography>
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CloneExistingForm