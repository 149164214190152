import { useTheme, useMediaQuery, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const Table = ({ id }) => {


    const { drawerData } = useFormContext();

    const location = useLocation();
    const currentPage = location.pathname;




    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    let switchRow = drawerData[existingComponentIndex]?.state.rowTitles.length>0 ? drawerData[existingComponentIndex].state.rowTitles : ['کیفیت خدمات', 'پاکیزگی', 'پاسخگویی', 'دوستی']

    let switchColumn = drawerData[existingComponentIndex]?.state.columnTitles.length>0 ? drawerData[existingComponentIndex].state.columnTitles : ['عدم رضایت', 'رضایت نسبی', 'رضایت', 'رضایت کامل']

    let switchHeader = drawerData[existingComponentIndex]?.state.tableValue ? drawerData[existingComponentIndex].state.tableValue : "جدول ورودی"
    

    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm" || currentPage.startsWith("/User/FillForm/")




    const [tableValue, setTableValue] = useState(switchHeader);
    const [rowTitles, setRowTitles] = useState(switchRow);
    const [columnTitles, setColumnTitles] = useState(switchColumn);
    const [selected, setSelected] = useState(Array(columnTitles.length).fill(null));
    const [show, setShow] = useState(false)
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [condition, setCondition] = useState(false)
    let initialSelectedColumnState = drawerData[existingComponentIndex]?.state.mainSelectedColumnIndex ? drawerData[existingComponentIndex].state.mainSelectedColumnIndex:Array(rowTitles.length).fill({ row: null, column: null })
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(initialSelectedColumnState);



    const containerRef = useRef(null);


    const fillInputValue = () => {
        if (drawerData.length > 0) {

            drawerData[existingComponentIndex].state.tableValue = tableValue
            drawerData[existingComponentIndex].state.rowTitles = rowTitles
            drawerData[existingComponentIndex].state.columnTitles = columnTitles
            drawerData[existingComponentIndex].state.mainSelectedColumnIndex = selectedColumnIndex
            drawerData[existingComponentIndex].state.show = show
            drawerData[existingComponentIndex].state.readOnly = readOnly

        }

    }


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'Table') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleRadioChange = (rowIndex, columnIndex) => {
        const newSelected = [...selected];
        newSelected[rowIndex] = columnIndex;
        setSelected(newSelected);

        const newSelectedColumnIndex = [...selectedColumnIndex];
        newSelectedColumnIndex[rowIndex] = { row: rowTitles[rowIndex], column: columnTitles[columnIndex] };
        setSelectedColumnIndex(newSelectedColumnIndex);

    };


    const handleRowTitleChange = (index, event) => {
        // const newTitles = [...rowTitles];
        // newTitles[index] = event.target.value;
        // setRowTitles(newTitles);

        const newTitles = [...rowTitles];
        newTitles[index] = event.target.value;
        setRowTitles(newTitles);

        const newSelectedColumnIndex = selectedColumnIndex.map((selection, rowIndex) => {
            if (rowIndex === index) {
                return { ...selection, row: event.target.value };
            }
            return selection;
        });
        setSelectedColumnIndex(newSelectedColumnIndex);


    };


    const handleColumnTitleChange = (index, event) => {
        // const newTitles = [...columnTitles];
        // newTitles[index] = event.target.value;
        // setColumnTitles(newTitles);


        const newTitles = [...columnTitles];
        newTitles[index] = event.target.value;
        setColumnTitles(newTitles);

        const newSelectedColumnIndex = selectedColumnIndex.map(selection => {
            if (selection.column === columnTitles[index]) {
                return { ...selection, column: event.target.value };
            }
            return selection;
        });
        setSelectedColumnIndex(newSelectedColumnIndex);


    };


    const handleRowDelete = (index) => {
        if (!condition) {

            const newRows = [...rowTitles];
            newRows.splice(index, 1);
            setRowTitles(newRows);
            const newSelectedColumnIndex = selectedColumnIndex.filter((_, i) => i !== index);
            setSelectedColumnIndex(newSelectedColumnIndex);
            const newSelected = selected.filter((_, i) => i !== index);
            setSelected(newSelected);


        }

    };

    const handleColumnDelete = (index) => {
        if (!condition) {

            const newColumns = [...columnTitles];
            newColumns.splice(index, 1);
            setColumnTitles(newColumns);
            const newSelectedColumnIndex = selectedColumnIndex.map(selection => {
                if (selection.column === columnTitles[index]) {
                    return { row: selection.row, column: null };
                }
                return selection;
            });
            setSelectedColumnIndex(newSelectedColumnIndex);

        }

    };


    const handleTableChange = (event) => {
        setTableValue(event.target.value);
    };


    const handleAddRow = () => {
        if (!condition) {
            // setRowTitles(prevTitles => [...prevTitles, `سطر ${prevTitles.length + 1}`]);
            // setSelectedColumnIndex(prev => [...prev, { row: null, column: null }]);

            setRowTitles(prevTitles => [...prevTitles, `سطر ${prevTitles.length + 1}`]);
            setSelectedColumnIndex(prev => [...prev, { row: null, column: null }]);
            setSelected(prev => [...prev, null]);
        }

    };


    const addColumn = () => {
        if (!condition) {
            const newColumnTitles = [...columnTitles, `ستون ${columnTitles.length + 1}`];
            setColumnTitles(newColumnTitles);
        }
    };


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
            setShow(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
            setShow(true)
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, tableValue, rowTitles, columnTitles, selectedColumnIndex, show]);


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    let theme = useTheme()

    let HeadingColor = theme.palette.blue[700]


    return (
        <Grid>
            <Grid
                mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
                p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
                borderRadius={'3px'}
                width={'100%'}
                mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
                bgcolor={'white'} ref={containerRef}
                border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '#0293e2' : 'transparent'}`}
                onClick={handleClickOutside}>
                <Grid className={'HeadingInput'} pb={'14px'}>
                    <input
                        readOnly={isReadOnly || condition}
                        className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                            border: 'none',
                            outline: 'none',
                            userSelect: 'text',
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap',
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: 1.45,
                            color: HeadingColor,
                            '::placeholder': {
                                color: '#b8bdc9', // Placeholder text color
                            },
                        }}
                        value={tableValue}
                        onChange={handleTableChange}
                        onClick={handleClickOutside}
                        onFocus={isReadOnly ? null : handleHeadingFocus}
                    />
                </Grid>
                <Grid className="table-container" display={'flex'} alignItems={'center'} position={'relative'}>
                    <Grid width={'fit-content'} onClick={isReadOnly || condition ? null : addColumn} display={show && !isReadOnly ? 'flex' : "none"} alignItems={'center'} gap={'4px'}
                        style={{ cursor: 'pointer' }} className='addColumn' position={'absolute'} right={'-30px'}
                        top={'50px'}>
                        <Typography variant={'subtitle1'} color={'darkgray'} style={{ whiteSpace: 'nowrap' }}
                            className='AddColumn'>افزودن ستون</Typography>
                        <AddIcon className='' />
                    </Grid>
                    <table className="custom-table" style={{ marginRight: isReadOnly ? '0px' : '20px' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '140px' }}></th>
                                {columnTitles.map((title, index) => (
                                    <th className="row-header" key={index} style={{ width: '140px' }}>
                                        <Grid className="input-container">
                                            <input
                                                readOnly={isReadOnly || condition}
                                                placeholder='عنوان' style={{
                                                    width: '100%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent'
                                                }} type="text" value={title}
                                                onChange={(event) => handleColumnTitleChange(index, event)} />
                                            <CloseIcon style={{
                                                color: show ? 'darkgray' : 'transparent',
                                                cursor: 'pointer',
                                                display: show && !isReadOnly ? 'block' : 'none'
                                            }}
                                                onClick={isReadOnly || condition ? null : () => handleColumnDelete(index)} className="delete-icons" />
                                        </Grid>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rowTitles.map((title, rowIndex) => (
                                <tr key={rowIndex}>
                                    <th className="row-header" style={{ width: '140px' }}>
                                        <Grid className="input-container">
                                            <input readOnly={isReadOnly || condition} placeholder='عنوان'
                                                style={{
                                                    width: '100%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent'
                                                }} type="text" value={title}
                                                onChange={(event) => handleRowTitleChange(rowIndex, event)} />
                                            <CloseIcon style={{
                                                color: show ? 'darkgray' : 'transparent',
                                                cursor: 'pointer',
                                                display: show && !isReadOnly ? 'block' : 'none'
                                            }}
                                                onClick={isReadOnly || condition ? null : () => handleRowDelete(rowIndex)} className="delete-icons" />
                                        </Grid>
                                    </th>
                                    {columnTitles.map((_, colIndex) => (
                                        <td key={colIndex} style={{ marginRight: "50%" }}>
                                            <Grid className="input-container">
                                                <input
                                                    disabled={condition}
                                                    style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                                                    type="radio"
                                                    checked={selected[rowIndex] === colIndex}
                                                    onChange={() => handleRadioChange(rowIndex, colIndex)}
                                                />
                                            </Grid>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Grid>
                <Grid width={'fit-content'} display={show && !isReadOnly ? 'flex' : "none"} alignItems={'center'} gap={'4px'} mr={'20px'}
                    style={{ cursor: 'pointer' }} className='AddColumn' onClick={isReadOnly || condition ? null : handleAddRow}>
                    <Typography variant={'subtitle1'} className='AddColumn'>افزودن سطر</Typography>
                    <AddIcon />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Table
