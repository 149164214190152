import {Grid} from "@mui/material";
import {Circles} from "react-loader-spinner";
import React from "react";
import {useLoading} from "../../Context/LoadingContext";

const Loading = ()=>{

    const {loading} = useLoading()

    return(
        loading &&
        <Grid display={'flex'} alignItems="center" justifyContent="center" height="100vh" width={'100vw'} bgcolor={'darkgray'} position={'fixed'} top={0} right={0} style={{ opacity: '0.8', zIndex: 1000 }} >
            <Circles color="#FF6600" height={80} width={80} />
        </Grid>
    )
}


export default Loading