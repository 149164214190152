import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSubmissionLimit } from '../../Context/FormLimited/SubmissionLimitContext';

const SubmissionLimit = () => {


    const { limit, handleChangeLimitNumber } = useSubmissionLimit();

    return (
        <Grid mt={'24px'} width={'100%'}>
            <Typography variant='h5' color={'rgba(10,21,81,1)'} fontWeight={500} mb={'4px'}>محدودیت ارسال</Typography>
            <Typography variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500} mb={'12px'}>تعداد کل ارسال ها را محدود کنید (به عنوان مثال، 1000)</Typography>
            <Grid width={'100%'}>
                <input
                    value={limit}
                    onChange={handleChangeLimitNumber}
                    type='number'
                    style={{
                        width: '100%',
                        border: '1px solid rgba(200,206,237,1)',
                        color: 'rgba(10,21,81,1)',
                        borderRadius: '4px',
                        fontSize: '14px',
                        padding: '8px 12px'
                    }}
                    placeholder='1000'
                />
            </Grid>
        </Grid>
    )
}

export default SubmissionLimit