import React, { createContext, useState, useContext } from 'react';

// Create Context
const ListContext = createContext();

// Create Provider Component
export const ListProvider = ({ children }) => {
    const [list, setList] = useState(null);

    return (
        <ListContext.Provider value={{ list, setList }}>
            {children}
        </ListContext.Provider>
    );
};

// Custom hook for easier consumption
export const useList = () => useContext(ListContext);
