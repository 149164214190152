import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import {lightGreen} from "@mui/material/colors";

export async function handleGetListResponse(formId, setListResponse) {
    const token = localStorage.getItem("authToken");

    try {
        const response = await axios.get(
            `https://formmaker-api.altynlogistics.com/api/form/${formId}/response`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("Data successfully fetched from the server:", response.data);
        setListResponse(response.data.output);

        return { data: response.data.output, error: null };
    } catch (err) {
        console.error("Error fetching data:", err);
        return { data: null, error: err };
    }
}
