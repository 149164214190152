import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SettingDrawer from '../Component/SettingDrawer'
import HeaderSetting from '../Component/HeaderSetting'
import Navbar from '../Component/Navbar'
import LayoutCondition from '../Component/LayoutCondition'
import { EnableFormSetting, GeneralSetting, SettingFormStatus } from '../Component/Icons'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import '../App.css'
import Checkbox from '@mui/material/Checkbox';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FormSettingOption from '../Component/FormSettingOption'
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'
import { useTitleContext } from '../Context/TitleContext'
import axios from 'axios';
import { toast } from 'react-toastify'
import { optionData } from '../Constant/formSettingOptionData'
import CustomToast from '../Component/CustomToast/CustomToast'
import SettingStatusForm from '../Component/SettingStatusForm'
import Encrypt from '../Component/FormSetting/Encrypt'
import { useSubmissionLimit } from '../Context/FormLimited/SubmissionLimitContext'
import { useDateRange } from '../Context/FormLimited/DateRangeContext'
import { useSubmissionLimitSetting } from '../Context/FormLimited/SubmissionLimitSettingContext'

const FormSetting = () => {


    const { title, setTitle } = useTitleContext();
    const { limit } = useSubmissionLimit();
    const { dateRange } = useDateRange();
    const {handleChangeLimitSetting} = useSubmissionLimitSetting()


    const [newTitle, setNewTitle] = useState(title);
    const [check, setCheck] = useState(false);
    const [show, setShow] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [selectValues, setSelectValues] = useState([
        { id: 0, name: 'title', value: 'فرم' },
        { id: 1, name: 'Save and Continue Later', value: 'غیرفعال' },
        { id: 2, name: 'FormStatus', value: 'فعال' },
        { id: 3, name: 'protection', value: '' },
        { id: 4, name: 'Unique Submission', value: 'بدون چک' },
        { id: 5, name: 'Unique Field', value: 'بدون چک' },
        { id: 6, name: 'Form Accessibility', value: 'غیرفعال' },
        { id: 7, name: 'Clear Hidden Field Values', value: 'وقتی پنهان است پاک شود' },
        { id: 8, name: 'Highlight Effect', value: 'غیرفعال' },
        { id: 9, name: 'Form Layout', value: 'همه سوالات در یک صفحه' },
        { id: 10, name: 'Show Error Navigation', value: 'بله' },
        { id: 11, name: 'Prevent Cloning', value: 'بله' },
        { id: 12, name: 'Allow Browser Autocomplete', value: 'بله' },
    ]);
    const [protection, setProtection] = useState('')

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleChangeTitle = (event) => {
        setNewTitle(event.target.value)
    }

    const handleChangeCheckBox = (event) => {
        setCheck(event.target.checked);
    };



    const handleSelectChange = (name, value) => {
        // Check if a select with the given name already exists in the array
        const existingIndex = selectValues.findIndex((select) => select.name === name);

        if (existingIndex !== -1) {
            // If a select with the given name exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the given name doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
        }
    };


    const handleBlurTitle = () => {
        setTitle(newTitle);
        // Update selectValues with the new title
        const existingIndex = selectValues.findIndex((select) => select.name === 'title');

        if (existingIndex !== -1) {
            // If a select with the name 'title' exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value: newTitle };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the name 'title' doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name: 'title', value: newTitle }]);
        }
    };

    const handleChangeProtection = (event) => {
        setProtection(event.target.value);

        // Update selectValues with the new protection value
        const existingIndex = selectValues.findIndex((select) => select.name === 'protection');

        if (existingIndex !== -1) {
            // If a select with the name 'protection' exists, update its value
            const updatedSelects = [...selectValues];
            updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value: event.target.value };
            setSelectValues(updatedSelects);
        } else {
            // If a select with the name 'protection' doesn't exist, add it to the array
            setSelectValues((prevSelectValues) => [...prevSelectValues, { name: 'protection', value: event.target.value }]);
        }
    };


    const handleSave = () => {
        const newLimitSetting = {
            maxUses: limit ?? null,
            start_date: dateRange.fromDate ?? null,
            end_date: dateRange.toDate ?? null
        };
        console.log(newLimitSetting)
        handleChangeLimitSetting(newLimitSetting);
    };


    const handleModalSelectChange = (name, value) => {
        if (value !== undefined) {
            // Check if a select with the given name already exists in the array
            const existingIndex = selectValues.findIndex((select) => select.name === name);

            if (existingIndex !== -1) {
                // If a select with the given name exists, update its value
                const updatedSelects = [...selectValues];
                updatedSelects[existingIndex] = { ...updatedSelects[existingIndex], value };
                setSelectValues(updatedSelects);
            } else {
                // If a select with the given name doesn't exist, add it to the array
                setSelectValues((prevSelectValues) => [...prevSelectValues, { name, value }]);
            }
        }
        handleSave()
        handleClose();
    };



    const modalOption = [
        { value: 'فعال ', label: ' فعال' },
        { value: 'غیرفعال ', label: 'غیرفعال ' },
        { value: 'در تاریخ خاصی غیرفعال کنید ', label: 'در تاریخ خاصی غیرفعال کنید ' },
        { value: 'غیرفعال کردن در حد مجاز ارسال ', label: 'غیرفعال کردن در حد مجاز ارسال ' },
        { value: 'در تاریخ خاص یا در محدودیت ارسال غیرفعال شود ', label: 'در تاریخ خاص یا در محدودیت ارسال غیرفعال شود ' },
    ]





    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'rgba(255,255,255,1)',
        boxShadow: 24,
        borderRadius: '8px',
        overflow:'visible',
    };



    useEffect(() => {
        setNewTitle(title)
    }, [title]);






    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'تنظیمات فرم'} subTitle={'وضعیت و خصوصیات فرم را سفارشی کنید'} link={'/Setting'} />
            </Grid>
            <Grid mt={{ xxs: '119px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={1} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '60px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <LayoutCondition title={'تنظیمات فرم'} subTitle={'وضعیت و خصوصیات فرم را سفارشی کنید'} link={''} icon={<GeneralSetting fill={'white'} />} color={'rgb(255, 97, 0)'} />
                    <Grid bgcolor={'white'} border={'1px solid #f2f2f2'} borderRadius={'4px'} mb={'1em'}>
                        <Grid className='title' borderBottom={'1px solid #f3f3fe'} p={'30px'}>
                            <Typography variant='h5' color={'#596066'} fontWeight={700} lineHeight={'1.618em'}>عنوان</Typography>
                            <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>یک نام برای فرم خود وارد کنید</Typography>
                            <input
                                value={newTitle}
                                onChange={handleChangeTitle}
                                onBlur={handleBlurTitle}
                                style={{
                                    border: '1px solid #c5c5c5',
                                    borderRadius: '4px',
                                    width: '100%',
                                    padding: '6px 12px',
                                    boxShadow: '0 2px 4px rgba(86,70,50,.1)',
                                    fontSize: '14px',
                                    backgroundColor: 'transparent',
                                    outline: '0px'
                                }}
                            />
                        </Grid>
                        <Grid>
                            <Grid className='FormStatus' borderBottom={'1px solid #f3f3fe'} p={'30px'} onClick={handleOpen}>
                                <Typography variant='h5' color={'#596066'} fontWeight={700} lineHeight={'1.618em'}>وضعیت فرم</Typography>
                                <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>فرم خود را فعال، غیرفعال یا مشروط کنید</Typography>
                                <Grid p={'12px 16px'} border={'1px solid rgb(197, 197, 197)'} borderRadius={'0.25rem'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={'32px'} height={'32px'} borderRadius={'0.25rem'} bgcolor={'rgba(100,178,0,1)'}>
                                            <EnableFormSetting fill={'white'} />
                                        </Grid>
                                        <Grid>
                                            <Typography mb={'4px'} variant='h5' fontWeight={500} color={'rgba(100,178,0,1)'} lineHeight={'20px'}>فعال شد</Typography>
                                            <Typography variant='h6' fontWeight={500} color={'rgb(89, 96, 102)'} lineHeight={'18px'}>فرم شما در حال حاضر قابل مشاهده است و می تواند موارد ارسالی را دریافت کند</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid pt={'10px'}>
                                        <ExpandCircleDownIcon style={{ rotate: '90deg', color: 'darkgray' }} fontSize={'large'} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Modal
                                    id="modal-container"
                                    sx={{ overflow: 'visible' }}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style} width={{ xs: '95%', xxs: '85%', sm: '70%', md: '50%' }}>
                                        <Grid p={'24px'} width={'100%'} borderBottom={'1px solid rgba(227,229,245,1)'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} width={{ xs: '40px' }} height={'40px'} borderRadius={'4px'} bgcolor={'rgba(0,117,227,1)'}>
                                                    <SettingFormStatus fill={'white'} />
                                                </Grid>
                                                <Grid>
                                                    <Typography variant='h5' color={'rgba(10,21,81,1)'} fontWeight={500} lineHeight={'20px'}>وضعیت فرم</Typography>
                                                    <Typography variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500} lineHeight={'18px'}>وضعیت فرم خود را انتخاب کنید</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid onClick={handleClose} display={'flex'} alignItems={'center'} justifyContent={'center'} width={{ xs: '24px', xxs: '40px' }} height={{ xs: '24px', xxs: '40px' }} borderRadius={'50%'} bgcolor={'rgba(218,222,243,1)'} style={{cursor:"pointer"}}>
                                                <CloseIcon style={{ color: 'rgba(52,60,106,1)' }} />
                                            </Grid>
                                        </Grid>
                                        <Grid p={'32px'}>
                                            <SettingStatusForm handleSelectChange={handleSelectChange} modalOption={modalOption} statusForm={selectValues[2].value} />
                                        </Grid>
                                        <Grid p={'16px 24px'} bgcolor={'rgba(243,243,254,1)'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Button sx={{p:'8px 18px' , bgcolor:'rgba(100,178,0,1)' , borderRadius:'4px'  , '&:hover':{bgcolor:'#3E7B27'}}} onClick={(event) => { handleModalSelectChange(event) }}>
                                                <Typography variant='h6' color={'white'} >ذخیره</Typography>
                                            </Button>
                                            <Button sx={{p:'8px 18px', bgcolor:'rgba(218,222,243,1)', borderRadius:'4px' , '&:hover':{bgcolor:'#BCCCDC'}}} onClick={handleClose}>
                                                <Typography variant='h6' color={'rgba(52,60,106,1)'}>لغو کردن</Typography>
                                            </Button>
                                        </Grid>
                                    </Box>
                                </Modal>
                            </Grid>
                        </Grid>

                        <Grid className='Encrypt' borderBottom={'1px solid #f3f3fe'} p={'30px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Encrypt />
                        </Grid>
                        <Grid className='MoreOptions' display={showOption ? 'block' : 'none'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} className='warning' p={'20px'} bgcolor={'white'} borderBottom={'1px solid #f3f3fe'}>
                                <Grid>
                                    <Typography variant='h5' color={'#596066'} fontWeight={700}>هشدارهای فرم</Typography>
                                    <Typography variant='h6' color={'#596066'} fontWeight={500}>پیام های هشدار را در فرم خود تغییر دهید.</Typography>
                                </Grid>
                                <Link to={'/Setting/general/FormWarnings'}>
                                    <Grid p={'11px 16px'} bgcolor={'#9b8a73'} borderRadius={'4px'} width={'fit-content'}>
                                        <Typography variant='h6' color={'white'} fontWeight={500}>ویرایش</Typography>
                                    </Grid>
                                </Link>
                            </Grid>
                            <Grid className='Password Protection' p={'20px'} bgcolor={'white'} borderBottom={'1px solid #f3f3fe'}>
                                <Typography variant='h5' color={'#596066'} fontWeight={700} mb={'10px'}>حفاظت از رمز عبور</Typography>
                                <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>یک رمز عبور برای محدود کردن دسترسی به فرم خود تنظیم کنید</Typography>
                                <Grid mb={check ? '10px' : '0px'} display={'flex'} alignItems={'center'} gap={'4px'}>
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, width: '24px', height: '24px', borderColor: 'lightgrey', borderRadius: '4px' }}
                                        checked={check}
                                        onChange={handleChangeCheckBox}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color="success"
                                    />
                                    <Typography pt={'2px'} variant='h6' color={'#596066'} fontWeight={500}>محافظت از رمز عبور را فعال کنید </Typography>
                                </Grid>
                                <Grid position={'relative'} display={check ? 'block' : 'none'}>
                                    <input
                                        value={protection}
                                        onChange={handleChangeProtection}
                                        type={show ? 'text' : 'password'}
                                        name={'protection'}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '1px solid #c5c5c5',
                                            borderRadius: '4px',
                                            padding: '6px 12px',
                                            boxShadow: '0 2px 4px rgba(86,70,50,.1)',
                                            color: '#564632',
                                            fontSize: '14px',
                                            backgroundColor: 'white',
                                            outline: 0,
                                            direction: 'ltr'
                                        }}
                                    />
                                    <Grid onClick={() => { setShow(!show) }} position={'absolute'} top={'50%'} left={'2%'} style={{ translate: '-30% -35%' }}>
                                        <RemoveRedEyeIcon fontSize='large' style={{ color: '#596066' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                {
                                    optionData.map(
                                        item =>
                                            <Grid key={item.id}>
                                                <FormSettingOption
                                                    header={item.title}
                                                    subTitle={item.subTitle}
                                                    options={item.optionValue}
                                                    id={item.active}
                                                    flag={item.id === 4 ? false : true}
                                                    linkFlag={item.linkFlag}
                                                    check={item.id === 3 ? true : false}
                                                    name={item.name}
                                                    onSelectChange={handleSelectChange}
                                                    selectValue={selectValues}
                                                    accessibilityFlag={item.id === 3 ? true : false}
                                                />
                                            </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='buttons' display={'flex'} flexDirection={!showOption ? 'row' : 'row-reverse'} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid mb={showOption ? '36px' : '0px'} width={'fit-content'} p={'11px 16px'} bgcolor={'#9b8a73'} borderRadius={'4px'} onClick={() => { setShowOption(!showOption) }} style={{ cursor: 'pointer' }}>
                            {
                                !showOption ?
                                    <Typography variant='h6' color={'white'} fontWeight={500}>نمایش گزینه های بیشتر</Typography>
                                    :
                                    <Typography variant='h6' color={'white'} fontWeight={500}>نمایش کمتر</Typography>
                            }
                        </Grid>
                        <Grid display={showOption ? 'block' : 'none'} onClick={handleSave} mb={showOption ? '36px' : '0px'} width={'fit-content'} p={'11px 16px'} bgcolor={'rgba(100,178,0,1)'} borderRadius={'4px'} style={{ cursor: 'pointer' }}>
                            <Typography variant='h6' color={'white'} fontWeight={500}>ذخیره</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormSetting