import React, { useContext } from 'react'
import { DrawerContext } from '../Context/DrawerContext';
import AllFormMessage from './AllFormMessage';
import { Grid } from '@mui/material';
import TrashMessage from './TrashMessage';
import FavoriteMessage from './FavoriteMessage';
import ArchiveMessage from './ArchiveMessage';
import DraftMessage from './DraftMessage';

const MainMessage = ({search}) => {


    console.log(search , 'search')


    const { selectDrawerItem } = useContext(DrawerContext);


    const renderComponent = () => {
        switch (selectDrawerItem) {
            case 'AllForm':
                return <AllFormMessage search={search}/>;
            case 'Trash':
                return <TrashMessage />;
            case 'Favorite':
                return <FavoriteMessage search={search}/>;
            case 'Archive':
                return <ArchiveMessage search={search}/>;
            case 'Drafts':
                return <DraftMessage />;
            default:
                return <div>Select a component</div>;
        }
    };

    return (
        <Grid>
            {renderComponent()}
        </Grid>
    )
}

export default MainMessage