import {useStatusForm} from "../Context/StatusFormContext";
import {usePageCount} from "../Context/PageCount";




export function useSectionUrls() {

    const { statusForms } = useStatusForm();


    const {pageCount} = usePageCount()

    return {
        AllForm: `https://formmaker-api.altynlogistics.com/api/form?page=${pageCount}&status=${statusForms}`,
        Favorite: 'https://formmaker-api.altynlogistics.com/api/form?offset=0&status=bookmark',
        Delete: 'https://formmaker-api.altynlogistics.com/api/form/mass',
        Archive: 'https://formmaker-api.altynlogistics.com/api/form?offset=0&status=archived',
        Drafts: 'https://formmaker-api.altynlogistics.com/api/form?offset=0&status=draft',
        Clone: `https://formmaker-api.altynlogistics.com/api/form?page=${pageCount}&status=all`,
    };
}


export const actionUrls = {
    updateStatus:'https://formmaker-api.altynlogistics.com/api/form/'
}