import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Grid, Modal, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
    DropDownFill,
    ShortTextBlue,
} from "./Icons";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Filter7OutlinedIcon from '@mui/icons-material/Filter7Outlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import NotesIcon from '@mui/icons-material/Notes';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PanoramaIcon from '@mui/icons-material/Panorama';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import '../App.css'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";

import { useTransition, animated } from 'react-spring';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const FillInTheBlank = ({ id }) => {


    const { drawerData } = useFormContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);


    const location = useLocation();
    const currentPage = location.pathname;
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm" || currentPage.startsWith("/User/FillForm/")

    let switchInputs = drawerData[existingComponentIndex]?.state.inputs ?
        drawerData[existingComponentIndex]?.state.inputs
        :
        [
            { id: 'first', value: 'این یک پر کردن', color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true, readOnly: true },
            { id: 'second', value: 'خالی', color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true, readOnly: false },
            { id: 'third', value: 'است', color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true, readOnly: true },
            { id: 'fourth', value: 'لطفاً فیلدهای', color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true, readOnly: true },
            { id: 'fifth', value: 'خالی', color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true, readOnly: false },
            { id: 'sixth', value: 'و متن مناسب را اضافه کنید', color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true, readOnly: true },
        ]


    //state

    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [show, setShow] = useState(false)
    const [showItem, setShowItem] = useState(false)
    const [clickedIndex, setClickedIndex] = useState([]);
    const [select, setSelect] = useState(null)
    const [active, setActive] = useState(null)
    const [activeMulti, setActiveMulti] = useState(null)
    const [check, setCheck] = useState(false)
    const [checkMulti, setCheckMulti] = useState(false)
    const [options, setOptions] = useState([]);
    const [openTextArea, setOpenTextArea] = useState(false)
    const [shortTextInputValue, setShortTextInputValue] = useState('لطفا انتخاب کنید');
    const [textareaValue, setTextareaValue] = useState('');
    const [fontSizes, setFontSizes] = useState(12)
    const [headingSize, setHeadingSize] = useState(12)
    const [fontColor, setFontColor] = useState('black')
    const [activeItem, setActiveItem] = useState(null)
    const [align, setAlign] = useState('right')
    const [modal, setModal] = useState(0);
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null)
    const [uploadedImages, setUploadedImages] = useState([]);
    const [activeBorder, setActiveBorder] = useState(null)
    const [increase, setIncrease] = useState(0)
    const [numberOrder, setNumberOrder] = useState(false)
    const [itemOrder, setItemOrder] = useState(false)
    const [headingSelect, setHeadingSelect] = useState('معمولی')
    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUnderline] = useState(false)
    const [change, setChange] = useState(false)
    const [changeSize, setChangeSize] = useState(false)
    const [changeHeading, setChangeHeading] = useState(false)
    const [selectIndex, setSelectIndex] = useState(null)
    const [innerHtml, setInnerHtml] = useState('');
    const [inputs, setInputs] = useState(switchInputs);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [condition, setCondition] = useState(false)


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });




    const fillInputValue = () => {
        drawerData[existingComponentIndex].state.inputs = inputs
        drawerData[existingComponentIndex].state.readOnly = readOnly
    }


    let dataIndex = drawerData.findIndex(item => item.title === title)


    let dataCondition = drawerData[dataIndex]?.state.readOnly





    //ref

    const containerRef = useRef(null);
    const dropDownRef = useRef(null)
    const text = useRef(null)
    const itemRef = useRef(null)
    const inputRef = useRef(null);
    let addOptionRef = useRef(null)
    const dropdownRef = useRef(null);


    //style
    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    const styleImage = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        maxWidth: '630px',
        bgcolor: '#ffffff',
        boxShadow: 24,
        zIndex: 10,
        borderRadius: '4px'
    };



    //data

    const AddFieldData = [
        { id: 1, title: 'نام', icon: <AccountCircleOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Name' },
        { id: 2, title: 'ایمیل', icon: <EmailOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Email' },
        { id: 3, title: 'تاریخ', icon: <CalendarTodayOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Date' },
        { id: 4, title: 'آدرس', icon: <LocationOnOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Address' },
        { id: 5, title: 'تلفن', icon: <PhoneOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Phone' },
        { id: 6, title: 'امضا', icon: <CreateOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Signature' },
        { id: 7, title: 'متن کوتاه', icon: <ShortTextBlue style={{ color: '#0293e2' }} />, EnTitle: 'ShortText' },
        { id: 11, title: 'شماره', icon: <Filter7OutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Number' },
        { id: 12, title: 'زمان', icon: <AccessTimeOutlinedIcon style={{ color: '#0293e2' }} />, EnTitle: 'Time' },
    ]


    const FormItem = [
        { id: 1, icon: '', title: 'یکان', flag: false },
        { id: 2, icon: '', title: `${fontSizes}px`, flag: false },
        { id: 3, icon: '', title: headingSelect, flag: false },
        { id: 4, icon: < FormatBoldIcon />, title: '', flag: true },
        { id: 5, icon: < FormatItalicIcon />, title: '', flag: true },
        { id: 6, icon: < FormatUnderlinedIcon />, title: '', flag: true },
        { id: 7, icon: < FormatColorTextIcon />, title: '', flag: true },
        { id: 8, icon: < NotesIcon />, title: '', flag: true },
        { id: 9, icon: < FormatListNumberedIcon />, title: '', flag: true },
        { id: 10, icon: < FormatListBulletedIcon />, title: '', flag: true },
        { id: 11, icon: < FormatIndentIncreaseIcon />, title: '', flag: true },
        { id: 12, icon: < FormatIndentDecreaseIcon />, title: '', flag: true },
        { id: 13, icon: < InsertLinkIcon />, title: '', flag: true },
        { id: 14, icon: < PanoramaIcon />, title: '', flag: true },
        { id: 15, icon: '', title: 'فیلدهای فرم', flag: false },
    ]






    const FontSizeData = [
        { id: 1, size: 10 },
        { id: 2, size: 12 },
        { id: 3, size: 18 },
        { id: 4, size: 32 },
    ]


    const HeadingData = [
        { id: 1, size: 32, title: 'عنوان 1' },
        { id: 2, size: 24, title: 'عنوان 2' },
        { id: 3, size: 18, title: 'عنوان 3' },
        { id: 4, size: 14, title: 'معمولی ' },
    ]


    const FontColor = [
        { id: 1, color: '#000' },
        { id: 2, color: 'rgb(230, 0, 0)' },
        { id: 3, color: 'rgb(255, 153, 0)' },
        { id: 4, color: 'rgb(255, 255, 0)' },
        { id: 5, color: 'rgb(0, 138, 0)' },
        { id: 6, color: 'rgb(0, 102, 204)' },
        { id: 7, color: 'rgb(153, 51, 255)' },

        { id: 8, color: 'rgb(255, 255, 255)' },
        { id: 9, color: 'rgb(250, 204, 204)' },
        { id: 10, color: 'rgb(255, 235, 204)' },
        { id: 11, color: 'rgb(255, 255, 204)' },
        { id: 12, color: 'rgb(204, 232, 204)' },
        { id: 13, color: 'rgb(204, 224, 245)' },
        { id: 14, color: 'rgb(235, 214, 255)' },

        { id: 15, color: 'rgb(187, 187, 187)' },
        { id: 16, color: 'rgb(240, 102, 102)' },
        { id: 17, color: 'rgb(255, 194, 102)' },
        { id: 18, color: 'rgb(255, 255, 102)' },
        { id: 19, color: 'rgb(102, 185, 102)' },
        { id: 20, color: 'rgb(102, 163, 224)' },
        { id: 21, color: 'rgb(194, 133, 255)' },

        { id: 22, color: 'rgb(136, 136, 136)' },
        { id: 23, color: 'rgb(161, 0, 0)' },
        { id: 24, color: 'rgb(178, 107, 0)' },
        { id: 25, color: 'rgb(178, 178, 0)' },
        { id: 26, color: 'aliceblue' },
        { id: 27, color: 'rgb(0, 71, 178)' },
        { id: 28, color: 'rgb(107, 36, 178)' },

        { id: 29, color: 'rgb(68, 68, 68)' },
        { id: 30, color: 'rgb(92, 0, 0)' },
        { id: 31, color: 'rgb(102, 61, 0)' },
        { id: 32, color: 'rgb(102, 102, 0)' },
        { id: 33, color: 'rgb(0, 55, 0)' },
        { id: 34, color: 'rgb(0, 41, 102)' },
        { id: 35, color: 'rgb(61, 20, 102)' },
    ]


    const AlignData = [
        { id: 1, icon: <FormatAlignRightIcon />, title: 'right' },
        { id: 2, icon: <FormatAlignCenterIcon />, title: 'center' },
        { id: 3, icon: <FormatAlignLeftIcon />, title: 'left' },
        { id: 4, icon: <FormatAlignJustifyIcon />, title: 'justify' }
    ]


    const FullName = ['نام', 'نام خانوادگی']
    const Email = ['ایمیل']
    const Date = ['تاریخ']
    const Address = ['آدرس خیابان', 'آدرس  ', 'شهر', 'استان']
    const Phone = ['کد منطقه', 'شماره تلفن']
    const Signature = ['امضا']
    const ShortText = ['یک عنوان وارد کنید']
    const Number = ['یک عنوان وارد کنید']
    const Time = ['زمان']


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'FillInTheBlank') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }




    //function
    const UploadImage = () => {
        return (
            <>
                <Grid border={'1px solid #535f6f'} borderRadius={'4px'}
                    display={'flex'} alignItems={'center'}
                    justifyContent={'center'} p={'15px'}
                    mb={'40px'}
                    width={'100%'}
                    height={'200px'}
                    gap={'18px'}
                >
                    <Grid width={'50%'} height={'100%'}>
                        <img src={uploadedFile.url} alt={''} width={'100%'} height={'100%'} />
                    </Grid>
                    <Grid width={'50%'} height={'100%'}>
                        <Typography
                            variant={'h5'} fontWeight={500} color={'white'}
                            pb={'10px'} height={'55px'}
                            sx={{
                                overflow: 'auto',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}
                        >
                            {uploadedFile.name}
                        </Typography>
                        <Typography variant={'h5'} fontWeight={500} color={'white'}
                            pb={'20%'}>{uploadedFile.size} KB</Typography>
                        <Button style={{
                            height: '40px',
                            width: '100%',
                            backgroundColor: 'rgba(0,117,227,1)',
                            color: 'white',
                            borderRadius: '4px',
                            fontSize: '14px',
                            fontWeight: 700
                        }} onClick={() => {
                            setUploadedFile(null)
                        }}>حذف تصویر </Button>
                    </Grid>
                </Grid>
            </>
        )
    }

    const handleFileUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) => handleFileSelect(event.target.files);
        input.click();
    };

    const handleFileSelect = (files) => {
        const selectedFile = files[0];
        const fileSizeInKB = Math.round(selectedFile.size / 1024);
        setUploadedFile((prevUploadedFile) => ({
            ...prevUploadedFile,
            name: selectedFile.name,
            size: fileSizeInKB,
            url: URL.createObjectURL(selectedFile),
        }));
        setUploadedImages((prevUploadedImages) => [
            ...prevUploadedImages,
            URL.createObjectURL(selectedFile),
        ]);
    };


    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = event.dataTransfer.files;
        handleFileSelect(droppedFiles);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Grid
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Grid sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Grid>
                )}
            </Grid>
        );
    }


    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const handleChangeHeading = (size, title) => {
        setHeadingSize(size);
        setHeadingSelect(title)
    }



    const DropDown = () => {
        return (
            <>
                <Grid display={activeItem === 2 ? 'block' : 'none'} bgcolor={'white'}
                    ref={itemRef} position={'absolute'} zIndex={1010000000000000} top={'30px'} right={'2px'} py={'6px'}
                    boxShadow={2}
                    onClick={handleClickOutsideFontItem}
                >
                    {
                        FontSizeData.map(
                            item =>
                                <Grid key={item.id} p={'4px 8px'} onClick={() => {
                                    setFontSizes(item.size)
                                }}>
                                    <Typography variant={'h6'} color={'black'}
                                        fontWeight={500}>{item.size}px</Typography>
                                </Grid>
                        )
                    }
                </Grid>
                <Grid display={activeItem === 3 ? 'block' : 'none'} bgcolor={'white'}
                    onClick={handleClickOutsideFontItem}
                    width={'auto'}
                    ref={itemRef} position={'absolute'} zIndex={101} top={'30px'} right={'0px'} py={'6px'}
                    boxShadow={2}
                >
                    {
                        HeadingData.map(
                            item =>
                                <Grid key={item.id} p={'4px 8px'} onClick={() => {
                                    handleChangeHeading(item.size, item.title)
                                }}>
                                    <Typography fontSize={`${item.size}px`} color={'black'} whiteSpace={'nowrap'}
                                        fontWeight={500}>{item.title}</Typography>
                                </Grid>
                        )
                    }
                </Grid>


                <Grid display={activeItem === 8 ? 'block' : 'none'} bgcolor={'white'}
                    onClick={handleClickOutsideFontItem}
                    width={'auto'}
                    ref={itemRef} position={'absolute'} zIndex={102} top={'30px'} right={'10px'} py={'4px'}
                    boxShadow={2}
                >
                    {
                        AlignData.map(
                            item =>
                                <Grid key={item.id} px={'4px'} onClick={() => {
                                    setAlign(item.title)
                                }}>
                                    {item.icon}
                                </Grid>
                        )
                    }
                </Grid>

                <Grid className={'FontColor'} bgcolor={'white'} display={activeItem === 7 ? 'flex' : 'none'}
                    onClick={handleClickOutsideFontItem}
                    boxShadow={2}
                    ref={itemRef} position={'absolute'} zIndex={150000} top={'30px'} left={'0px'}>
                    <Grid bgcolor={'white'} m={'12px'} display={'flex'} alignItems={'center'}
                        flexDirection={'row-reverse'}
                        justifyContent={'space-between'} flexWrap={'wrap'} width={isUpXxl ? '152px' : '80px'} height={isUpXxl ? '110px' : '180px'}>
                        {
                            FontColor.map(
                                item =>
                                    <Grid key={item.id} width={'16px'} height={'16px'} m={'2px'} bgcolor={item.color} onClick={() => {
                                        setFontColor(item.color)
                                    }}></Grid>
                            )
                        }
                    </Grid>
                </Grid>


                <Grid className={'InsertImage'}>
                    <Modal
                        open={modal === 14 && open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Grid sx={styleImage}>
                            <Grid sx={{ width: '100%' }}>
                                <Grid>
                                    <Tabs value={value} onChange={handleChange} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#2f333a',
                                    }}>
                                        <Tab label="بارگذاری" {...a11yProps(0)} style={{ width: '33%', color: 'white', fontSize: '12px', fontWeight: 700 }} />
                                        <Tab label="عکس های من" {...a11yProps(1)} style={{ width: '33%', color: 'white', fontSize: '12px', fontWeight: 700 }} />
                                        <Tab label="وارد کردن آدرس اینترنتی" {...a11yProps(2)} style={{ width: '33%', color: 'white', fontSize: '12px', fontWeight: 700 }} />
                                    </Tabs>
                                </Grid>
                                <Grid bgcolor={'#535f6f'}>
                                    <CustomTabPanel value={value} index={0}>
                                        {
                                            uploadedFile !== null ?
                                                <UploadImage />
                                                :
                                                <React.Fragment>
                                                    <Grid
                                                        border={'1px dashed white'} borderRadius={'4px'}
                                                        display={'flex'} flexDirection={'column'} alignItems={'center'}
                                                        justifyContent={'center'}
                                                        mb={'40px'}
                                                        onDragEnter={handleDragEnter}
                                                        onDragOver={handleDragOver}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}
                                                    >
                                                        <Button onClick={handleFileUpload} style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            padding: '12px 10px',
                                                            backgroundColor: 'rgba(0,117,227,1)',
                                                            margin: '20px'
                                                        }}>
                                                            <DownloadIcon style={{ color: 'white' }} />
                                                            <Typography variant={'h6'} color={'white'} fontWeight={500}>بارگذاری فایل</Typography>
                                                        </Button>
                                                        <Typography pb={'10px'} variant={'h5'} color={'white'}>یا اینجا بکشید و رها کنید</Typography>
                                                    </Grid>
                                                </React.Fragment>
                                        }
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>
                                        <>
                                            <Grid border={'1px solid white'} borderRadius={'4px'}
                                                display={'flex'} alignItems={'center'}
                                                justifyContent={'center'} p={'15px'}
                                                mb={'40px'}
                                                width={'100%'}
                                                height={'200px'}
                                                gap={'18px'}>
                                                {
                                                    uploadedImages.map(
                                                        (item, index) =>
                                                            <Grid key={index} display={'flex'} flexWrap={'wrap'}
                                                                alignItems={'center'} justifyContent={'flex-start'}
                                                                gap={'8px'} width={{ xs: '70px', xxs: '136px' }}
                                                                height={'80px'}
                                                                border={activeBorder === index ? '3px solid #09f' : '3px solid transparent'}
                                                                borderRadius={'4px'}
                                                                position={'relative'}
                                                                sx={{
                                                                    overflow: 'auto',
                                                                    scrollbarWidth: 'none',
                                                                    '&::-webkit-scrollbar': {
                                                                        display: 'none',
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    setActiveBorder(index)
                                                                }}
                                                            >
                                                                <img src={item} alt={''} width={'100%'}
                                                                    height={'100%'} />
                                                                <Grid width={'100%'}
                                                                    height={'100%'}
                                                                    bgcolor={activeBorder === index ? '#09f' : "transparent"}
                                                                    style={{ opacity: `${activeBorder === index ? 0.2 : 0}` }}
                                                                    position={'absolute'} top={0} left={0}
                                                                    zIndex={10}>
                                                                    <CheckCircleIcon style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        zIndex: 100
                                                                    }} />
                                                                </Grid>
                                                            </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>
                                        <Grid>
                                            <Grid pb={'35px'}>
                                                <input
                                                    dir={'ltr'}
                                                    placeholder={'https://'}
                                                    style={{
                                                        width: '100%',
                                                        height: '45px',
                                                        border: '1px solid #c8ceed',
                                                        borderRadius: '4px',
                                                        padding: '0px 10px',
                                                        marginBottom: '10px'
                                                    }}
                                                />
                                            </Grid>
                                            <Button variant={'contained'} color={'primary'}>
                                                <Typography variant={'h6'} fontWeight={500}>افزودن لینک</Typography>
                                            </Button>
                                        </Grid>
                                    </CustomTabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Modal>
                </Grid>
            </>
        )
    }

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    const handleChooseField = () => {
        setOptions([]);
        setDropdownOpen(!dropdownOpen);
    }



    const handleSelect = (title) => {
        setSelect((prevSelect) => title);
        setShow(false)
        setDropdownOpen(false)
        addNewInputs()
    }


    const addNewInputs = () => {
        if (selectIndex !== null && select) {
            setInputs(prevInputs => {
                const newInputs = [...prevInputs];
                let insertIndex = selectIndex + 1;

                switch (select) {
                    case 'Name':
                        for (let i = FullName.length - 1; i >= 0; i--) {
                            newInputs.splice(insertIndex, 0, { id: newInputs.length, value: FullName[i], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        }
                        break;
                    case 'Address':
                        for (let i = Address.length - 1; i >= 0; i--) {
                            newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Address[i], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        }
                        break;
                    case 'Phone':
                        for (let i = Phone.length - 1; i >= 0; i--) {
                            newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Phone[i], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        }
                        break;
                    case 'DropDown':
                        newInputs.splice(insertIndex, 0, {
                            id: newInputs.length,
                            type: 'select',
                            options: ['گزینه اول', 'گزینه دوم', 'گزینه سوم']
                        });
                        break;
                    case 'Email':
                        newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Email[0], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        break;
                    case 'Date':
                        newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Date[0], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        break;
                    case 'Signature':
                        newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Signature[0], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        break;
                    case 'ShortText':
                        newInputs.splice(insertIndex, 0, { id: newInputs.length, value: ShortText[0], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        break;
                    case 'Number':
                        newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Number[0], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        break;
                    case 'Time':
                        newInputs.splice(insertIndex, 0, { id: newInputs.length, value: Time[0], color: 'black', fontSize: 12, bold: false, italic: false, underline: false, headingFlag: true });
                        break;
                    case 'MultiChoice':
                        newInputs.splice(insertIndex, 0, {
                            id: newInputs.length,
                            value: 'Choice 1',
                            type: 'checkbox',
                            label: 'یک گزینه وارد کنید'
                        });
                        newInputs.splice(insertIndex + 1, 0, {
                            id: newInputs.length,
                            value: 'Choice 2',
                            type: 'checkbox',
                            label: 'یک گزینه وارد کنید'
                        });
                        // Add more choices as needed
                        break;
                    case 'SingleChoice':
                        newInputs.splice(insertIndex, 0, {
                            id: newInputs.length,
                            value: 'Choice 1',
                            type: 'radio',
                            label: 'یک گزینه وارد کنید'
                        });
                        newInputs.splice(insertIndex + 1, 0, {
                            id: newInputs.length,
                            value: 'Choice 2',
                            type: 'radio',
                            label: 'یک گزینه وارد کنید'
                        });
                        // Add more choices as needed
                        break;
                    default:
                        break;
                }

                return newInputs;
            });
            setSelect(null); // Reset select after adding inputs
        }
    };




    const handleClick = (id) => {
        setActiveItem(activeItem === id ? null : id)
        id === 12 && setIncrease(increase + 1)
        id === 11 && setIncrease(increase === 0 ? 0 : increase - 1)
        if (id === 10) {
            if (numberOrder === false) {
                setItemOrder(!itemOrder)
            } else {
                setNumberOrder(false)
                setItemOrder(!itemOrder)
            }
        }

        if (id === 9) {
            if (itemOrder === false) {
                setNumberOrder(!numberOrder)
            } else {
                setItemOrder(false)
                setNumberOrder(!numberOrder)
            }
        }
        id === 4 && setBold(!bold)
        id === 5 && setItalic(!italic)
        id === 6 && setUnderline(!underline)
    }


    const handleInputChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index].value = event.target.value;

        if (bold) {
            newInputs[index].bold = true
        } else {
            newInputs[index].bold = false
        }

        if (italic) {
            newInputs[index].italic = true
        } else {
            newInputs[index].italic = false
        }

        if (underline) {
            newInputs[index].underline = true
        } else {
            newInputs[index].underline = false
        }

        if (change) {
            newInputs[index].color = fontColor
        }

        if (changeSize) {
            newInputs[index].headingFlag = false
            newInputs[index].fontSize = fontSizes
        }

        setInputs(newInputs);

        const input = inputRef.current;
        if (input) {
            input.style.width = `${input.scrollWidth}px`;
        }
    };



    const handleLabelChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index].value = event.target.value;

        if (bold) {
            newInputs[index].bold = true
        } else {
            newInputs[index].bold = false
        }

        if (italic) {
            newInputs[index].italic = true
        } else {
            newInputs[index].italic = false
        }

        if (underline) {
            newInputs[index].underline = true
        } else {
            newInputs[index].underline = false
        }

        if (change) {
            newInputs[index].color = fontColor
        }

        if (changeSize) {
            newInputs[index].headingFlag = false
            newInputs[index].fontSize = fontSizes
        }

        setInputs(newInputs);
    };




    const handleItemClick = (itemId, event) => {
        event.stopPropagation();
        handleClick(itemId);
        setModal(itemId)
        handleOpen()
    };


    const handleItemClickIndex = (index) => {
        // Check if the index is already clicked
        const indexAlreadyClicked = clickedIndex.includes(index);

        // If it's already clicked, remove it from the clickedIndices array
        // Otherwise, add it to the clickedIndices array
        const updatedClickedIndices = indexAlreadyClicked
            ? clickedIndex.filter(clickedIndex => clickedIndex !== index)
            : [...clickedIndex, index];

        // Update the state with the updated clickedIndices array
        setClickedIndex(updatedClickedIndices);
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false)
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true)
        }
    };


    const handleClickOutsideText = (event) => {
        if (text.current && !text.current.contains(event.target)) {
            setOpenTextArea(false);
        }
    };


    const handleClickOutsideFontItem = (event) => {
        if (itemRef.current && !itemRef.current.contains(event.target)) {
            setActiveItem(null);
        }
    };


    //animation

    const transitions = useTransition(showItem, {
        from: { opacity: 0, transform: 'translateY(-70%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(-70%)' },
        config: { tension: 210, friction: 20 },
    });


    //effect

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    useEffect(() => {
        document.addEventListener('click', handleClickOutsideText);

        return () => {
            document.removeEventListener('click', handleClickOutsideText);
        };
    }, []);


    useEffect(() => {
        addNewInputs()
    }, [select]);


    useEffect(() => {
        setChange(true)
    }, [fontColor]);


    useEffect(() => {
        setChangeSize(true)
    }, [fontSizes]);


    useEffect(() => {
        setChangeHeading(true)
    }, [headingSize]);



    // useEffect(() => {
    //     handleSaveInnerHtml()
    // }, [innerHtml, numberOrder, itemOrder, inputs]);



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id , inputs]);


    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Clicked outside the dropdown, so close it
                setDropdownOpen(false);
            }
        }

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])



    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '24px 36px'}
            borderRadius={'3px'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}
        >
            <Grid className={'Button'} display={'flex'} alignItems={'center'} gap={'6px'} pb={'12px'}>
                <Grid ref={dropdownRef} className={'AddField'} p={'10px'} bgcolor={'#f2f3f4'} border={'none'}
                    width={'fit-content'} style={{ outline: 'none', cursor: 'pointer' }} borderRadius={'4px'} position={'relative'}>
                    <Grid display={'flex'} alignItems={'center'} gap={'4px'} onClick={isReadOnly || condition ? null : handleChooseField}>
                        <Typography variant={'subtitle1'} color={'#1c2643'} pt={'1px'}>اضافه کردن فیلد</Typography>
                        <AddCircleIcon style={{ color: 'black' }} fontSize={'medium'} />
                    </Grid>
                    {
                        dropdownOpen &&
                        <Grid position={'absolute'} top={'45px'} right={0} zIndex={110} width={'auto'} bgcolor={'white'}
                            maxHeight={'250px'} style={{ overflowY: 'scroll' }}
                            borderRadius={'4px'}>
                            {
                                AddFieldData.map(
                                    (item, index) =>
                                        <Grid key={item.id} display={'flex'} alignItems={'center'} gap={'8px'} p={'10px 20px 10px 10px'}
                                            onClick={() => {
                                                handleSelect(item.EnTitle)
                                            }}>
                                            {item.icon}
                                            <Typography variant={'body1'} color={'#1c2643'}
                                                whiteSpace={'nowrap'}>{item.title}</Typography>
                                        </Grid>
                                )
                            }
                        </Grid>
                    }

                </Grid>
                <Grid className={'UnderLine'} display={'flex'} alignItems={'center'} bgcolor={'#f2f3f4'}
                    border={'none'} width={'fit-content'} style={{ outline: 'none', cursor: 'pointer' }}
                    borderRadius={'4px'} p={'10px'}
                    onClick={isReadOnly || condition ? null : () => {
                        setShowItem(!showItem)
                    }}>
                    <Typography variant={'h6'} color={'#1c2643'} pt={'1px'}
                        style={{ textDecoration: 'underline' }}>A</Typography>
                </Grid>
            </Grid>
            <Grid>
                <>
                    {transitions((style, item) => item && (
                        <animated.div style={style}>
                            <Grid display={'flex'} alignItems={'center'} flexWrap={'wrap'}
                                pb={'12px'}
                                width={'100%'} height={'100%'}>
                                {FormItem.map(item => (
                                    <Grid position={'relative'} key={item.id} py={'3px'} px={'12px'}
                                        pt={item.flag ? '6px' : '3px'}
                                        zIndex={100}
                                        style={{ cursor: 'pointer' }}
                                        ref={dropDownRef}
                                        borderLeft={item.id === 1 || item.id === 2 || item.id === 3 || item.id === 6 || item.id === 7 || item.id === 8 || item.id === 12 || item.id === 14 ? '1px solid #ccc' : ''}
                                        lineHeight={'10px'}
                                    >
                                        <Grid display={'flex'} alignItems={'baseline'} justifyContent={'center'}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(event) => handleItemClick(item.id, event)}
                                            position={'relative'} zIndex={10}
                                        >
                                            <Grid>
                                                {
                                                    item.flag ?
                                                        item.icon :
                                                        <Typography variant={'h6'} color={'#444'}>{item.title}</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                        {
                                            activeItem !== null && item.id === activeItem ? <DropDown /> : <></>
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                        </animated.div>
                    ))}
                </>
                <Grid id={'InnerHtml'}>
                    {
                        numberOrder &&
                        <ol>
                            <li>
                                <Grid className={'textFillInBlanckOl'} display={'flex'} alignItems={'baseline'} justifyContent={align === 'right' ? 'flex-start' : align === 'left' ? 'flex-end' : align === 'justify' ? 'right' : 'center'} flexWrap={'wrap'} pt={'12px'}
                                    borderTop={'1px solid #ccc'} pr={`${increase * 16}px`}>
                                    {inputs.map((input, index) => (
                                        <Grid key={input.id} onClick={() => setClickedIndex(index)} >
                                            {input.type === 'radio' ? (
                                                <label style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <input
                                                        type={"radio"}
                                                        name={`input-${input.id}`}
                                                        checked={input.id === active ? !check : false}  // You need to handle checked state as needed
                                                        onChange={() => {
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents the click event from propagating to the parent container
                                                            setActive(input.id);
                                                        }}
                                                        readOnly={condition}

                                                    />
                                                    <input
                                                        style={{ border: 'none', outline: 'none' }}
                                                        type="text"
                                                        value={input.label}
                                                        onChange={(event) => handleLabelChange(index, event)}
                                                        readOnly={condition}

                                                    />
                                                </label>
                                            ) : input.type === 'checkbox' ? (
                                                <label style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <input
                                                        type={"checkbox"}
                                                        name={`input-${input.id}`}
                                                        checked={input.id === activeMulti ? !checkMulti : false}  // You need to handle checked state as needed
                                                        onChange={() => {
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents the click event from propagating to the parent container
                                                            setActiveMulti(input.id);
                                                        }}
                                                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    />
                                                    <input
                                                        style={{ border: 'none', outline: 'none' }}
                                                        type="text"
                                                        value={input.label}
                                                        onChange={(event) => handleLabelChange(index, event)}
                                                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    />
                                                </label>
                                            )
                                                :
                                                input.type === 'select' ?
                                                    <Grid
                                                        ref={text}
                                                        style={{
                                                            position: 'relative',
                                                            borderBottom: '1px solid #b8bdc9',
                                                            borderRadius: '3px',
                                                            padding: '10px',
                                                            userSelect: 'text',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                            width: '120px',
                                                            height: '20px',
                                                            color: 'HeadingColor',
                                                            marginBottom: '8px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Grid
                                                            onClick={() => {
                                                                setOpenTextArea(!openTextArea)
                                                            }}
                                                            style={{ width: '100%', height: '100%' }}
                                                            display={'flex'} alignItems={'center'} justifyContent={'space-between'}
                                                        >
                                                            <Typography variant={'h6'} fontWeight={500}>{shortTextInputValue}</Typography>
                                                            <KeyboardArrowDownIcon />
                                                        </Grid>
                                                        <textarea
                                                            style={{
                                                                resize: "none",
                                                                position: 'absolute',
                                                                top: '20px',
                                                                left: '0px',
                                                                zIndex: 50,
                                                                display: openTextArea ? 'block' : 'none',
                                                                height: '40px',
                                                                maxHeight: '40px',
                                                                overflowY: 'scroll',
                                                                width: '120px',
                                                                paddingLeft: '10px',
                                                                fontSize: '12px',
                                                                border: '1px solid #2e69ff',
                                                                boxShadow: '0 0 0 3px rgba(46,105,255,.25)',
                                                                color: '#2c3345',
                                                                borderRadius: '4px'
                                                            }}
                                                            value={textareaValue}
                                                            readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                            onChange={handleTextareaChange}
                                                            placeholder="گزینه اول
                        گزینه دوم
                        گزینه سوم
                        "
                                                        ></textarea>
                                                    </Grid>
                                                    :
                                                    (
                                                        <input
                                                            id={input.id}
                                                            type={'text'}
                                                            className="FillInput"
                                                            style={{
                                                                border: 'none',
                                                                outline: 'none',
                                                                userSelect: 'text',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'pre-wrap',
                                                                width: '100%',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                lineHeight: 1.45,
                                                                color: 'HeadingColor',
                                                                borderBottom: input.id === 'fifth' || input.id === 'second' || input.id >= 6 ? '1px solid #c3cad8' : 'none',
                                                            }}
                                                            value={input.value}
                                                            readOnly={isReadOnly || condition}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                    )
                                            }
                                        </Grid>
                                    ))}
                                </Grid>
                            </li>
                        </ol>
                    }
                    {
                        itemOrder &&
                        <ul>
                            <li>
                                <Grid className={'textFillInBlanckUl'} display={'flex'} alignItems={'baseline'} justifyContent={align === 'right' ? 'flex-start' : align === 'left' ? 'flex-end' : align === 'justify' ? 'right' : 'center'} flexWrap={'wrap'} pt={'12px'}
                                    borderTop={'1px solid #ccc'} pr={`${increase * 16}px`}>
                                    {inputs.map((input, index) => (
                                        <Grid key={input.id} onClick={() => setClickedIndex(index)} >
                                            {input.type === 'radio' ? (
                                                <label style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <input
                                                        type={"radio"}
                                                        name={`input-${input.id}`}
                                                        checked={input.id === active ? !check : false}  // You need to handle checked state as needed
                                                        onChange={() => {
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents the click event from propagating to the parent container
                                                            setActive(input.id);
                                                        }}
                                                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    />
                                                    <input
                                                        style={{ border: 'none', outline: 'none' }}
                                                        type="text"
                                                        value={input.label}
                                                        onChange={(event) => handleLabelChange(index, event)}
                                                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    />
                                                </label>
                                            ) : input.type === 'checkbox' ? (
                                                <label style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <input
                                                        type={"checkbox"}
                                                        name={`input-${input.id}`}
                                                        checked={input.id === activeMulti ? !checkMulti : false}  // You need to handle checked state as needed
                                                        onChange={() => {
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevents the click event from propagating to the parent container
                                                            setActiveMulti(input.id);
                                                        }}
                                                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    />
                                                    <input
                                                        style={{ border: 'none', outline: 'none' }}
                                                        type="text"
                                                        value={input.label}
                                                        onChange={(event) => handleLabelChange(index, event)}
                                                        readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    />
                                                </label>
                                            )
                                                :
                                                input.type === 'select' ?
                                                    <Grid
                                                        ref={text}
                                                        style={{
                                                            position: 'relative',
                                                            borderBottom: '1px solid #b8bdc9',
                                                            borderRadius: '3px',
                                                            padding: '10px',
                                                            userSelect: 'text',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                            width: '120px',
                                                            height: '20px',
                                                            color: 'HeadingColor',
                                                            marginBottom: '8px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <Grid
                                                            onClick={() => {
                                                                setOpenTextArea(!openTextArea)
                                                            }}
                                                            style={{ width: '100%', height: '100%' }}
                                                            display={'flex'} alignItems={'center'} justifyContent={'space-between'}
                                                        >
                                                            <Typography variant={'h6'} fontWeight={500}>{shortTextInputValue}</Typography>
                                                            <KeyboardArrowDownIcon />
                                                        </Grid>
                                                        <textarea
                                                            style={{
                                                                resize: "none",
                                                                position: 'absolute',
                                                                top: '20px',
                                                                left: '0px',
                                                                zIndex: 50,
                                                                display: openTextArea ? 'block' : 'none',
                                                                height: '40px',
                                                                maxHeight: '40px',
                                                                overflowY: 'scroll',
                                                                width: '120px',
                                                                paddingLeft: '10px',
                                                                fontSize: '12px',
                                                                border: '1px solid #2e69ff',
                                                                boxShadow: '0 0 0 3px rgba(46,105,255,.25)',
                                                                color: '#2c3345',
                                                                borderRadius: '4px'
                                                            }}
                                                            value={textareaValue}

                                                            readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}
                                                            onChange={handleTextareaChange}
                                                            placeholder="گزینه اول
                    گزینه دوم
                    گزینه سوم
                    "
                                                        ></textarea>
                                                    </Grid>
                                                    :
                                                    (
                                                        <input
                                                            id={input.id}
                                                            type={'text'}
                                                            className="FillInput"
                                                            style={{
                                                                border: 'none',
                                                                outline: 'none',
                                                                userSelect: 'text',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'pre-wrap',
                                                                width: '100%',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                lineHeight: 1.45,
                                                                color: 'HeadingColor',
                                                                borderBottom: input.id === 'fifth' || input.id === 'second' || input.id >= 6 ? '1px solid #c3cad8' : 'none',
                                                            }}
                                                            readOnly={isReadOnly || condition}
                                                            value={input.value}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                    )
                                            }
                                        </Grid>
                                    ))}
                                </Grid>
                            </li>
                        </ul>
                    }
                    {
                        !numberOrder && !itemOrder &&
                        <Grid className={'textFillInBlanck'} display={'flex'} alignItems={'baseline'} justifyContent={align === 'right' ? 'flex-start' : align === 'left' ? 'flex-end' : align === 'justify' ? 'right' : 'center'} flexWrap={'wrap'} pt={'12px'}
                            borderTop={'1px solid #ccc'} pr={`${increase * 16}px`}>
                            {inputs.map((input, index) => (
                                <Grid key={input.id}
                                    onClick={() => handleItemClickIndex(index)}
                                >
                                    {input.type === 'radio' ? (
                                        <label style={{ display: 'flex', alignItems: 'baseline' }}>
                                            <input
                                                type={"radio"}
                                                name={`input-${input.id}`}
                                                checked={input.id === active ? !check : false}  // You need to handle checked state as needed
                                                onChange={() => {
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the click event from propagating to the parent container
                                                    setActive(input.id);
                                                }}
                                                readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                            />
                                            <input
                                                style={{ border: 'none', outline: 'none' }}
                                                type="text"
                                                value={input.label}
                                                onChange={(event) => handleLabelChange(index, event)}
                                                readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                            />
                                        </label>
                                    ) : input.type === 'checkbox' ? (
                                        <label style={{ display: 'flex', alignItems: 'baseline' }}>
                                            <input
                                                type={"checkbox"}
                                                name={`input-${input.id}`}
                                                checked={input.id === activeMulti ? !checkMulti : false}  // You need to handle checked state as needed
                                                onChange={() => {
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the click event from propagating to the parent container
                                                    setActiveMulti(input.id);
                                                }}
                                                readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                            />
                                            <input
                                                style={{ border: 'none', outline: 'none' }}
                                                type="text"
                                                value={input.label}
                                                onChange={(event) => handleLabelChange(index, event)}
                                                readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                            />
                                        </label>
                                    )
                                        :
                                        input.type === 'select' ?
                                            <Grid
                                                ref={text}
                                                style={{
                                                    position: 'relative',
                                                    borderBottom: '1px solid #b8bdc9',
                                                    borderRadius: '3px',
                                                    padding: '10px',
                                                    userSelect: 'text',
                                                    wordWrap: 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                    width: '120px',
                                                    height: '20px',
                                                    color: 'HeadingColor',
                                                    marginBottom: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <Grid
                                                    onClick={() => {
                                                        setOpenTextArea(!openTextArea)
                                                    }}
                                                    style={{ width: '100%', height: '100%' }}
                                                    display={'flex'} alignItems={'center'} justifyContent={'space-between'}
                                                >
                                                    <Typography variant={'h6'} fontWeight={500}>{shortTextInputValue}</Typography>
                                                    <KeyboardArrowDownIcon />
                                                </Grid>
                                                <textarea
                                                    style={{
                                                        resize: "none",
                                                        position: 'absolute',
                                                        top: '20px',
                                                        left: '0px',
                                                        zIndex: 50,
                                                        display: openTextArea ? 'block' : 'none',
                                                        height: '40px',
                                                        maxHeight: '40px',
                                                        overflowY: 'scroll',
                                                        width: '120px',
                                                        paddingLeft: '10px',
                                                        fontSize: '12px',
                                                        border: '1px solid #2e69ff',
                                                        boxShadow: '0 0 0 3px rgba(46,105,255,.25)',
                                                        color: '#2c3345',
                                                        borderRadius: '4px'
                                                    }}
                                                    value={textareaValue}
                                                    readOnly={currentPage === '/build' || currentPage === "/build/CardForm" || condition}

                                                    onChange={handleTextareaChange}
                                                    placeholder="گزینه اول
                      گزینه دوم
                      گزینه سوم
                      "
                                                ></textarea>
                                            </Grid>
                                            :
                                            (
                                                <Grid display={'inline-block'}>
                                                    <input
                                                        id={input.id}
                                                        type={'text'}
                                                        className="FillInput"
                                                        ref={inputRef}
                                                        style={{
                                                            border: 'none',
                                                            outline: 'none',
                                                            userSelect: 'text',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                            width: '100%',
                                                            fontSize: !input.headingFlag ? `${input.fontSize}px` : `${headingSize}px`,
                                                            fontWeight: input.bold ? 'bold' : 500,
                                                            textDecoration: input.underline ? 'underline' : '',
                                                            fontStyle: input.italic ? 'italic' : '',
                                                            lineHeight: 1.45,
                                                            color: input.color,
                                                            borderBottom: input.id === 'fifth' || input.id === 'second' || input.id >= 6 ? '1px solid #c3cad8' : 'none',
                                                        }}
                                                        value={input.value}
                                                        readOnly={(isReadOnly && input.readOnly) || condition}
                                                        onChange={(event) => handleInputChange(index, event)}
                                                        onClick={() => { setSelectIndex(index) }}
                                                    />
                                                </Grid>
                                            )

                                    }
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Grid>

            </Grid>

        </Grid>
    )
}
export default FillInTheBlank
