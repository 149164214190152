import moment from 'moment-timezone';

export const convertToPersianTime = (time) => {

    if (!/^\d{2}:\d{2}:\d{2}$/.test(time)) {
        return "Invalid Time Format";
    }

    return moment
        .utc(`1970-01-01T${time}Z`)
        .tz('Asia/Tehran')
        .format('HH:mm:ss');
};
