import { Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Circles } from 'react-loader-spinner';
import CustomToast from "../CustomToast/CustomToast";
import LoginSelect from "../LoginSelect/LoginSelect";
import {useCountry} from "../../Context/CountryContext";

const ResetPasswordComponent = () => {

    const { countryId } = useCountry();

    let navigate = useNavigate()


    const [phoneValue, setPhoneValue] = useState('')
    const [passwordValue, setPasswordValue] = useState('')
    const [tokenValue, setTokenValue] = useState('')
    const [country, setCountry] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [phoneError, setPhoneError] = useState('');
    const [countryError, setCountryError] = useState('');



    const handleChangePhoneValue = (e) => {
        setPhoneValue(e.target.value)
    }


    const handleChangePasswordValue = (e) => {
        setPasswordValue(e.target.value)
    }


    const handleChangeTokenValue = (e) => {
        setTokenValue(e.target.value)
    }

    const handleSubmit = () => {

        const data = {
            phone: Number(phoneValue),
            countryId: countryId,
            password: passwordValue,
            token: Number(tokenValue)
        };

        const apiUrl = 'https://formmaker-api.altynlogistics.com/api/password/reset'

        setLoading(true); // Start the loading state


        axios
            .post(apiUrl, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setLoading(false); // End the loading state

                if (response.data.status) {

                    navigate('/');

                } else {
                    response.data.errors.length > 0 && toast(<CustomToast message={response.data.errors[0]} state={'error'} />)
                    setPhoneError(response.data.validations.phone && response.data.validations.phone[0])
                    // isSignUp ? toast(<CustomToast message={'ثبت نام انجام نشد لطفا دوباره امتحان کنید.'} state={'error'} />) : toast(<CustomToast message={'ورود ناموفق بود. لطفا اعتبار خود را بررسی کنید.'} state={'error'} />)
                }
            })
            .catch((err) => {
                setLoading(false); // End the loading state on error
                console.error('Error:', err);
                // alert('An error occurred. Please try again later.');
            });
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://formmaker-api.altynlogistics.com/api/countries');
                setCountry(response.data.output);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);



    return (
        <>
            <Grid display={loading ? 'flex' : 'none'} alignItems="center" justifyContent="center" height="100vh" width={'100vw'} bgcolor={'darkgray'} position={'fixed'} top={0} right={0} style={{ opacity: '0.8', zIndex: 1000 }} >
                <Circles color="#FF6600" height={80} width={80} /> {/* Show loading spinner */}
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'36px'}>
                <Grid width={'100px'} height={'100px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img src='/assets/images/AltynLogo.svg' alt='' style={{ width: '100%', height: '100%' }} />
                </Grid>
            </Grid>
            <Grid width={'90%'} maxWidth={'500px'} display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>

            </Grid>
        </>

    )
}

export default ResetPasswordComponent