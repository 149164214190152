import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, Typography, useTheme } from "@mui/material";


import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';


import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'jalali-moment';
import '../App.css'
import 'persianjs';
import { useFormContext } from '../Context/Context';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const Appointment = ({ id, header }) => {


    const { drawerData } = useFormContext();

    const existingComponentIndex = drawerData.findIndex(component => component.id === id);



    let switchInterval = drawerData[existingComponentIndex]?.state.interval ? drawerData[existingComponentIndex].state.interval : 15

    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.appointmentValue ? drawerData[existingComponentIndex].state.appointmentValue : 'وقت ملاقات'

    let switchSelectTime = drawerData[existingComponentIndex]?.state.mainSavedSelectTime ? drawerData[existingComponentIndex].state.mainSavedSelectTime : null

    const location = useLocation();
    const currentPage = location.pathname;


    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);

    const [appointmentValue, setAppointmentValue] = useState(switchHeader);
    const [border, setBorder] = useState(false);
    const [currentDate, setCurrentDate] = useState(moment());
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedDay, setSelectedDay] = useState(moment().jDate());
    const [today, setToday] = useState(moment().jDate());
    const [todayMonth, setTodayMonth] = useState(null)
    const [tehranTime, setTehranTime] = useState('');
    const [show, setShow] = useState(false)
    const [todayPersianDate, setTodayPersianDate] = useState('');
    const [year, setYear] = useState('');
    const [showTimeInterval, setShowTimeInterval] = useState(false)
    const [activeTime, setActiveTime] = useState(1)
    const [selectTime, setSelectTime] = useState('')
    const [time, setTime] = useState(new Date());
    const [friday, setFriday] = useState(false)
    const [savedSelectTime, setSavedSelectTime] = useState(switchSelectTime);
    const [persianDateTime, setPersianDateTime] = useState(drawerData[existingComponentIndex]?.state.mainPersianDateTime);
    const [active, setActive] = useState(null)
    const [condition, setCondition] = useState(false)
    const [currentYaer, setCurrentYear] = useState(null)


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm" || currentPage.startsWith("/User/FillForm/")



    const gridRef = useRef(null);
    const theme = useTheme();
    let HeadingColor = theme.palette.blue[700];


    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly




    const fillInputValue = () => {


        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.appointmentValue = appointmentValue
                drawerData[existingComponentIndex].state.mainSavedSelectTime = savedSelectTime
                drawerData[existingComponentIndex].state.mainPersianDateTime = persianDateTime
                drawerData[existingComponentIndex].state.interval = interval
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }


    const CustomToast = ({ message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'red',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <ErrorIcon fontSize={'large'} />
                    {message}
                </Grid>

        );
    };


    const daysInMonth = currentDate.jDaysInMonth();
    const firstDayOfMonth = moment(currentDate).startOf('jMonth');

    const startTime = moment('2024-01-21T09:00:00');
    const endTime = moment('2024-01-21T21:00:00');
    const [interval, setInterval] = useState(switchInterval);

    const minYear = year;

    const minuteData = [
        { id: 1, title: '15 دقیقه', time: 15 },
        { id: 2, title: '30 دقیقه', time: 30 },
        { id: 3, title: '45 دقیقه', time: 45 },
        { id: 4, title: '60 دقیقه', time: 60 },
    ]


    const handlePhoneChange = (event) => {
        setAppointmentValue(event.target.value);
    };

    const handleHeadingFocus = (event) => {
        event.target.select();
    };


    const handleTimeInterval = (time, id) => {
        setInterval(time)
        setActiveTime(id)
    }


    const persianToEnglish = (persianDigit) => {
        const persianDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
        const englishDigits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        let englishDigit = persianDigit;
        for (let i = 0; i < persianDigits.length; i++) {
            englishDigit = englishDigit.replace(new RegExp(persianDigits[i], 'g'), englishDigits[i]);
        }
        return englishDigit;
    };


    const handleSetTime = (times, id) => {

        setSelectTime(persianToEnglish(times));
        setActive(id)

    };


    const handleShowInterval = () => {
        if (!isReadOnly) {
            setShowTimeInterval(!showTimeInterval)
        }
    }

    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };

    const handleDayClick = (day, isFriday) => {
        console.log(day, 'day')
        if (currentPage === '/Publish') {
            if (!isFriday) {
                setSelectedDay(day);
            }
        }

    };


    const convertToPersianNumber = (number) => {
        const persianDigits = [
            '۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'
        ];

        return String(number).replace(/\d/g, (digit) => persianDigits[digit]);
    };


    const convertToPersianDate = (date) => {
        return moment(date).format('jYYYY/jM/jD');
    };

    const convertToPersianMonth = (month) => {
        const persianMonths = [
            'فروردین', 'اردیبهشت', 'خرداد', 'تیر',
            'مرداد', 'شهریور', 'مهر', 'آبان',
            'آذر', 'دی', 'بهمن', 'اسفند'
        ];
        return persianMonths[month];
    };

    const convertToPersianWeekday = (weekday) => {
        const persianWeekdays = [
            'شنبه', 'یک‌شنبه', 'دو‌شنبه', 'سه‌شنبه',
            'چهار‌شنبه', 'پنج‌شنبه', 'جمعه'
        ];
        return persianWeekdays[weekday];
    };

    const getPersianDate = () => {
        // Calculate currentDate based on selectedDay
        const updatedDate = currentDate.clone().jDate(selectedDay);

        // Format the updatedDate in the desired Persian format
        return convertToPersianNumber(moment(updatedDate).format('jYYYY/jM/jD'));
    };


    const handleMonthChange = (event) => {
        const selectedMonth = parseInt(event.target.value, 10);
        setCurrentDate((prevDate) => {
            const newDate = moment(prevDate);
            newDate.jMonth(selectedMonth);
            return newDate;
        });
    };


    const changeMonth = (increment) => {
        setCurrentDate((prevDate) => {
            const newDate = moment(prevDate);
            newDate.jMonth(prevDate.jMonth() + increment);

            // Get a new moment object for today's date
            const today = moment();

            // Calculate the minimum allowed month and year
            const minMonth = today.jYear() === newDate.jYear() ? today.jMonth() : 0;
            const minYear = today.jYear();

            // Allow going back to previous months only if not in the current year and month is greater than or equal to minMonth
            if (increment === -1 && newDate.jYear() === minYear && newDate.jMonth() < minMonth) {
                return prevDate;
            }

            return newDate;
        });
    };

    const changeYear = (increment) => {
        setCurrentDate((prevDate) => {
            const newDate = moment(prevDate);
            newDate.jYear(prevDate.jYear() + increment);

            // Get a new moment object for today's date
            const today = moment();

            // Calculate the minimum allowed year
            const minYear = today.jYear();

            // Allow going back to previous years only if year is greater than or equal to minYear
            if (increment === -1 && newDate.jYear() < minYear) {
                return prevDate;
            }

            return newDate;
        });
    };


    const changeDay = (increment) => {

        setSelectedDay((prevDay) => {
            const newDay = prevDay ? prevDay + increment : today + increment;

            // Check if the newDay is greater than or equal to today
            if (newDay >= today) {
                return newDay <= daysInMonth ? newDay : today;
            }

            // If newDay is less than today, set today as the new day
            return today;
        });


    };


    const handleYearChange = (event) => {
        const selectedYear = parseInt(event.target.value, 10);
        setCurrentDate((prevDate) => {
            const newDate = moment(prevDate);
            newDate.jYear(selectedYear);
            return newDate;
        });
    };


    const renderCalendar = () => {
        const calendar = {};
        let currentDay = moment(firstDayOfMonth);

        const daysInMonth = currentDate.jDaysInMonth();

        const firstDayOfWeek = currentDay.jDay();

        // Weekdays header
        const weekdaysHeader = (
            <Grid bgcolor={'#eef3ff'} mb={'10px'}>
                <Grid key="weekdays" className="calendar-weekdays" display={'flex'} alignItems={'center'}
                    justifyContent={'space-between'} p={'0px 10px'} bgcolor={'#eef3ff'}>
                    {Array(7).fill(0).map((_, index) => (
                        <Grid key={index} className="weekday" display={'flex'} alignItems={'flex-start'}
                            justifyContent={'center'} lineHeight={'46px'} color={'#4166cd'}>
                            {convertToPersianWeekday(index)}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );

        for (let i = 0; i < firstDayOfWeek; i++) {
            calendar[i] = [<div key={`placeholder-${i}`} className="calendar-day-column" />];
        }

        // Days of the month
        for (let i = 0; i < daysInMonth; i++) {
            const weekdayIndex = currentDay.jDay();
            calendar[weekdayIndex] = calendar[weekdayIndex] || [];

            const day = currentDay.jDate();
            const isDaySelected = selectedDay === day;
            let isPastDay;

            if (currentYaer < currentDate.jYear()) {
                isPastDay = false;
            } else {
                if (today > day) {
                    isPastDay = true;
                }
            }

            const isFriday = weekdayIndex === 6;

            calendar[weekdayIndex].push(
                <Grid key={i} className="calendar-day-column" display={'flex'} alignItems={'flex-end'}
                    style={isFriday || isPastDay ? { cursor: 'not-allowed', color: '#777' } : { cursor: 'pointer' }}
                    justifyContent={'center'} pb={'10px'} fontSize={'12px'} fontWeight={700}
                    onClick={isPastDay ? null : () => handleDayClick(day, isFriday)}
                    width={'40px'} height={'40px'}
                    bgcolor={isFriday || isPastDay || !isDaySelected ? 'transparent' : '#486cee'}
                    color={isDaySelected ? 'white' : 'black'} borderRadius={'4px'}
                    border={today === i + 1 ? '1px solid #8ea4f5' : '1px solid transparent'}>
                    {convertToPersianNumber(day)}
                </Grid>
            );

            currentDay.add(1, 'day');
        }

        // Render the calendar
        return (
            <Grid>
                {weekdaysHeader}
                <Grid className="calendar-columns-container" p={'10px 0px'} display={'flex'} alignItems={'flex-start'}
                    justifyContent={'space-between'}>
                    {Object.keys(calendar).map((weekdayIndex) => (
                        <Grid key={weekdayIndex} className="calendar-week">
                            {calendar[weekdayIndex]}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    };


    const dropdownStyles = {
        /* for Firefox */
        MozAppearance: 'none',
        /* for Safari, Chrome, Opera */
        WebkitAppearance: 'none',
        /* for IE10 */
        msExpand: 'none',
    };

    const updateClock = () => {
        const now = moment();
        const tehranNow = now.utcOffset('+0330');
        const hours = tehranNow.hours();
        const amPm = hours >= 12 ? 'بعد از ظهر' : 'قبل از ظهر';
        const formattedTime = tehranNow.format('hh:mm') + ' ' + amPm;

        setTehranTime(convertToPersianNumber(formattedTime));
    };





    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === "Appointment") {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        // Calculate currentDate based on selectedDay
        const updatedDate = currentDate.clone().jDate(selectedDay);

        // Calculate persianDate and persianWeekday based on the updatedDate
        const persianDate = convertToPersianNumber(convertToPersianDate(updatedDate));
        const persianWeekday = convertToPersianWeekday(updatedDate.jDay());

        // Combine persianWeekday and persianDate into a single string
        const persianDateTimeString = `${persianWeekday}، ${persianDate}`;

        // Save persianDateTimeString to state
        setPersianDateTime(persianDateTimeString);
    }, [currentDate, selectedDay]);


    useEffect(() => {

        const selectedDate = currentDate.clone().jDate(selectedDay);
        const selectedWeekday = selectedDate.jDay();
        const generateTimeSlots = () => {
            const slots = [];
            let currentTime = startTime.clone();

            while (currentTime.isBefore(endTime)) {
                const timeSlot = {
                    time: convertToPersianNumber(currentTime.format('HH:mm')), //convert to per
                    isAfternoon: currentTime.isAfter(moment('2024-01-21T12:00:00')),
                    isBeforeNoon: currentTime.isBefore(moment('2024-01-21T12:00:00'))
                };
                if (!(currentTime.isSameOrAfter(moment('2024-01-21T12:00:00')) && currentTime.isSameOrBefore(moment('2024-01-21T13:59:59')))) {
                    if (selectedWeekday !== 6) {
                        setFriday(false)
                        slots.push(timeSlot);
                    } else {
                        setFriday(true)
                    }

                }
                currentTime.add(interval, 'minutes');
            }

            setTimeSlots(slots);
        };

        generateTimeSlots();
    }, [interval]);




    useEffect(() => {
        // Update today's date when the component mounts
        setToday(moment().jDate());

        // Get the month number of today's date
        setTodayMonth(moment().jMonth() + 1)
        updateClock();

    }, []);


    useEffect(() => {
        const todayGregorianDate = moment().locale('en').format('YYYY-MM-DD');
        const todayJalaaliDate = moment(todayGregorianDate, 'YYYY-MM-DD').format('jYYYY/jMM/jDD');
        const year = moment(todayJalaaliDate, 'jYYYY/jMM/jDD').jYear();

        setTodayPersianDate(todayJalaaliDate);
        setYear(year);
    }, []);


    useEffect(() => {


        const hour = time.getHours();
        const minute = time.getMinutes();

        const isTimeSelected = selectTime !== '';

        if (isTimeSelected) {

            let selected = selectTime.split(':').map(item => persianToEnglish(item));
            selected = selected.map(item => parseInt(item));
            let shouldSave = false;

            if (selectedDay > today) {
                shouldSave = true;
                toast(<CustomToast message='زمان انتخابی شما با موفقیت ثبت شد' state={'success'} />);
            } else {
                if (selected[0] > hour) {
                    shouldSave = true;
                    toast(<CustomToast message='زمان انتخابی شما با موفقیت ثبت شد' state={'success'} />);
                } else if (selected[0] < hour) {
                    shouldSave = false;
                    toast(<CustomToast message='لطفا زمان دیگری را انتخاب کنید' state={'not'} />);
                } else if (selected[1] < minute) {
                    shouldSave = false;
                    toast(<CustomToast message='لطفا زمان دیگری را انتخاب کنید' state={'not'} />);
                } else {
                    shouldSave = true;
                    toast(<CustomToast message='زمان انتخابی شما با موفقیت ثبت شد' state={'success'} />);
                }
            }


            if (shouldSave) {
                const saveTimeout = setTimeout(() => {
                    setSavedSelectTime(selectTime);
                }, 100);
                return () => clearTimeout(saveTimeout);
            } else {
                const saveTimeout = setTimeout(() => {
                    setSavedSelectTime('');
                }, 100);

                return () => clearTimeout(saveTimeout);
            }
        }
    }, [selectTime]);



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, appointmentValue, selectTime, persianDateTime, savedSelectTime, interval]);



    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    useEffect(() => {
        setCurrentYear(Number(todayPersianDate.split('/')[0]))
    }, [todayPersianDate])




    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'}
            ref={gridRef} onClick={() => setBorder(true)}
            border={border && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'} display={'flex'} alignItems={'center'} gap={'5px'}>
                <input
                    className="customHead-input"
                    placeholder={'Type a question'}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                    }}
                    readOnly={isReadOnly || condition}
                    value={appointmentValue}
                    onChange={handlePhoneChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
                <Grid style={{ cursor: 'pointer' }} onClick={handleShowInterval} width={'40px'} height={'30px'} borderRadius={'5px'} bgcolor={'darkgrey'} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                    <SettingsIcon fontSize='large' style={{ color: 'white' }} />
                </Grid>
            </Grid>
            <Grid
                style={{
                    display: showTimeInterval ? 'flex' : 'none',
                    opacity: showTimeInterval ? 1 : 0, // For fading animation
                    transition: 'opacity 0.5s ease-in-out' // Animation duration and easing
                }}
                alignItems={'center'}
                gap={'20px'}
                mb={'20px'}
            >
                {
                    minuteData.map(
                        item =>
                            <Grid style={{ cursor: 'pointer' }} key={item.id} onClick={() => { handleTimeInterval(item.time, item.id) }} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'65px'} height={'40px'} borderRadius={'5px'} border={'1px solid rgb(72, 108, 238)'} bgcolor={activeTime === item.id ? 'rgb(72, 108, 238)' : 'white'} p={'5px'}>
                                <Typography variant='h6' color={activeTime === item.id ? 'white' : 'rgb(72, 108, 238)'}>{item.title}</Typography>
                            </Grid>

                    )
                }
            </Grid>
            <Grid width={'100%'} display={{ xs: 'block', sm: 'flex' }} alignItems={'flex-start'} justifyContent={'flex-start'} gap={'12px'}>
                <Grid className={'calender'} border={'1px solid #c3cad8'} borderRadius={'6px'} width={{ xs: "100%", sm: '50%' }} mb={{ xs: '16px', sm: '0px' }}>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} p={'10px 12px'} onClick={() => { setShow(!show) }}>
                        <Typography variant={'h5'}>{getPersianDate()}</Typography>
                        <CalendarMonthIcon style={{ color: 'darkgray' }} />
                    </Grid>
                    <Grid width={'100%'} height={'1px'} bgcolor={'#ecedf3'}></Grid>
                    <Grid display={{ xs: show ? 'flex' : 'none', sm: 'flex' }} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid
                            width={'50%'}
                            borderLeft={'1px solid #ecedf3'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            p={'10px 12px'}
                        >
                            <Typography variant={'h5'} width={'100%'}>
                                <select
                                    disabled={condition}
                                    value={currentDate.jMonth()}
                                    onChange={handleMonthChange}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        width: '100%',
                                        fontSize: '16px', ...dropdownStyles
                                    }}
                                >
                                    {Array.from({ length: 12 }, (_, i) => i).map((month) => (
                                        <option key={month} value={month}>
                                            {convertToPersianMonth(month)}
                                        </option>
                                    ))}
                                </select>
                            </Typography>
                            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <KeyboardArrowUpIcon fontSize={'small'} style={{ color: 'darkgray', cursor: "pointer" }}
                                    onClick={() => changeMonth(-1)} />
                                <KeyboardArrowDownIcon fontSize={'small'} style={{ color: 'darkgray', cursor: "pointer" }}
                                    onClick={() => changeMonth(1)} />
                            </Grid>
                        </Grid>
                        <Grid
                            width={'50%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            p={'10px 12px'}
                        >
                            <select
                                disabled={condition}
                                value={currentDate.jYear()}
                                onChange={handleYearChange}
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    width: '100%',
                                    fontSize: '16px', ...dropdownStyles
                                }}
                            >
                                {Array.from({ length: 20 }, (_, i) => i).map((index) => {
                                    const year = minYear + index;
                                    return (
                                        <option key={index} value={year} style={{ padding: '10px' }}>
                                            {convertToPersianNumber(year)}
                                        </option>
                                    );
                                })}
                            </select>
                            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <KeyboardArrowUpIcon fontSize={'small'} style={{ color: 'darkgray', cursor: 'pointer' }}
                                    onClick={() => changeYear(-1)} />
                                <KeyboardArrowDownIcon fontSize={'small'} style={{ color: 'darkgray', cursor: 'pointer' }}
                                    onClick={() => changeYear(1)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid width={'100%'} height={'1px'} bgcolor={'#ecedf3'} display={{ xs: show ? 'block' : 'none', sm: 'block' }}></Grid>
                    <Grid id="monthsContainer" display={{ xs: show ? 'block' : 'none', sm: 'block' }}>
                        {
                            renderCalendar()
                        }
                    </Grid>
                </Grid>
                <Grid className={'TimeZone'} width={{ xs: "100%", sm: '50%' }}>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'16px'}>
                        <Typography variant={'h5'}>{persianDateTime}</Typography>
                        <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'12px'}>
                            <Grid
                                width={'26px'}
                                height={'26px'}
                                borderRadius={'50%'}
                                bgcolor={'#ecedf3'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                onClick={() => changeDay(-1)}
                            >
                                <KeyboardArrowRightIcon style={{ color: 'darkgray', cursor: 'pointer' }} />
                            </Grid>
                            <Grid
                                width={'26px'}
                                height={'26px'}
                                borderRadius={'50%'}
                                bgcolor={'#ecedf3'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                onClick={() => changeDay(1)}
                            >
                                <KeyboardArrowLeftIcon style={{ color: 'darkgray', cursor: 'pointer' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={'Appointment'} width={'100%'} display={'flex'} alignItems={'center'}
                        flexWrap={'wrap'} gap={'5px'} height={'280px'} sx={{
                            overflow: 'auto', scrollbarWidth: 'none', '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                        <>
                            {
                                friday ?
                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'} gap={'5px'}>
                                        <WarningIcon fontSize='large' style={{ color: '#E8751A' }} />
                                        <Typography pt={'2px'} variant='h6' fontWeight={700} color={'#E8751A'}>زمانی برای نمایش وجود ندارد. لطفا روز دیگری را انتخاب کنید</Typography>
                                    </Grid>
                                    :
                                    timeSlots.map((slot, index) => (
                                        <Grid onClick={() => { handleSetTime(slot.time, index) }} key={index} height={'44px'} width={'49%'} display={'flex'} alignItems={'center'}
                                            justifyContent={'center'} border={'1px solid #486cee'} borderRadius={'6px'} mb={'8px'}
                                            style={{ cursor: 'pointer' }} bgcolor={active === index ? '#486cee' : 'transparent'}>
                                            <Typography color={active === index ? 'white' : '#486cee'} variant={'h5'} textAlign={'center'}>
                                                {slot.time} {slot.isAfternoon ? 'بعد از ظهر' : 'قبل از ظهر'}
                                            </Typography>
                                        </Grid>
                                    ))
                            }
                        </>

                    </Grid>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'8px'}
                        style={{ cursor: 'pointer' }}>
                        <AccessTimeIcon />
                        <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'4px'}>
                            <Typography variant={'subtitle1'} color={'rgb(44, 51, 69)'} fontWeight={500}>آسیا /
                                تهران</Typography>
                            <Typography variant={'subtitle1'} color={'rgb(44, 51, 69)'}
                                fontWeight={500}>({tehranTime})</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Appointment;



