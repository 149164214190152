import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import FormItem from './FormItem';

const PublishFormItem = ({ active }) => {


    return (
        <>
            <FormItem active={active} />
        </>

    )
}

export default PublishFormItem