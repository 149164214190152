import { Grid, Typography } from '@mui/material'
import React from 'react'
import FormSettingSelect from './FormSettingSelect'
import FormSettingLimited from './FormSetting/FormSettingLimited';

const SettingStatusForm = ({handleSelectChange , modalOption , statusForm}) => {
    return (
        <>
            <Grid>
                <Typography mb={'4px'} variant='h5' color={'rgba(10,21,81,1)'} fontWeight={500} lineHeight={'20px'}>وضعیت فرم</Typography>
                <Typography mb={'12px'} variant='h6' color={'rgba(108,115,168,1)'} fontWeight={500} lineHeight={'18px'}>فرم خود را فعال، غیرفعال یا مشروط کنید</Typography>
            </Grid>
            <Grid>
                <FormSettingSelect options={modalOption} id={0} onSelectChange={handleSelectChange} name={'FormStatus'} />
                <FormSettingLimited statusForm={statusForm}/>
            </Grid>
        </>
    )
}

export default SettingStatusForm