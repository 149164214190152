import { usePageCount } from "../../Context/PageCount";
import { Box, Pagination } from "@mui/material";
import React from "react";


const PaginationForms = ({ count }) => {

    const { pageCount, setPageCount } = usePageCount();

    const handleChange = (event, value) => {
        setPageCount(value);
    };

    return (
        <Box spacing={2} dir={'ltr'} display={'flex'} alignItems={'center'} >
            <Pagination count={count} page={pageCount} onChange={handleChange}
                size="large"
                sx={{
                    "& .MuiPaginationItem-root": {
                        fontSize: "15px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '3px'
                    },
                    "& .MuiPaginationItem-previousNext": {
                        fontSize: "36px",
                    },
                }}
            />
        </Box>
    )
}


export default PaginationForms