export const optionData = [
    {
        id: 0,
        name: 'Save and Continue Later',
        title: 'ذخیره و بعدا ادامه دهید',
        subTitle: 'به کاربران فرم اجازه دهید فرم ارسالی خود را ذخیره کنند و بعداً آن را ادامه دهند',
        optionValue: [
            { value: 'غیرفعال', label: 'غیرفعال' },
            { value: 'فعال', label: 'فعال' },
        ],
        flag: true,
        linkFlag: true,
        active: 0
    },
    {
        id: 1,
        name: 'Unique Submission',
        title: 'ارسال منحصر به فرد',
        subTitle: 'از کوکی ها یا آدرس IP برای جلوگیری از ارسال چند فرم استفاده کنید',
        optionValue: [
            { value: 'بدون چک', label: 'بدون چک' },
            { value: 'فقط کوکی ها را بررسی کنید', label: 'فقط کوکی ها را بررسی کنید' },
            { value: 'کوکی ها و آدرس IP را بررسی کنید', label: 'کوکی ها و آدرس IP را بررسی کنید' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 2,
        name: 'Unique Field',
        title: 'فیلد منحصر به فرد',
        subTitle: 'مقادیر وارد شده پیش‌تر را برای یک فیلد خاص قبول نکنید.',
        optionValue: [
            { value: 'بدون چک', label: 'بدون چک' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 3,
        name: 'Form Accessibility',
        title: 'قابلیت دسترسی به فرم',
        subTitle: 'دسترسی فرم خود را بررسی کنید',
        optionValue: [
            { value: 'غیرفعال', label: 'غیرفعال' },
            { value: 'فعال', label: 'فعال' },
        ],
        flag: true, linkFlag: false,
        active: 0
    },
    { id: 4, title: 'عنوان صفحه', subTitle: 'لطفاً یک عنوان وارد کنید که به برای عنوان صفحه در مرورگر نمایش داده شود.', optionValue: '', flag: true, linkFlag: false, active: 0 },
    {
        id: 5,
        name: 'Clear Hidden Field Values',
        title: 'پاک کردن مقادیر فیلدهای پنهان',
        subTitle: 'انتخاب کنید که آیا مقادیر برای فیلدهای پنهان شده توسط منطق شرطی پاک شوند یا خیر',
        optionValue: [
            { value: 'وقتی پنهان است پاک شود', label: 'وقتی پنهان است پاک شود' },
            { value: 'هنگام ارسال پاک شود', label: 'هنگام ارسال پاک شود' },
            { value: 'پاک نشود', label: 'پاک نشود' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 6,
        name: 'Highlight Effect',
        title: 'جلوه برجسته',
        subTitle: 'هایلایت پس زمینه زرد را برای فیلدهای فرم فعال یا غیرفعال کنید',
        optionValue: [
            { value: 'غیرفعال', label: 'غیرفعال' },
            { value: 'فعال', label: 'فعال' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 7,
        name: 'Form Layout',
        title: 'طرح بندی فرم',
        subTitle: '',
        optionValue: [
            { value: 'همه سوالات در یک صفحه', label: 'همه سوالات در یک صفحه' },
            { value: 'تک سوال در هر صفحه', label: 'تک سوال در هر صفحه' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 8,
        name: 'Show Error Navigation',
        title: 'نمایش راهنمای خطا',
        subTitle: 'اجازهٔ پیمایش بین خطاهای فرم ',
        optionValue: [
            { value: 'بله', label: 'بله' },
            { value: 'خیر', label: 'خیر' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 9,
        name: 'Prevent Cloning',
        title: 'جلوگیری از شبیه سازی',
        subTitle: 'از شبیه سازی این فرم توسط سایر کاربران جلوگیری کنید',
        optionValue: [
            { value: 'بله', label: 'بله' },
            { value: 'خیر', label: 'خیر' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
    {
        id: 10,
        name: 'Allow Browser Autocomplete',
        title: 'اجازه تکمیل خودکار مرورگر',
        subTitle: 'به مرورگرها اجازه ذخیره و تکمیل خودکار فیلدهای فرم را بدهید.',
        optionValue: [
            { value: 'بله', label: 'بله' },
            { value: 'خیر', label: 'خیر' },
        ],
        flag: true,
        linkFlag: false,
        active: 0
    },
]