import axios from "axios";
import { toast } from "react-toastify";
import CustomToast from "../Component/CustomToast/CustomToast";

export default function handleDeleteForm(formIdDeleted, fetchList, setLoading) {

    const token = localStorage.getItem("authToken");

    const data = {
        formIds: formIdDeleted,
    };

    setLoading(true);

    axios
        .delete("https://formmaker-api.altynlogistics.com/api/form/mass", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
        .then((response) => {
            toast(<CustomToast message="فرم / فرم های مورد نظر شما با موفقیت حذف شد" state={"success"} />);
            if (fetchList) fetchList();
            setLoading(false);
        })
        .catch((error) => {
            console.error("Error deleting form:", error);
        })
        .finally(() => {
            setLoading(false);
        });
}
