import React, { useRef, useState } from 'react'
import { useLoading } from '../../Context/LoadingContext';
import axios from 'axios';
import { useFormContext } from '../../Context/Context';
import { useTitleContext } from '../../Context/TitleContext';
import { useEditMode } from '../../Context/EditModeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import FormItem from '../FormItem';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import Heading from '../Heading';
import FullName from '../FullName';
import Email from '../Email';
import Address from '../Address';
import Phone from '../Phone';
import DatePicker from '../DatePicker';
import ShortText from '../ShortText';
import LongText from '../LongText';
import Number from '../Number';
import FileUpload from '../FileUpload';
import Time from '../Time';
import Submit from '../Submit';
import Spinner from '../Spinner';
import Image from '../Image';
import StarRating from '../StarRating';
import ScaleRating from '../ScaleRating';
import MultiChoice from '../MultiChoice';
import SingleChoice from '../SingleChoice';
import Signature from '../Signature';
import DropDown from '../DropDown';
import Paragraph from '../Paragraph/Editor';
import Appointment from '../Appointment';
import ProductList from '../ProductList';
import Captcha from '../Captcha';
import FillInTheBlank from '../FillInTheBlank';
import Table from '../Table';
import Divider from '../Divider';
import Section from '../Section';
import { toast } from 'react-toastify';
import CustomToast from '../CustomToast/CustomToast';
import { useComponentTitleContext } from '../../Context/ComponentTitleContext';
import { useCaptcha } from '../../Context/CaptchaContext';

const Forms = () => {

    const token = localStorage.getItem('authToken');

    const { drawerData, handleDrawerData, handleDelete, updateComponentStatesById, setDrawerData } = useFormContext();
    const { setLoading } = useLoading()
    const { setEditMode } = useEditMode();
    const { title, setTitle } = useTitleContext();
    const { updateTitles } = useComponentTitleContext();
    const { isValidCaptcha } = useCaptcha();





    const [error, setError] = useState(null);
    const [showMessage, setShowMessage] = useState(false)


    const refs = useRef([]);


    const navigate = useNavigate()
    const location = useLocation();
    const currentPage = location.pathname;

    let sendToServerData = [];



    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let Typo = isUpXxl ? 'h6' : 'subtitle2';


    const COMPONENT_MAP = {
        Heading: Heading,
        FullName: FullName,
        Email: Email,
        Address: Address,
        Phone: Phone,
        DatePicker: DatePicker,
        ShortText: ShortText,
        LongText: LongText,
        Number: Number,
        FileUpload: FileUpload,
        Time: Time,
        Submit: Submit,
        Spinner: Spinner,
        Image: Image,
        StarRating: StarRating,
        ScaleRating: ScaleRating,
        MultiChoice: MultiChoice,
        SingleChoice: SingleChoice,
        Signature: Signature,
        DropDown: DropDown,
        Paragraph: Paragraph,
        Appointment: Appointment,
        ProductList: ProductList,
        Captcha: Captcha,
        FillInTheBlank: FillInTheBlank,
        Table: Table,
        Divider: Divider,
        Section: Section,
    };

    const handleChangeState = (id, newState) => {
        updateComponentStatesById(id, newState);
    };


    const handleFilterTitle = (drawerData) => {
        const componentFilter = drawerData.map(({ id, title }) => ({
            id,
            title,
            icon: null,
            peTitle: null,
        }));

        updateTitles(componentFilter);
    }


    const handleUpdateForm = (formId, data) => {
        // Replace `baseUrl` with your actual base URL
        const baseUrl = 'https://formmaker-api.altynlogistics.com/api';

        const token = localStorage.getItem('authToken');


        return axios
            .post(`${baseUrl}/form/${formId}/response`, { response: data }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Form updated successfully:', response.data);
                return response.data;
            })
            .catch((error) => {
                console.error('Error updating the form:', error.response || error.message);
                throw error; // Reject with the error
            });
    };

    const handleFillFormSubmit = () => {

        let formId = localStorage.getItem('FormId')


        if (drawerData.length === 0) {
            console.error("Components array is empty. Cannot send empty data.");
            toast(<CustomToast message="لطفا ابتدا فرم را پر کنید" state={'warning'} />);
            return;
        }


        handleFilterTitle(drawerData)


        sendToServerData = [...drawerData]

        let response = {
            title: title,
            forms: JSON.stringify({ sendToServerData })
        }

        setShowMessage(true)

        if (isValidCaptcha === 'valid') {

            handleUpdateForm(formId, response)
                .then((updatedForm) => {
                    console.log('Updated Form Data:', updatedForm);
                    toast(<CustomToast message="پاسخ شما ذخیره شد" state={'success'} />);
                    navigate('/Main')

                })
                .catch((error) => {
                    toast(<CustomToast message="پاسخ شما ذخیره نشد! لطفا چند لحظه دیگر مجددا تلاش کنید" state={'not'} />);
                });

        } else {
            toast(<CustomToast message="کپچای نامعتبر است. لطفا دوباره تلاش کنید." state={'not'} />);
        }



    };

    return (
        <Grid>
            <Grid width={{ xs: '90%', xxs: '90%' }} maxWidth={'752px'} bgcolor={'#fff'} mx={'auto'} borderRadius={'3px'}
                boxShadow={'0 4px 4px rgba(87,100,126,.21)'} py={'40px'} px={'16px'} mt={'40px'} mb={'40px'} position={'relative'}>
                {drawerData.length === 0 ?
                    <Grid height={'126px'} width={'85%'} mx={'auto'} borderRadius={'10px'} bgcolor={'#fff'}
                        border={'2px dashed #c8ceed'} display={'flex'} alignItems={'center'}
                        justifyContent={'center'}>
                        <Grid width={'20px'} height={'20px'}>
                            <img src={'/assets/images/pointer.svg'} alt={''} />
                        </Grid>
                        <Typography variant={Typo} color={'#636a96'}>اولین سوال خود را از منوی سمت راست انتخاب
                            کنید.</Typography>
                    </Grid>
                    :
                    <Grid style={{ cursor: 'move' }}>
                        {drawerData.map((componentData, index) => {
                            const { id, title, states } = componentData;
                            const Component = COMPONENT_MAP[title];
                            if (!refs.current[index]) {
                                refs.current[index] = React.createRef();
                            }
                            return (

                                <Grid style={{ position: 'relative' }} ref={refs.current[index]}>
                                    <Component
                                        id={id}
                                        states={states}
                                        onChangeState={() => { handleChangeState(id, states) }}
                                    />
                                </Grid>
                            );
                        })}

                    </Grid>
                }
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'36px'} mt={'36px'} width={'100%'}>
                <Button variant='contained' color='success' sx={{ width: { xs: '50%', xxs: '35%', sm: '30%' }, p: '15px 0px' }} onClick={handleFillFormSubmit}>
                    <Typography variant='h4' fontWeight={700} pt={'5px'}>ارسال</Typography>
                </Button>
            </Grid>
        </Grid>

    )
}

export default Forms