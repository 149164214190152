import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { useTitleContext } from '../../Context/TitleContext';
const ResponseFormListHeader = () => {

    const { title } = useTitleContext();

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);


    const handleNavigateMainPage = () => {
        navigate('/Main')
    }

    return (
        <>
            <Grid display={loading ? 'flex' : 'none'} alignItems="center" justifyContent="center" height="100vh" width={'100vw'} bgcolor={'darkgray'} position={'fixed'} top={0} right={0} style={{ opacity: '0.8', zIndex: 100000 }}>
                <Circles color="#FF6600" height={80} width={80} />
            </Grid>
            <Grid width={'100%'} height={'70px'} bgcolor={'#2b3245'} p={{ xs: '8px', md: '16px' }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid display={'flex'} alignItems={'center'} gap={{ xs: '4px', md: '8px' }}>
                    <Grid width={'60px'} height={'60px'} bgcolor={'transparent'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'4px'}>
                        <img src='/assets/images/AltynLogo.svg' alt='' style={{ width: '70%', height: '70%' }} />
                    </Grid>
                    <Typography variant='h4' color={'white'} fontWeight={700}>گروه آلتن</Typography>
                </Grid>
                <Grid display={'flex'} alignItems={'center'} gap={'4px'}>
                    <Typography variant='h3' color={'white'} fontWeight={700}>{'لیست پاسخ های فرم'}</Typography>
                    <Typography variant='h2' color={'white'} fontWeight={700}>{`:  ${title}`}</Typography>
                </Grid>
                <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                    <Button onClick={handleNavigateMainPage} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '42px', px: '16px', bgcolor: '#3674B5', color: 'white', fontSize: '16px', borderRadius: '4px', '&:hover': { bgcolor: '#074799' } }}>
                        بازگشت
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ResponseFormListHeader