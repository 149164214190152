import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DateDisableForm from './DateDisableForm';
import SubmissionLimit from './SubmissionLimit';
import TimeAndDateDisableForm from './TimeAndDateDisableForm';

const FormSettingLimited = ({ statusForm }) => {


    const [Component, setComponent] = useState(null);


    useEffect(()=>{
        if(statusForm=== "در تاریخ خاصی غیرفعال کنید "){
            setComponent(<DateDisableForm />) 
        }else if(statusForm==="غیرفعال کردن در حد مجاز ارسال "){
            setComponent(<SubmissionLimit />)
        }else if (statusForm==="در تاریخ خاص یا در محدودیت ارسال غیرفعال شود "){
            setComponent(<TimeAndDateDisableForm />)
        }else{
            setComponent(<></>)
        }

    } , [statusForm])


    return (
        <Grid>
            {
                Component
            }
        </Grid>
    )
}

export default FormSettingLimited