import React, { useContext, useEffect, useRef, useState } from 'react';
import DraggableComponent from './DraggableComponent';
import Heading from './Heading';
import FullName from './FullName';
import Email from './Email';
import Address from './Address';
import Phone from './Phone';
import DatePicker from './DatePicker';
import ShortText from './ShortText';
import LongText from './LongText';
import Number from './Number';
import FileUpload from './FileUpload';
import Time from './Time';
import Submit from './Submit';
import Spinner from './Spinner';
import Image from './Image';
import StarRating from './StarRating';
import ScaleRating from './ScaleRating';
import MultiChoice from './MultiChoice';
import SingleChoice from './SingleChoice';
import Signature from './Signature';
import DropDown from './DropDown';
import { Grid, Typography, useMediaQuery, Button } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Paragraph from './Paragraph/Editor';
import Appointment from './Appointment';
import ProductList from './ProductList';
import Captcha from './Captcha';
import FillInTheBlank from './FillInTheBlank';
import Table from './Table';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from './Divider';
import Section from './Section';
import { Droppable } from "./Droppable";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useComponentTitleContext } from '../Context/ComponentTitleContext';
import { useErrorContext } from '../Context/ErrorContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import moment from 'moment-jalaali';
import FilteredValuesContext from '../Context/FilteredValuesContext';
import ThankYouModal from './ThankYouModal';
import { ThankYouMessageContext } from '../Context/ThankYouMessageContext';
import { useTitleContext } from '../Context/TitleContext';
import { useData } from '../Context/DataContext';
import { v4 as uuidv4 } from 'uuid';
import { useDraft } from '../Context/DraftContext';
import { CurrencyRuble } from '@mui/icons-material';
import { useSelectedItem } from '../Context/SelectedItemContext';
import { useEditMode } from '../Context/EditModeContext';
import { useCaptcha } from '../Context/CaptchaContext';
import { useFormLimit } from '../Context/FormLimitContext';
import handleUpdateStatusForm from "../helpers/HandleUpdateStatusForm";
import { useSubmissionLimitSetting } from '../Context/FormLimited/SubmissionLimitSettingContext';



const FormItem = ({ active }) => {


    const { updateTitles } = useComponentTitleContext();
    const { isValidCaptcha } = useCaptcha();
    const { drawerData, handleDrawerData, handleDelete, updateComponentStatesById, setDrawerData } = useFormContext();
    const { finalSelectValues } = useContext(FinalValuesContext);
    const { filteredSelectValuesList } = useContext(FilteredValuesContext);
    const { saveData } = useData();
    const { draftData, setDraftData, saveDraft } = useDraft();
    const { showThankYouMessage, setShowThankYouMessage } = useContext(ThankYouMessageContext);
    const { readOnly, setReadOnly } = useReadOnly();
    const { title, setTitle } = useTitleContext();
    const { selectedItem, setSelectedItem, selectedItemId, setSelectedItemId } = useSelectedItem();
    const { editMode, setEditMode } = useEditMode();
    const { limitSetting } = useSubmissionLimitSetting();



    const location = useLocation();
    const currentPage = location.pathname;
    const navigate = useNavigate();


    let isReadOnly = currentPage === '/Publish' || currentPage === '/Publish/CardForm'


    const [draggedItem, setDraggedItem] = useState(null);
    const [hovered, setHovered] = useState(false);
    const [showMessage, setShowMessage] = useState(false)




    const refs = useRef([]);
    const scrollToTop = useRef(null);



    let sendToServerData = [];


    const moveComponent = (dragIndex, hoverIndex) => {
        const draggedComponent = drawerData[dragIndex];
        const updatedComponents = [...drawerData];

        if (dragIndex < hoverIndex) {
            updatedComponents.splice(dragIndex, 1);
            updatedComponents.splice(hoverIndex, 0, draggedComponent);
        } else {
            updatedComponents.splice(hoverIndex, 0, draggedComponent);
            updatedComponents.splice(dragIndex + 1, 1);
        }

        // setComponents(updatedComponents);
        handleDrawerData(updatedComponents); // Update drawerData state
        // saveComponentsToLocalStorage(updatedComponents);
    };

    const deleteComponent = (id) => {
        handleDelete(id);
    };



    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling
        });
    };


    const handleChangeState = (id, newState) => {
        updateComponentStatesById(id, newState);
    };



    const showDeleteIcon = (index) => {
        const icon = refs.current[index]?.current?.querySelector('.delete-icon');
        if (icon) {
            icon.style.display = 'flex';
        }
    };

    const hideDeleteIcon = (index) => {
        const icon = refs.current[index]?.current?.querySelector('.delete-icon');
        if (icon) {
            icon.style.display = 'none';
        }
    };


    const handleClickOutside = (event) => {
        refs.current.forEach((ref, index) => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideDeleteIcon(index);
            } else {
                showDeleteIcon(index);
            }
        });
    };

    const isUpXxl = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let Typo = isUpXxl ? 'h6' : 'subtitle2';

    const COMPONENT_MAP = {
        Heading: Heading,
        FullName: FullName,
        Email: Email,
        Address: Address,
        Phone: Phone,
        DatePicker: DatePicker,
        ShortText: ShortText,
        LongText: LongText,
        Number: Number,
        FileUpload: FileUpload,
        Time: Time,
        Submit: Submit,
        Spinner: Spinner,
        Image: Image,
        StarRating: StarRating,
        ScaleRating: ScaleRating,
        MultiChoice: MultiChoice,
        SingleChoice: SingleChoice,
        Signature: Signature,
        DropDown: DropDown,
        Paragraph: Paragraph,
        Appointment: Appointment,
        ProductList: ProductList,
        Captcha: Captcha,
        FillInTheBlank: FillInTheBlank,
        Table: Table,
        Divider: Divider,
        Section: Section,
    };



    const CustomToast = ({ message, state }) => {
        return (

            state === 'success' ?
                <Grid display={"flex"} alignItems={'start'} justifyContent={'end'} gap={'5px'} style={{
                    backgroundColor: 'white',
                    color: 'green',
                    fontSize: '16px',
                    padding: '15px 10px',
                    fontWeight: 900,
                }}>
                    <CheckCircleIcon fontSize={'large'} />
                    {message}
                </Grid>
                :
                state === 'warning' ?
                    <Grid display={"flex"} alignItems={'start'} justifyContent={'flex-end'} gap={'5px'} style={{
                        backgroundColor: 'white',
                        color: 'orange',
                        fontSize: '16px',
                        padding: '15px 10px',
                        fontWeight: 900,
                        width: '100%',
                        height: '100%'
                    }}>
                        <ErrorIcon fontSize={'large'} style={{ color: 'orange' }} />
                        {message}
                    </Grid>
                    :
                    <Grid display={"flex"} alignItems={'flex-start'} justifyContent={'flex-end'} gap={'5px'} style={{
                        backgroundColor: 'white',
                        color: 'red',
                        fontSize: '16px',
                        padding: '15px 10px',
                        fontWeight: 900,
                    }}>
                        <ErrorIcon fontSize={'large'} />
                        {message}
                    </Grid>

        );
    };



    const handleFilterTitle = (drawerData) => {
        const componentFilter = drawerData.map(({ id, title }) => ({
            id,
            title,
            icon: null,
            peTitle: null,
        }));

        updateTitles(componentFilter);
    }



    const checkState = (state) => {
        let condition = true
        for (const key in state) {
            if (condition) {
                if (key.includes('main')) {
                    if (Array.isArray(state[key])) {
                        if (state[key].length === 0) {
                            condition = false
                            return false;
                        }
                    } else {
                        if (state[key] === '' || state[key] === null) {
                            condition = false
                            return false;
                        }
                    }

                }
            }

        }
        return true;
    }




    const handleConvertTitle = (title) => {
        switch (title) {
            case "عنوان":
                return "Heading";
            case "نام و نام خانوادگی":
                return "FullName";
            case "ایمیل":
                return "Email";
            case "آدرس":
                return "Address";
            case "تلفن":
                return "Phone";
            case "تقویم":
                return "DatePicker";
            case "وقت ملاقات":
                return "Appointment";
            case "امضا":
                return "Signature";
            case 'جای خالی را پر کنید':
                return "FillInTheBlank";
            case "لیست محصولات":
                return "ProductList";
            case "متن کوتاه":
                return "ShortText";
            case "متن طولانی":
                return "LongText";
            case "پاراگراف":
                return "Paragraph";
            case "بازشونده":
                return "DropDown";
            case "تک گزینه ای":
                return "SingleChoice";
            case "چند گزینه ای":
                return "MultiChoice";
            case "شماره":
                return "Number";
            case "عکس":
                return "Image";
            case "آپلود فایل":
                return "FileUpload";
            case "زمان":
                return "Time";
            case "شمارنده":
                return "Spinner";
            case "جدول ورودی":
                return "Table";
            case "رتبه بندی ستاره ای":
                return "StarRating";
            case "رتبه بندی مقیاس":
                return "ScaleRating";
            case "جدا کننده":
                return "Divider";
            case "بخش جدا کننده":
                return "Section";
            default:
                return "";
        }
    }


    const handleHideField = (data, index) => {


        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
        const doField = handleConvertTitle(filteredSelectValuesList[index][doFieldIndex].value);
        const doConditionIndex = data.findIndex(item => item.title === doField);

        if (doConditionIndex !== -1) {
            return data.splice(doConditionIndex, 1);
        } else {
            console.log('doField not found in data');
        }


    }

    const handleHideMultipleFields = (data, index) => {

        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
        let doField = []
        doField.push(filteredSelectValuesList[index][doFieldIndex].value.map(
            item => handleConvertTitle(item)
        ))


        let doConditionIndex = []

        for (let a = 0; a < doField[0].length; a++) {
            let index = data.findIndex(item => item.title === doField[0][a])
            doConditionIndex.push(index)
        }


        if (doConditionIndex.length !== 0) {

            doConditionIndex.sort((a, b) => b - a);

            doConditionIndex.map(
                item => data.splice(item, 1)
            )

        } else {
            console.log('doField not found in data');
        }

    }

    const handleRequired = (data, index) => {

        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
        const doField = handleConvertTitle(filteredSelectValuesList[index][doFieldIndex].value);

        const doConditionIndex = data.findIndex(item => item.title === doField);

        if (!checkState(drawerData[doConditionIndex].state)) {
            let alertIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
            toast(<CustomToast message={`پر کردن ${filteredSelectValuesList[index][alertIndex]?.value} الزامی است`} state={'not'} />);
            return
        }
    }

    const handleNeedMultipleOptions = (data, doConditionIndex, index) => {

        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))

        let doField = []
        doField.push(filteredSelectValuesList[index][doFieldIndex].value.map(
            item => handleConvertTitle(item)
        ))

        if (Array.isArray(doField)) {
            for (let field = 0; field < doField[0].length; field++) {

                let alertIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))

                // const doConditionIndex = data.findIndex(item => item.title === doField[0][field]);
                if (!checkState(data[doConditionIndex].state)) {
                    toast(<CustomToast message={`پر کردن ${filteredSelectValuesList[index][alertIndex]?.value} الزامی است`} state={'not'} />);
                }
            }
        }

    }

    const handleNeedMultipleOptionsNotEqual = (data, index) => {

        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))

        let doField = []
        doField.push(filteredSelectValuesList[index][doFieldIndex].value.map(
            item => handleConvertTitle(item)
        ))

        if (Array.isArray(doField)) {
            for (let field = 0; field < doField[0].length; field++) {
                const doConditionIndex = data.findIndex(item => item.title === doField[0][field]);
                if (!checkState(data[doConditionIndex].state)) {
                    toast(<CustomToast message={`پر کردن ${filteredSelectValuesList[index][doFieldIndex].value[field]} الزامی است`} state={'not'} />);
                }
            }
        }

    }

    const handleCopyFieldValue = (data, index) => {

        let fromIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("fromValue"))
        let fromTitle = handleConvertTitle(filteredSelectValuesList[index][fromIndex].value)
        let fromTargetState = data.findIndex(item => item.title === fromTitle)


        let toIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("toValue"))
        let toTitle = handleConvertTitle(filteredSelectValuesList[index][toIndex].value)
        let toTargetState = data.findIndex(item => item.title === toTitle)


        let foundKeys = [];

        let foundConditionKeys = []

        for (let key in data[fromTargetState].state) {
            if (key.includes("main")) {
                foundKeys.push(key);
            }
        }



        for (let key in data[toTargetState]?.state) {
            if (key.includes("main")) {
                foundConditionKeys.push(key);
            }
        }


        let concatenatedValues = foundKeys.map(key => data[fromTargetState].state[key]).join('');


        for (let foundConditionKey of foundConditionKeys) {

            data[toTargetState].state[foundConditionKey] = concatenatedValues

        }

        return data

    }

    const handleCopyMultipleFieldValues = (data, index) => {

        let fromIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("fromValue"))
        let formTitleList = filteredSelectValuesList[index][fromIndex].value

        let fromTitle = []

        for (let index = 0; index < formTitleList.length; index++) {
            fromTitle.push(handleConvertTitle(formTitleList[index]))
        }

        let fromTargetStates = fromTitle.map(title => data.findIndex(item => item.title === title));


        let toIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("toValue"))
        let toTitle = handleConvertTitle(filteredSelectValuesList[index][toIndex].value)
        let toTargetState = data.findIndex(item => item.title === toTitle)


        let foundKeys = [];

        let foundConditionKeys = []

        fromTargetStates.forEach(fromTargetState => {
            for (let key in data[fromTargetState].state) {
                if (key.includes("main")) {
                    foundKeys.push(key);
                }
            }
        });



        for (let key in data[toTargetState]?.state) {
            if (key.includes("main")) {
                foundConditionKeys.push(key);
            }
        }


        let concatenatedValues = foundKeys.map(key => {
            return fromTargetStates.map(fromTargetState => data[fromTargetState].state[key]).join('');
        }).join('');


        for (let foundConditionKey of foundConditionKeys) {

            data[toTargetState].state[foundConditionKey] = concatenatedValues

        }


        return data;
    }

    const handleShowField = (data, doFieldIndex, index) => {

        const doField = handleConvertTitle(filteredSelectValuesList[index][doFieldIndex].value);
        const doConditionIndex = data.findIndex(item => item.title === doField);

        if (doConditionIndex !== -1) {
            return data.splice(doConditionIndex, 1);
        } else {
            console.log('doField not found in data');
        }

    }

    const handleShowMultiField = (data, doFieldIndex, index) => {


        console.log(doFieldIndex)


        let doField = []
        doField.push(filteredSelectValuesList[index][doFieldIndex].value.map(
            item => handleConvertTitle(item)
        ))

        let doConditionIndex = []

        for (let a = 0; a < doField[0].length; a++) {
            let index = (data.findIndex(item => item.title === doField[0][a]))
            doConditionIndex.push(index)
        }
        if (doConditionIndex.length !== 0) {

            doConditionIndex.sort((a, b) => b - a);
            for (let index of doConditionIndex) {
                data.splice(index, 1);
            }
        } else {
            console.log('doField not found in data');
        }

        return data

    }

    const handleNotRequired = (data, index) => {

        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
        const doField = handleConvertTitle(filteredSelectValuesList[index][doFieldIndex].value);

        const doConditionIndex = data.findIndex(item => item.title === doField);




        if (!checkState(drawerData[doConditionIndex].state)) {
            let alertIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
            toast(<CustomToast message={`پر کردن ${filteredSelectValuesList[index][alertIndex]?.value} الزامی است`} state={'not'} />);
            return
        }
    }

    const handleNotMultiRequired = (data, index) => {

        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))

        let doField = []
        doField.push(filteredSelectValuesList[index][doFieldIndex].value.map(
            item => handleConvertTitle(item)
        ))

        if (Array.isArray(doField)) {
            for (let field = 0; field < doField[0].length; field++) {
                const doConditionIndex = data.findIndex(item => item.title === doField[0][field]);
                let alertIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
                if (!checkState(data[doConditionIndex].state)) {
                    toast(<CustomToast message={`پر کردن ${filteredSelectValuesList[index][alertIndex]?.value} الزامی است`} state={'not'} />);
                }
            }
        }
    }


    const handleHidePage = (data) => {
        data.splice(0, data.length)
    }


    const handleInsertValue = (data, index) => {

        let calculateIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("CalculateValue"))
        let calculateValue = filteredSelectValuesList[index][calculateIndex].value



        let summaryIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("summaryValue"))
        let summaryTitle = handleConvertTitle(filteredSelectValuesList[index][summaryIndex].value)
        let summaryTargetState = data.findIndex(item => item.title === summaryTitle)


        console.log(summaryTargetState)


        let foundConditionKeys = []


        for (let key in data[summaryTargetState]?.state) {
            if (key.includes("main")) {
                foundConditionKeys.push(key);
            }
        }


        console.log(foundConditionKeys)


        for (let foundConditionKey of foundConditionKeys) {

            data[summaryTargetState].state[foundConditionKey] = calculateValue

        }

        return data;

    }



    const handleValidateSendData = (data) => {



        for (let index = 0; index < filteredSelectValuesList.length; index++) {

            const ifConditionTitle = handleConvertTitle(filteredSelectValuesList[index][0]?.value);
            const ifConditionIndex = data.findIndex(item => item.title === ifConditionTitle);

            if (ifConditionIndex === -1) {
                console.error('ifConditionTitle not found in data');
                return;
            }

            const checkStateFill = checkState(drawerData[ifConditionIndex].state);
            const stateCondition = filteredSelectValuesList[index][1].value;
            let doConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
            const doCondition = filteredSelectValuesList[index][doConditionIndex].value
            let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))



            if (stateCondition === "پر شده است") {
                if (checkStateFill) {

                    if (doCondition === "پنهان کردن") {

                        handleHideField(data, index)

                    } else if (doCondition === "پنهان کردن چندگانه") {

                        handleHideMultipleFields(data, index)

                    } else if (doCondition === "لازم بودن") {

                        handleRequired(data, index)

                    } else if (doCondition === "نیاز به چندین گزینه") {

                        handleNeedMultipleOptions(data, doConditionIndex, index)

                    } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                        if (doCondition === "غیر فعال کردن") {
                            setReadOnly(true)
                        } else {
                            setReadOnly(false)
                        }
                    } else if (doCondition === "کپی مقدار فیلد") {

                        handleCopyFieldValue(data, index)

                    } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                        handleCopyMultipleFieldValues(data, index)

                    } else if (doCondition === "پنهان کردن صفحه") {
                        handleHidePage(data)
                    } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                        setShowThankYouMessage(true)
                    } else if (doCondition === "محاسبه مقدار فیلد") {
                        handleInsertValue(data, index)
                    }

                } else if (!checkStateFill) {

                    if (doCondition === "نشان دادن") {

                        handleShowField(data, doFieldIndex, index)

                    } else if (doCondition === "نمایش چندگانه") {

                        handleShowMultiField(data, doFieldIndex, index)

                    } else if (doCondition === "مورد نیاز نیست") {

                        handleNotRequired(data, index)

                    } else if (doCondition === "نیاز به چندین گزینه نداشته باشید") {

                        handleNotMultiRequired(data, index)

                    } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                        if (doCondition === "فعال کردن") {
                            setReadOnly(true)
                        } else {
                            setReadOnly(false)
                        }
                    }
                }

            } else if (stateCondition === "خالی است") {


                if (!checkStateFill) {

                    if (doCondition === "پنهان کردن") {

                        handleHideField(data, index)

                    } else if (doCondition === "پنهان کردن چندگانه") {

                        handleHideMultipleFields(data, index)

                    } else if (doCondition === "لازم بودن") {

                        handleRequired(data, index)

                    } else if (doCondition === "نیاز به چندین گزینه") {

                        handleNeedMultipleOptions(data, doConditionIndex, index)

                    } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                        if (doCondition === "غیر فعال کردن") {
                            setReadOnly(true)
                        } else {
                            setReadOnly(false)
                        }
                    } else if (doCondition === "کپی مقدار فیلد") {

                        handleCopyFieldValue(data, index)

                    } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                        handleCopyMultipleFieldValues(data, index)

                    } else if (doCondition === "پنهان کردن صفحه") {
                        handleHidePage(data)
                    } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                        setShowThankYouMessage(true)
                    } else if (doCondition === "محاسبه مقدار فیلد") {
                        handleInsertValue(data, index)
                    }

                } else if (checkStateFill) {

                    if (doCondition === "نشان دادن") {

                        handleShowField(data, doFieldIndex, index)

                    } else if (doCondition === "نمایش چندگانه") {

                        handleShowMultiField(data, doFieldIndex, index)

                    } else if (doCondition === "مورد نیاز نیست") {

                        handleNotRequired(data, index)

                    } else if (doCondition === "نیاز به چندین گزینه نداشته باشید") {

                        handleNotMultiRequired(data, index)

                    } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                        if (doCondition === "فعال کردن") {
                            setReadOnly(true)
                        } else {
                            setReadOnly(false)
                        }
                    }
                }


            } else if (stateCondition === 'برابر است با') {

                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))
                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))
                const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;
                const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);


                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {

                    let targetState = data.findIndex(target => target.title === ifField)
                    let foundKeys = [];
                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }

                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }

                    if (foundKeys.length > 0) {

                        let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
                        let multiIndex = data.findIndex(item => item.title === "MultiChoice")
                        let multiState = data[multiIndex]?.state.mainSelectedTitles

                        let equal = false;

                        if (targetStatus === "مقدار") {
                            if (ifConditionTitle === "MultiChoice") {
                                for (let index = 0; index < multiState.length; index++) {
                                    for (let foundKey of foundKeys) {
                                        if (data[targetState].state[foundKey][index] === filteredSelectValuesList[index][valueConditionIndex].value) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }
                            } else if (ifConditionTitle === "Time") {
                                for (let foundKey of foundKeys) {
                                    let conditionArrayTime = filteredSelectValuesList[index][valueConditionIndex].value.split(":")
                                    let userArrayTime = data[targetState].state[foundKey].split(":")
                                    if (conditionArrayTime[0] === userArrayTime[0] && conditionArrayTime[1] === userArrayTime[1]) {
                                        equal = true;
                                        break;
                                    }
                                }

                            } else if (ifConditionTitle === "ScaleRating" || ifConditionTitle === "StarRating") {
                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey] == filteredSelectValuesList[index][valueConditionIndex].value) {
                                        equal = true;
                                        break;
                                    }
                                }

                            } else {
                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey] === filteredSelectValuesList[index][valueConditionIndex].value) {
                                        equal = true;
                                        break;
                                    }
                                }
                            }
                        } else if (targetStatus === "یک فیلد دیگر") {
                            if (ifConditionTitle === "MultiChoice") {
                                for (let index = 0; index < multiState.length; index++) {
                                    for (let foundKey of foundKeys) {
                                        for (let foundConditionKey of foundConditionKeys) {
                                            if (data[targetState].state[foundKey][index] === data[targetConditionStatus].state[foundConditionKey]) {
                                                equal = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else if (ifConditionTitle === "Time") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        let conditionValueTime = data[targetConditionStatus].state[foundConditionKey]
                                        let conditionArrayTime = conditionValueTime.split(":")
                                        let userArrayTime = data[targetState].state[foundKey].split(":")
                                        if (conditionArrayTime[0] === userArrayTime[0] && conditionArrayTime[1] === userArrayTime[1]) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }


                            } else if (ifConditionTitle === "ScaleRating" || ifConditionTitle === "StarRating") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        if (data[targetState].state[foundKey] == data[targetConditionStatus].state[foundConditionKey]) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }

                            } else {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        if (data[targetState].state[foundKey] === data[targetConditionStatus].state[foundConditionKey]) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        if (equal) {
                            if (doCondition === "پنهان کردن") {

                                handleHideField(data, index)

                            } else if (doCondition === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptions(data, doConditionEqualIndex, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {

                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }
                        } else if (!equal) {

                            if (doCondition === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doCondition === "نمایش چندگانه") {

                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doCondition === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }

                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "برابر نیست") {

                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))
                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;

                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {

                    let targetState = data.findIndex(target => target.title === ifField)
                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))
                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);
                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);

                    let foundKeys = [];
                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }

                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }


                    if (foundKeys.length > 0) {

                        let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
                        let multiIndex = data.findIndex(item => item.title === "MultiChoice")
                        let multiState = data[multiIndex]?.state.mainSelectedTitles

                        let equal = false;

                        if (targetStatus === "مقدار") {
                            if (ifConditionTitle === "MultiChoice") {
                                for (let sub = 0; sub < multiState.length; sub++) {
                                    for (let foundKey of foundKeys) {
                                        if (data[targetState].state[foundKey][sub] === filteredSelectValuesList[index][valueConditionIndex].value) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }
                            } else if (ifConditionTitle === "Time") {
                                for (let foundKey of foundKeys) {
                                    let conditionArrayTime = filteredSelectValuesList[index][valueConditionIndex].value.split(":")
                                    let userArrayTime = data[targetState].state[foundKey].split(":")

                                    if (conditionArrayTime[0] !== userArrayTime[0] && conditionArrayTime[1] !== userArrayTime[1]) {
                                        equal = false;
                                        break;
                                    } else if (conditionArrayTime[0] === userArrayTime[0] && conditionArrayTime[1] === userArrayTime[1]) {
                                        equal = true;
                                        break;
                                    }
                                }

                            } else {
                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey] === filteredSelectValuesList[index][valueConditionIndex].value) {
                                        equal = true;
                                        break;
                                    }
                                }
                            }
                        } else if (targetStatus === "یک فیلد دیگر") {
                            if (ifConditionTitle === "MultiChoice") {
                                for (let index = 0; index < multiState.length; index++) {
                                    for (let foundKey of foundKeys) {
                                        for (let foundConditionKey of foundConditionKeys) {
                                            if (data[targetState].state[foundKey][index] === data[targetConditionStatus].state[foundConditionKey]) {
                                                equal = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else if (ifConditionTitle === "Time") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        let conditionValueTime = data[targetConditionStatus].state[foundConditionKey]
                                        let conditionArrayTime = conditionValueTime.split(":")
                                        let userArrayTime = data[targetState].state[foundKey].split(":")

                                        if (conditionArrayTime[0] !== userArrayTime[0] && conditionArrayTime[1] !== userArrayTime[1]) {
                                            equal = false;
                                            break;
                                        } else if (conditionArrayTime[0] === userArrayTime[0] && conditionArrayTime[1] === userArrayTime[1]) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }

                            } else if (ifConditionTitle === "ScaleRating" || ifConditionTitle === "StarRating") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        if (data[targetState].state[foundKey] == data[targetConditionStatus].state[foundConditionKey]) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }

                            } else {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        if (data[targetState].state[foundKey] === data[targetConditionStatus].state[foundConditionKey]) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }
                            }

                        }



                        if (!equal) {
                            if (doConditionEqual === "پنهان کردن") {

                                handleHideField(data, index)

                            } else if (doConditionEqual === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {

                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }
                        } else if (equal) {
                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {

                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }
                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "شامل") {

                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))


                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;

                let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
                const valueCondition = filteredSelectValuesList[index][valueConditionIndex]?.value


                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {

                    let targetState = data.findIndex(target => target.title === ifField)
                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))
                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);
                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);

                    let foundKeys = [];

                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }

                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }

                    if (foundKeys.length > 0) {

                        let includes = false;
                        if (targetStatus === "مقدار") {
                            if (ifConditionTitle === "Table") {
                                for (let foundKey of foundKeys) {
                                    for (let a = 0; a < data[targetState].state[foundKey].length; a++) {
                                        if (data[targetState].state[foundKey][a].row?.includes(valueCondition) || data[targetState].state[foundKey][a].column?.includes(valueCondition)) {
                                            includes = true;
                                            break;
                                        }
                                    }
                                }

                            } else {
                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey].includes(valueCondition)) {
                                        includes = true;
                                        break;
                                    }
                                }
                            }

                        } else if (targetStatus === "یک فیلد دیگر") {
                            if (ifConditionTitle === "Table") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        for (let a = 0; a < data[targetState].state[foundKey].length; a++) {
                                            let tableCondition = data[targetConditionStatus].state[foundConditionKey]
                                            if (data[targetState].state[foundKey][a].row?.includes(tableCondition) || data[targetState].state[foundKey][a].column?.includes(tableCondition)) {
                                                includes = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        let dataCondition = data[targetConditionStatus].state[foundConditionKey]
                                        if (data[targetState].state[foundKey].includes(dataCondition)) {
                                            includes = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        if (includes) {

                            if (doConditionEqual === "پنهان کردن") {
                                handleHideField(data, index)
                            } else if (doConditionEqual === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {

                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }
                        } else if (!includes) {

                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {


                                handleShowMultiField(data, doFieldIndex, index)


                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }

                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "شامل نمی شود") {


                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))


                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;

                let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                const valueCondition = filteredSelectValuesList[index][valueConditionIndex]?.value

                let doFieldIndex = finalSelectValues.findIndex(item => item.name.includes("FieldCondition"))

                let targetState = data.findIndex(target => target.title === ifField)



                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {


                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))

                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);

                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);


                    let foundKeys = [];

                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }



                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }

                    if (foundKeys.length > 0) {

                        let includes = false;

                        if (targetStatus === "مقدار") {
                            if (ifConditionTitle === "Table") {
                                for (let foundKey of foundKeys) {
                                    for (let a = 0; a < data[targetState].state[foundKey].length; a++) {
                                        if (data[targetState].state[foundKey][a].row?.includes(valueCondition) || data[targetState].state[foundKey][a].column?.includes(valueCondition)) {
                                            includes = true;
                                            break;
                                        }
                                    }
                                }
                            } else {
                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey].includes(valueCondition)) {
                                        includes = true;
                                        break;
                                    }
                                }
                            }
                        } else if (targetStatus === "یک فیلد دیگر") {
                            if (ifConditionTitle === "Table") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        for (let a = 0; a < data[targetState].state[foundKey].length; a++) {
                                            let tableCondition = data[targetConditionStatus].state[foundConditionKey]
                                            if (data[targetState].state[foundKey][a].row?.includes(tableCondition) || data[targetState].state[foundKey][a].column?.includes(tableCondition)) {
                                                includes = true;
                                                break;
                                            }
                                        }
                                    }
                                }

                            } else {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {
                                        let dataCondition = data[targetConditionStatus].state[foundConditionKey]
                                        if (data[targetState].state[foundKey].includes(dataCondition)) {
                                            includes = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }




                        if (!includes) {
                            if (doConditionEqual === "پنهان کردن") {

                                handleHideField(data, index)

                            } else if (doConditionEqual === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {

                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }
                        } else if (includes) {

                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {

                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }
                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "شروع می شود با" || stateCondition === "به پایان می رسد با") {

                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))
                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;
                let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                const valueCondition = filteredSelectValuesList[index][valueConditionIndex]?.value


                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {
                    let targetState = data.findIndex(target => target.title === ifField)
                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))
                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);
                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);

                    let foundKeys = [];

                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }

                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }

                    if (foundKeys.length > 0) {

                        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
                        let startWithCondition = false;

                        if (targetStatus === "مقدار") {

                            for (let foundKey of foundKeys) {
                                if (stateCondition === "شروع می شود با") {
                                    startWithCondition = data[targetState].state[foundKey].startsWith(valueCondition);
                                } else if (stateCondition === "به پایان می رسد با") {
                                    startWithCondition = data[targetState].state[foundKey].endsWith(valueCondition);
                                }
                                if (startWithCondition) {
                                    break;
                                }
                            }


                        } else if (targetStatus === "یک فیلد دیگر") {

                            for (let foundKey of foundKeys) {
                                for (let foundConditionKey of foundConditionKeys) {
                                    let dataCondition = data[targetConditionStatus].state[foundConditionKey]
                                    if (stateCondition === "شروع می شود با") {
                                        startWithCondition = data[targetState].state[foundKey].startsWith(dataCondition);
                                    } else if (stateCondition === "به پایان می رسد با") {
                                        startWithCondition = data[targetState].state[foundKey].endsWith(dataCondition);
                                    }
                                    if (startWithCondition) {
                                        break;
                                    }
                                }
                            }

                        }


                        if (startWithCondition) {

                            if (doConditionEqual === "پنهان کردن") {
                                handleHideField(data, index)
                            } else if (doConditionEqual === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {


                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }
                        } else if (!startWithCondition) {

                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {

                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }

                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "شروع نمی شود با" || stateCondition === "به پایان نمی رسد با") {

                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = finalSelectValues.findIndex(item => item.name.includes("TargetStatus"))


                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;

                let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                const valueCondition = filteredSelectValuesList[index][valueConditionIndex].value


                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {

                    let targetState = data.findIndex(target => target.title === ifField)
                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))
                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);
                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);

                    let foundKeys = [];

                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }

                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }

                    if (foundKeys.length > 0) {

                        let doFieldIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")


                        let startWithCondition = false;

                        if (targetStatus === "مقدار") {
                            for (let foundKey of foundKeys) {
                                if (stateCondition === "شروع نمی شود با") {
                                    startWithCondition = data[targetState].state[foundKey].startsWith(valueCondition);
                                } else if (stateCondition === "به پایان نمی رسد با") {
                                    startWithCondition = data[targetState].state[foundKey].endsWith(valueCondition);
                                }
                                if (startWithCondition) {
                                    break;
                                }
                            }
                        } else if (targetStatus === "یک فیلد دیگر") {

                            for (let foundKey of foundKeys) {

                                for (let foundConditionKey of foundConditionKeys) {

                                    let dataCondition = data[targetConditionStatus].state[foundConditionKey]

                                    if (stateCondition === "شروع می شود با") {
                                        startWithCondition = data[targetState].state[foundKey].startsWith(dataCondition);
                                    } else if (stateCondition === "به پایان می رسد با") {
                                        startWithCondition = data[targetState].state[foundKey].endsWith(dataCondition);
                                    }
                                    if (startWithCondition) {
                                        break;
                                    }
                                }
                            }

                        }





                        if (!startWithCondition) {

                            if (doConditionEqual === "پنهان کردن") {

                                handleHideField(data, index)

                            } else if (doConditionEqual === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)


                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {


                                handleCopyFieldValue(data, index)


                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }

                        } else if (startWithCondition) {

                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {


                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }

                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "قبل" || stateCondition === "بعد" || stateCondition === "برابر با تاریخ است" || stateCondition === "برابر با تاریخ نیست" || stateCondition === "برابر با روز است" || stateCondition === "برابر با روز نیست") {

                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))
                let conditionRating;
                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;

                let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                const valueCondition = filteredSelectValuesList[index][valueConditionIndex]?.value
                const valueConditionMoment = moment(valueCondition, 'jYYYY/jMM/jDD');

                if (ifConditionTitle === "ScaleRating" || ifConditionTitle === "StarRating") {
                    conditionRating = parseInt(filteredSelectValuesList[index][valueConditionIndex]?.value)
                }

                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {

                    let targetState = data.findIndex(target => target.title === ifField)
                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))
                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);
                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);

                    let foundKeys = [];

                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }

                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }

                    if (foundKeys.length > 0) {

                        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))
                        let dateCondition = false;

                        if (targetStatus === "مقدار") {
                            if (ifConditionTitle === "DatePicker") {
                                for (let foundKey of foundKeys) {
                                    const dateStateMoment = moment(data[targetState].state[foundKey], 'jYYYY/jMM/jDD');
                                    // Extract day component from both dates
                                    const dateStateDay = dateStateMoment.jDate();
                                    const valueConditionDay = valueConditionMoment.jDate();

                                    if (stateCondition === "قبل") {
                                        dateCondition = dateStateMoment.isBefore(valueConditionMoment);
                                    } else if (stateCondition === "بعد") {
                                        dateCondition = dateStateMoment.isAfter(valueConditionMoment);
                                    } else if (stateCondition === "برابر با تاریخ است") {
                                        dateCondition = dateStateMoment.isSame(valueConditionMoment);
                                    } else if (stateCondition === "برابر با تاریخ نیست") {
                                        dateCondition = !dateStateMoment.isSame(valueConditionMoment);
                                    } else if (stateCondition === "برابر با روز است") {
                                        dateCondition = dateStateDay === valueConditionDay;
                                    } else if (stateCondition === "برابر با روز نیست") {
                                        dateCondition = dateStateDay !== valueConditionDay;
                                    }
                                    if (dateCondition) {
                                        break;
                                    }

                                }

                            } else if (ifConditionTitle === "Time") {

                                for (let foundKey of foundKeys) {

                                    let conditionArrayTime = filteredSelectValuesList[index][valueConditionIndex].value.split(":")
                                    let userArrayTime = data[targetState].state[foundKey].split(":")

                                    if (stateCondition === "قبل") {

                                        if (userArrayTime[0] < conditionArrayTime[0]) {

                                            dateCondition = true;

                                        } else if (userArrayTime[0] === conditionArrayTime[0]) {
                                            if (userArrayTime[1] < conditionArrayTime[1]) {
                                                dateCondition = true
                                            } else {
                                                dateCondition = false
                                            }
                                        } else {
                                            dateCondition = false
                                        }

                                    } else if (stateCondition === "بعد") {

                                        if (userArrayTime[0] > conditionArrayTime[0]) {

                                            dateCondition = true;

                                        } else if (userArrayTime[0] === conditionArrayTime[0]) {
                                            if (userArrayTime[1] > conditionArrayTime[1]) {
                                                dateCondition = true
                                            } else {
                                                dateCondition = false
                                            }
                                        } else {
                                            dateCondition = false
                                        }

                                    }

                                }


                            } else if (ifConditionTitle === "ScaleRating" || ifConditionTitle === "StarRating") {


                                for (let foundKey of foundKeys) {

                                    let userRating = data[targetState].state[foundKey]

                                    if (stateCondition === "قبل") {

                                        if (userRating < conditionRating) {

                                            dateCondition = true

                                        } else {

                                            dateCondition = false

                                        }

                                    } else if (stateCondition === "بعد") {

                                        if (userRating > conditionRating) {

                                            dateCondition = true

                                        } else {

                                            dateCondition = false

                                        }

                                    }

                                }
                            }
                        } else if (targetStatus === "یک فیلد دیگر") {

                            if (ifConditionTitle === "DatePicker") {


                                for (let foundKey of foundKeys) {

                                    for (let foundConditionKey of foundConditionKeys) {

                                        const dateStateMoment = moment(data[targetState].state[foundKey], 'jYYYY/jMM/jDD');
                                        const dateStateMomentCondition = moment(data[targetConditionStatus].state[foundConditionKey], 'jYYYY/jMM/jDD');
                                        // Extract day component from both dates
                                        const dateStateDay = dateStateMoment.jDate();
                                        const valueConditionDay = dateStateMomentCondition.jDate();


                                        if (stateCondition === "قبل") {

                                            dateCondition = dateStateMoment.isBefore(dateStateMomentCondition);

                                        } else if (stateCondition === "بعد") {

                                            dateCondition = dateStateMoment.isAfter(dateStateMomentCondition);

                                        } else if (stateCondition === "برابر با تاریخ است") {

                                            dateCondition = dateStateMoment.isSame(dateStateMomentCondition);

                                        } else if (stateCondition === "برابر با تاریخ نیست") {

                                            dateCondition = !dateStateMoment.isSame(dateStateMomentCondition);

                                        } else if (stateCondition === "برابر با روز است") {

                                            dateCondition = dateStateDay === valueConditionDay;

                                        } else if (stateCondition === "برابر با روز نیست") {

                                            dateCondition = dateStateDay !== valueConditionDay;

                                        }
                                        if (dateCondition) {
                                            break;
                                        }
                                    }
                                }

                            } else if (ifConditionTitle === "Time") {

                                for (let foundKey of foundKeys) {

                                    for (let foundConditionKey of foundConditionKeys) {


                                        let conditionValueTime = data[targetConditionStatus].state[foundConditionKey]

                                        let conditionArrayTime = conditionValueTime.split(":")

                                        let userArrayTime = data[targetState].state[foundKey].split(":")

                                        if (stateCondition === "قبل") {

                                            if (userArrayTime[0] < conditionArrayTime[0]) {

                                                dateCondition = true;

                                            } else if (userArrayTime[0] === conditionArrayTime[0]) {
                                                if (userArrayTime[1] < conditionArrayTime[1]) {
                                                    dateCondition = true
                                                } else {
                                                    dateCondition = false
                                                }
                                            } else {
                                                dateCondition = false
                                            }

                                        } else if (stateCondition === "بعد") {

                                            if (userArrayTime[0] > conditionArrayTime[0]) {

                                                dateCondition = true;

                                            } else if (userArrayTime[0] === conditionArrayTime[0]) {
                                                if (userArrayTime[1] > conditionArrayTime[1]) {
                                                    dateCondition = true
                                                } else {
                                                    dateCondition = false
                                                }
                                            } else {
                                                dateCondition = false
                                            }

                                        }
                                    }

                                }


                            } else if (ifConditionTitle === "ScaleRating" || ifConditionTitle === "StarRating") {


                                for (let foundKey of foundKeys) {

                                    for (let foundConditionKey of foundConditionKeys) {

                                        let userRating = data[targetState].state[foundKey]
                                        conditionRating = parseInt(data[targetConditionStatus].state[foundConditionKey])


                                        if (stateCondition === "قبل") {

                                            if (userRating < conditionRating) {

                                                dateCondition = true

                                            } else {

                                                dateCondition = false

                                            }

                                        } else if (stateCondition === "بعد") {

                                            if (userRating > conditionRating) {

                                                dateCondition = true

                                            } else {

                                                dateCondition = false

                                            }

                                        }

                                    }

                                }
                            }

                        }



                        if (dateCondition) {

                            if (doConditionEqual === "پنهان کردن") {

                                handleHideField(data, index)

                            } else if (doConditionEqual === "پنهان کردن چندگانه") {

                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {

                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {


                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }
                        } else if (!dateCondition) {

                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {

                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }
                        }
                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }
            } else if (stateCondition === "کمتر از" || stateCondition === "بزرگتر از") {


                let doConditionEqualIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("DoCondition"))
                let targetStatusIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("TargetStatus"))


                const ifField = handleConvertTitle(filteredSelectValuesList[index][0].value);
                const doConditionEqual = filteredSelectValuesList[index][doConditionEqualIndex].value;
                const targetStatus = filteredSelectValuesList[index][targetStatusIndex]?.value;

                if (targetStatus === "مقدار" || targetStatus === "یک فیلد دیگر") {

                    let targetState = data.findIndex(target => target.title === ifField)

                    const ifFieldConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("ifFieldCondition"))

                    const ifFieldCondition = handleConvertTitle(filteredSelectValuesList[index][ifFieldConditionIndex]?.value);

                    const targetConditionStatus = data.findIndex(target => target.title === ifFieldCondition);

                    let foundKeys = [];

                    let foundConditionKeys = []

                    for (let key in data[targetState].state) {
                        if (key.includes("main")) {
                            foundKeys.push(key);
                        }
                    }



                    for (let key in data[targetConditionStatus]?.state) {
                        if (key.includes("main")) {
                            foundConditionKeys.push(key);
                        }
                    }


                    if (foundKeys.length > 0) {

                        let valueConditionIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("valueCondition"))
                        let doFieldIndex = filteredSelectValuesList[index].findIndex(item => item.name.includes("FieldCondition"))

                        let equal = false;

                        if (targetStatus === "مقدار") {

                            if (stateCondition === "کمتر از") {
                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey] < filteredSelectValuesList[index][valueConditionIndex].value) {
                                        equal = true;
                                        break;
                                    }
                                }
                            } else if (stateCondition === "بزرگتر از") {

                                for (let foundKey of foundKeys) {
                                    if (data[targetState].state[foundKey] > filteredSelectValuesList[index][valueConditionIndex].value) {
                                        equal = true;
                                        break;
                                    }
                                }

                            }

                        } else if (targetStatus === "یک فیلد دیگر") {

                            if (stateCondition === "کمتر از") {
                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {

                                        let numTarget = parseInt(data[targetConditionStatus].state[foundConditionKey])

                                        if (data[targetState].state[foundKey] < numTarget) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }
                            } else if (stateCondition === "بزرگتر از") {

                                for (let foundKey of foundKeys) {
                                    for (let foundConditionKey of foundConditionKeys) {

                                        let numTarget = parseInt(data[targetConditionStatus].state[foundConditionKey])

                                        if (data[targetState].state[foundKey] > numTarget) {
                                            equal = true;
                                            break;
                                        }
                                    }
                                }

                            }

                        }


                        if (equal) {

                            if (doConditionEqual === "پنهان کردن") {

                                handleHideField(data, index)

                            } else if (doConditionEqual === "پنهان کردن چندگانه") {


                                handleHideMultipleFields(data, index)

                            } else if (doCondition === "لازم بودن") {

                                handleRequired(data, index)

                            } else if (doCondition === "نیاز به چندین گزینه") {

                                handleNeedMultipleOptionsNotEqual(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "غیر فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            } else if (doCondition === "کپی مقدار فیلد") {

                                handleCopyFieldValue(data, index)

                            } else if (doCondition === "چندین مقدار فیلد را کپی کنید") {

                                handleCopyMultipleFieldValues(data, index)

                            } else if (doCondition === "پنهان کردن صفحه") {
                                handleHidePage(data)
                            } else if (doCondition === "نمایش پیام سفارشی" || doCondition === "هدایت به URL") {
                                setShowThankYouMessage(true)
                            }

                        } else if (!equal) {

                            if (doConditionEqual === "نشان دادن") {

                                handleShowField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "نمایش چندگانه") {

                                handleShowMultiField(data, doFieldIndex, index)

                            } else if (doConditionEqual === "مورد نیاز نیست") {

                                handleNotRequired(data, index)

                            } else if (doConditionEqual === "نیاز به چندین گزینه نداشته باشید") {

                                handleNotMultiRequired(data, index)

                            } else if (doCondition === "غیر فعال کردن" || doCondition === "فعال کردن") {
                                if (doCondition === "فعال کردن") {
                                    setReadOnly(true)
                                } else {
                                    setReadOnly(false)
                                }
                            }

                        }


                    } else {
                        console.log("No key containing 'main' found in data.state.");
                    }
                }


            }

        }




    };

    const handleUpdateForm = (formId, data , status) => {
        // Replace `baseUrl` with your actual base URL
        const baseUrl = 'https://formmaker-api.altynlogistics.com/api';

        const token = localStorage.getItem('authToken');


        return axios
            .patch(`${baseUrl}/form/${formId}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Form updated successfully:', response.data);
                handleUpdateStatusForm(formId , status)
                 return response.data;
            })
            .catch((error) => {
                console.error('Error updating the form:', error.response || error.message);
                throw error; // Reject with the error
            });
    };



    const sendDataToServer = (status) => {

        if (drawerData.length === 0) {
            console.error("Components array is empty. Cannot send empty data.");
            toast(<CustomToast message="لطفا ابتدا فرم را پر کنید" state={'warning'} />);
            return;
        }


        handleFilterTitle(drawerData)


        if (currentPage === '/Publish' || currentPage === '/build') {

            sendToServerData = [...drawerData]

            if (filteredSelectValuesList.length > 0) {
                handleValidateSendData(sendToServerData)
            }

            let content = {
                title: title,
                forms:JSON.stringify({sendToServerData})
            }


            let finalData = {
                content:content,
                maxUses:'',
                start_date:'',
                end_date:''
            }

            setShowMessage(true)

            if(isValidCaptcha === 'valid'){

                const updateFormId = localStorage.getItem('createFormId')

                handleUpdateForm(updateFormId, finalData , status)
                    .then((updatedForm) => {
                        console.log('Updated Form Data:', updatedForm);
                        if(currentPage === '/build'){
                            toast(<CustomToast message="فرم شما با موفقیت ذخیره شد" state={'success'} />);
                            navigate('/Publish')
                        }else{
                            toast(<CustomToast message="فرم شما با موفقیت منتشر شد" state={'success'} />);
                            navigate('/Main')
                        }
                    })
                    .catch((error) => {
                        if(currentPage === '/build'){
                            toast(<CustomToast message="فرم شما ذخیره نشد! لطفا چند لحظه دیگر مجددا تلاش کنید" state={'not'} />);
                        }else{
                            toast(<CustomToast message="فرم شما منتشر نشد! لطفا چند لحظه دیگر مجددا تلاش کنید" state={'not'} />);
                        }
                        console.error('Failed to update form:', error);
                    });

            }else{
                toast(<CustomToast message="کپچای نامعتبر است. لطفا دوباره تلاش کنید." state={'not'} />);
            }
            
        }

    };

    const handleSaveDraft = () => {

        sendToServerData = [...drawerData]

        if (filteredSelectValuesList.length > 0) {

            handleValidateSendData(sendToServerData)
        }

        if (drawerData.length > 0) {


            if (editMode) {

                setSelectedItem(
                    selectedItem.map(
                        item => {
                            item.title = title;
                            item.state = sendToServerData
                        }
                    )
                )

            }



            let finalSaveDraft = editMode ?

                selectedItem
                :
                [
                    {
                        id: uuidv4(),
                        title: title,
                        state: sendToServerData,
                        checked: false,
                        favorite: false,
                        draft: true
                    }
                ]

            saveDraft(finalSaveDraft);
            console.log("Draft saved:", draftData);
        }


    };


    const backToMainPage = () => {
        navigate('/Main')
        handleSaveDraft()
    }




    useEffect(() => {
        refs.current = refs.current.slice(0, drawerData.length);
    }, [drawerData]);



    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);




    useEffect(() => {
        const storedData = localStorage.getItem('formDrawerData');
        if (storedData) {
            handleDrawerData(JSON.parse(storedData));
            handleFilterTitle(JSON.parse(storedData))
        }
    }, []);


    useEffect(() => {
        return () => {
            const storedData = localStorage.getItem('formDrawerData');
            if (storedData) {
                handleDrawerData(JSON.parse(storedData));
                handleFilterTitle(JSON.parse(storedData))
            }
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('formDrawerData', JSON.stringify(drawerData));
    }, [drawerData]);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (currentPage === '/Publish' && drawerData.length > 0) {
                handleSaveDraft();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [currentPage, drawerData]);




    return (
        <Grid>
            {showMessage && <ThankYouModal />}
            <Grid>
                <Navbar active={active} />
                <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} position={'fixed'} left={'5%'} bottom={'5%'} zIndex={100}>
                    <Grid ref={scrollToTop} onClick={handleScrollToTop} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'40px'} height={'40px'} borderRadius={'8px'} bgcolor={'transparent'} border={'1px solid black'}>
                        <ArrowUpwardIcon fontSize='large' />
                    </Grid>
                    <Typography variant='subtitle1' color={'black'}>Go to top</Typography>
                </Grid>
                <Grid width={{ xs: '90%', xxs: '90%' }} maxWidth={'752px'} bgcolor={currentPage === '/build/CardForm' || currentPage === '/Publish/CardForm' ? 'transparent' : '#fff'} mx={'auto'} borderRadius={'3px'}
                    boxShadow={currentPage === '/build/CardForm' || currentPage === '/Publish/CardForm' ? '0px' : '0 4px 4px rgba(87,100,126,.21)'} py={currentPage === '/build/CardForm' || currentPage === '/Publish/CardForm' ? '20px' : '40px'} mt={'16px'} px={'16px'} mb={'40px'} position={'relative'}>
                    <DndProvider backend={HTML5Backend}>
                        {drawerData.length === 0 ?
                            <Grid height={'126px'} width={'85%'} mx={'auto'} borderRadius={'10px'} bgcolor={'#fff'}
                                border={'2px dashed #c8ceed'} display={'flex'} alignItems={'center'}
                                justifyContent={'center'}>
                                <Grid width={'20px'} height={'20px'}>
                                    <img src={'/assets/images/pointer.svg'} alt={''} />
                                </Grid>
                                <Typography variant={Typo} color={'#636a96'}>اولین سوال خود را از منوی سمت راست انتخاب
                                    کنید.</Typography>
                            </Grid>
                            :
                            <Grid style={{ cursor: 'move' }}>
                                <Droppable>
                                    {drawerData.map((componentData, index) => {
                                        const { id, title, states } = componentData;
                                        const Component = COMPONENT_MAP[title];
                                        if (!refs.current[index]) {
                                            refs.current[index] = React.createRef();
                                        }
                                        return (
                                            <DraggableComponent
                                                key={index}
                                                index={index}
                                                id={index + 1}
                                                moveComponent={moveComponent}
                                                draggedItem={draggedItem}
                                                setDraggedItem={setDraggedItem}
                                            >
                                                <Grid style={{ position: 'relative' }} ref={refs.current[index]}>
                                                    {
                                                        currentPage === '/build' || currentPage === '/Publish' ?
                                                            <Component
                                                                id={id}
                                                                states={states}
                                                                onChangeState={() => { handleChangeState(id, states) }}
                                                            />
                                                            :
                                                            <Grid boxShadow={'-1px 15px 33px 4px rgba(0, 0, 0, 0.15)'}>
                                                                <Component
                                                                    id={id}
                                                                    states={states}
                                                                    onChangeState={() => { handleChangeState(id, states) }}
                                                                />
                                                            </Grid>
                                                    }
                                                    {
                                                        !isReadOnly &&
                                                        <Grid className="delete-icon" display={'flex'} alignItems={'center'} mr={{ xs: "0px", md: '10px' }}
                                                            justifyContent={'center'} gap={'5px'} width={hovered ? '80px' : '40px'}
                                                            height={'40px'} borderRadius={hovered ? '20px' : '50%'}
                                                            position={'absolute'} top={{ xs: "105%", md: '35%' }}
                                                            right={{ xs: '41%', md: '100%' }}
                                                            zIndex={1} bgcolor={'rgba(220,38,38,1)'}
                                                            onMouseEnter={() => setHovered(true)}
                                                            onMouseLeave={() => setHovered(false)}
                                                            onClick={() => deleteComponent(componentData.id)}
                                                            style={{ cursor: 'pointer', transition: 'right 0.3s ease' }}>
                                                            <DeleteIcon fontSize={'large'} style={{ color: 'white' }} />
                                                            <Typography pt={'4px'} color={'white'} variant={'h6'}
                                                                display={hovered ? 'block' : 'none'}>حذف</Typography>
                                                        </Grid>
                                                    }

                                                </Grid>
                                            </DraggableComponent>
                                        );
                                    })}
                                </Droppable>

                            </Grid>
                        }
                    </DndProvider>
                </Grid>
                <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'16px'} width={'100%'}>
                    <Grid display={currentPage === '/build' || currentPage === "/build/CardForm" ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} mb={'36px'} mt={currentPage === "/build/CardForm" ? '0px' : '36px'} width={'100%'}>
                        <Button variant='contained' color='success' sx={{ width: { xs: '50%', xxs: '35%', sm: '30%' }, p: '15px 0px' }} onClick={()=>{sendDataToServer('unpublished')}}>
                            <Typography variant='h4' fontWeight={700} pt={'5px'}>ذخیره</Typography>
                        </Button>
                    </Grid>
                    <Grid display={currentPage === '/Publish' || currentPage === '/Publish/CardForm' ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} mb={'36px'} mt={currentPage === '/Publish/CardForm' ? '0px' : '36px'} width={'100%'}>
                        <Button variant='contained' color='success' sx={{ width: { xs: '50%', xxs: '35%', sm: '30%' }, p: '15px 0px' }} onClick={()=>{sendDataToServer('published')}}>
                            <Typography variant='h4' fontWeight={700} pt={'5px'}>انتشار</Typography>
                        </Button>
                    </Grid>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'48px'} width={'100%'}>
                        <Button variant='contained' color='primary' sx={{ width: { xs: '50%', xxs: '35%', sm: '30%' }, p: '15px 0px' }} onClick={backToMainPage}>
                            <Typography variant='h4' fontWeight={700} pt={'5px'}>بازگشت به صفحه اصلی</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>


    )
}

export default FormItem;