import { Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTitleContext } from '../../Context/TitleContext';
import CustomToast from '../CustomToast/CustomToast';

const Header = () => {

    const { title } = useTitleContext();

    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleLogOut = () => {

        const apiUrl = 'https://formmaker-api.altynlogistics.com/api/logout';
        const token = localStorage.getItem('authToken');

        if (token) {
            setLoading(true);

            axios
                .post(apiUrl, {}, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setLoading(false);

                    if (response.data.status) {
                        localStorage.removeItem('authToken');
                        navigate('/');
                        toast(<CustomToast message="خروج با موفقیت انجام شد" state="success" />);
                    } else {
                        response.data.errors.length > 0 &&
                            toast(<CustomToast message={response.data.errors[0]} state="error" />);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    toast(<CustomToast message="An error occurred. Please try again later." state="error" />);
                });
        } else {
            toast(<CustomToast message="No token found. Please log in first." state="error" />);
        }
    };


    const handleNavigateMainPage = ()=>{
        navigate('/Main')
    }


    return (
        <>
            <Grid display={loading ? 'flex' : 'none'} alignItems="center" justifyContent="center" height="100vh" width={'100vw'} bgcolor={'darkgray'} position={'fixed'} top={0} right={0} style={{ opacity: '0.8', zIndex: 100000 }}>
                <Circles color="#FF6600" height={80} width={80} />
            </Grid>
            <Grid width={'100%'} height={'70px'} bgcolor={'#2b3245'} p={{ xs: '8px', md: '16px' }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid display={'flex'} alignItems={'center'} gap={{ xs: '4px', md: '8px' }}>
                    <Grid width={'60px'} height={'60px'} bgcolor={'transparent'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'4px'}>
                        <img src='/assets/images/AltynLogo.svg' alt='' style={{ width: '70%', height: '70%' }} />
                    </Grid>
                    <Typography variant='h4' color={'white'} fontWeight={700}>گروه آلتن</Typography>
                </Grid>
                <Grid>
                    <Typography variant='h2' color={'white'} fontWeight={700}>{title}</Typography>
                </Grid>
                <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                    <Button onClick={handleNavigateMainPage} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '42px', px: '16px', bgcolor: '#3674B5', color: 'white', fontSize: '16px', borderRadius: '4px', '&:hover': { bgcolor: '#074799' } }}>
                        بازگشت
                    </Button>
                    <Button onClick={handleLogOut} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '42px', px: '16px', bgcolor: '#ff6100', color: 'white', fontSize: '16px', borderRadius: '4px', '&:hover': { bgcolor: '#DC5F00' } }}>
                        خروج
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default Header