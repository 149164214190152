import React, {createContext, useContext, useState} from 'react';
import useLocalStorage from 'use-local-storage';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{ loading, setLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    return useContext(LoadingContext);
};

