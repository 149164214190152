import React, { createContext, useContext, useState } from 'react';

const ActiveFilterContext = createContext();

export const ActiveFilterProvider = ({ children }) => {
    const [activeFilter, setActiveFilter] = useState(103);

    return (
        <ActiveFilterContext.Provider value={{ activeFilter, setActiveFilter }}>
            {children}
        </ActiveFilterContext.Provider>
    );
};

export const useActiveFilter = () => useContext(ActiveFilterContext);
