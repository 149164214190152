import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Switch from 'react-switch'
import ViewWeekIcon from '@mui/icons-material/ViewWeek';


const Encrypt = () => {

    const [checked, setChecked] = useState(true);


    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };


    return (
        <>
            <Grid>
                <Typography variant='h5' color={'#596066'} fontWeight={700} lineHeight={'1.618em'}>رمزگذاری داده های فرم</Typography>
                <Typography variant='h6' color={'#596066'} fontWeight={500} mb={'10px'}>پاسخ های فرم خود را برای ذخیره ایمن داده های حساس رمزگذاری کنید.</Typography>
            </Grid>
            <Grid>
                <label htmlFor="small-radius-switch">
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        handleDiameter={28}
                        offColor="#f98e03"
                        onColor="#ddd"
                        // offHandleColor="#0ff"
                        // onHandleColor="#08f"
                        height={40}
                        width={70}
                        borderRadius={6}
                        // activeBoxShadow="0px 0px 1px 2px #fffc35"
                        uncheckedIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "white",
                                    paddingRight: 2,
                                    paddingTop: '4px'

                                }}
                            >
                                Yes
                            </div>
                        }
                        checkedIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "white",
                                    paddingRight: 2,
                                    paddingTop: '4px'

                                }}
                            >
                                No
                            </div>
                        }
                        uncheckedHandleIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 20,
                                    backgroundColor: '#fff',
                                    borderRadius: '4px'

                                }}
                            >
                                <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                            </div>
                        }
                        checkedHandleIcon={
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 20,
                                    backgroundColor: '#fff',
                                    borderRadius: '4px'
                                }}
                            >
                                <ViewWeekIcon fontSize='large' style={{ color: '#eee' }} />
                            </div>
                        }
                        className="react-switch"
                        id="small-radius-switch"
                    />
                </label>
            </Grid>
        </>
    )
}

export default Encrypt