import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment-jalaali';
import { convertToEnglishNumbers } from '../../helpers/convertToEnglishNumbers';

const DateRangeContext = createContext();

export const DateRangeProvider = ({ children }) => {
    
    const [dateRange, setDateRange] = useState({
        fromDate: null,
        toDate: null,
    });

    const [hasChanged, setHasChanged] = useState(false);

    const handleDateChange = (key, value) => {
        setHasChanged(true);
        if (value) {
            setDateRange((prev) => ({
                ...prev,
                [key]: convertToEnglishNumbers(value.format("YYYY/MM/DD")),
            }));
        }
    };

    useEffect(() => {
        if (!hasChanged) {
            setDateRange({ fromDate: null, toDate: null });
        }
    }, [hasChanged]);

    return (
        <DateRangeContext.Provider value={{ dateRange, handleDateChange }}>
            {children}
        </DateRangeContext.Provider>
    );
};

export const useDateRange = () => {
    return useContext(DateRangeContext);
};
