import React from 'react';
import { Grid, Typography } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import { useDateRange } from '../../Context/FormLimited/DateRangeContext';

const DateDisableForm = () => {

    const { dateRange, handleDateChange } = useDateRange();
    
    return (
        <Grid width={'100%'} mt={'24px'}>
            <Typography variant={'h5'} color={'rgba(10,21,81,1)'} fontWeight={700} mb={'4px'}>
                تاریخ انقضای فرم
            </Typography>
            <Typography variant={'h6'} color={'rgba(108,115,168,1)'} fontWeight={500} mb={'12px'}>
                فرم در این بازه‌ی تاریخ فعال خواهد بود
            </Typography>
            <Grid className={'date'} display={'flex'} gap={'16px'} flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }} width={'100%'}>
                <Grid display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={{ xs: '100%', md: '50%' }}>
                    <Typography variant={'h5'} color={'rgba(10,21,81,1)'} fontWeight={700} mb={'8px'}>
                        از تاریخ
                    </Typography>
                    <Grid className="custom-input-wrapper" width={'100%'}>
                        <Grid width={'100%'}>
                            <DatePicker
                                value={dateRange.fromDate}
                                onChange={(date) => handleDateChange('fromDate', date)}
                                calendar={persian}
                                locale={persian_fa}
                                format="YYYY/MM/DD"
                                digits={false}
                                style={{
                                    border:'none',
                                    outline: 'none',
                                    boxShadow:'none',
                                    width: '100%',
                                    padding: '0px 24px 4px 0px',
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                }}
                            />
                        </Grid>
                        <CalendarMonthIcon className={'iconDatePicker'} />
                    </Grid>
                </Grid>
                <Grid display={'flex'} flexDirection={'column'} alignItems={'flex-start'} width={{ xs: '100%', md: '50%' }}>
                    <Typography variant={'h5'} color={'rgba(10,21,81,1)'} fontWeight={700} mb={'8px'}>
                        تا تاریخ
                    </Typography>
                    <Grid className="custom-input-wrapper" width={'100%'}>
                        <Grid width={'100%'}>
                            <DatePicker
                                value={dateRange.toDate}
                                onChange={(date) => handleDateChange('toDate', date)}
                                calendar={persian}
                                locale={persian_fa}
                                format="YYYY/MM/DD"
                                digits={false}
                                style={{
                                    border:'none',
                                    outline: 'none',
                                    boxShadow:'none',
                                    width: '100%',
                                    padding: '0px 24px 4px 0px',
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                }}
                            />
                        </Grid>
                        <CalendarMonthIcon className={'iconDatePicker'} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DateDisableForm;
