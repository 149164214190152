import React from 'react'
import ResponseFormListHeader from '../Component/ResponseForm/ResponseFormListHeader'
import ResponseFormList from "../Component/ResponseForm/ResponseFormList";

const ResponseFormPage = () => {
  return (
    <>
        <ResponseFormListHeader />
        <ResponseFormList />
    </>
  )
}

export default ResponseFormPage
