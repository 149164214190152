import { Grid } from '@mui/material'
import React from 'react'
import DateDisableForm from './DateDisableForm'
import SubmissionLimit from './SubmissionLimit'

const TimeAndDateDisableForm = () => {
  return (
    <Grid display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} mt={'24px'}>
        <DateDisableForm />
        <SubmissionLimit />
    </Grid>
  )
}

export default TimeAndDateDisableForm