import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const StatusFormContext = createContext();

export const StatusFormProvider = ({ children }) => {

    // Using local storage to persist the status form data
    const [statusForms, setStatusForms] = useLocalStorage('StatusForms', 'all');

    // Function to update the status forms
    const saveStatusForm = (data) => {
        console.log(data);
        setStatusForms(data);
    };

    return (
        <StatusFormContext.Provider value={{ statusForms, setStatusForms, saveStatusForm }}>
            {children}
        </StatusFormContext.Provider>
    );
};

// Hook to use the context
export const useStatusForm = () => {
    return useContext(StatusFormContext);
};
