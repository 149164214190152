import React, { createContext, useContext, useState } from 'react';
import useLocalStorage from 'use-local-storage';

const ListResponseContext = createContext();

export const ListResponseProvider = ({ children }) => {

    const [listResponse, setListResponse] = useState(null);

    return (
        <ListResponseContext.Provider value={{ listResponse, setListResponse }}>
            {children}
        </ListResponseContext.Provider>
    );
};

export const useListResponse = () => {
    return useContext(ListResponseContext);
};

