import React, { createContext, useContext, useState } from 'react';

const DetailsResponseContext = createContext();

export const DetailsResponseProvider = ({ children }) => {

    const [detailsResponse, setDetailsResponse] = useState(null);

    return (
        <DetailsResponseContext.Provider value={{ detailsResponse, setDetailsResponse }}>
            {children}
        </DetailsResponseContext.Provider>
    );
};

export const useDetailsResponse = () => {
    return useContext(DetailsResponseContext);
};

