import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const PageCountContext = createContext();

export const PageCountProvider = ({ children }) => {

    const [pageCount, setPageCount] = useLocalStorage('pageCount', 1);


    return (
        <PageCountContext.Provider value={{ pageCount, setPageCount }}>
            {children}
        </PageCountContext.Provider>
    );
};

export const usePageCount = () => {
    return useContext(PageCountContext);
};

