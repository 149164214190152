import { Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import LoginSelect from '../LoginSelect/LoginSelect';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Circles } from 'react-loader-spinner';
import CustomToast from "../CustomToast/CustomToast";
import { useCountry } from "../../Context/CountryContext";


const ForgetPasswordComponent = () => {


    const { countryId } = useCountry();
    let navigate = useNavigate()


    const [phoneError, setPhoneError] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [country, setCountry] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [countryError, setCountryError] = useState('');
    const [reset, setReset] = useState(false);
    const [passwordValue, setPasswordValue] = useState('')
    const [tokenValue, setTokenValue] = useState('')



    const handleChangePhoneValue = (e) => {
        setPhoneValue(e.target.value)
    }

    const handleChangePasswordValue = (e) => {
        setPasswordValue(e.target.value)
    }


    const handleChangeTokenValue = (e) => {
        setTokenValue(e.target.value)
    }


    const handleForgetPassword = () => {

        const data = {
            phone: Number(phoneValue),
            countryId: countryId,
        };

        const apiUrl = 'https://formmaker-api.altynlogistics.com/api/password/forgot'

        setLoading(true);

        axios
            .post(apiUrl, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setLoading(false); // End the loading state
                if (response.data.status) {
                    setReset(true)
                } else {
                    response.data.errors.length > 0 && toast(<CustomToast message={response.data.errors[0]} state={'error'} />)
                    setPhoneError(response.data.validations.phone && response.data.validations.phone[0])
                }
            })
            .catch((err) => {
                setLoading(false); // End the loading state on error
                console.error('Error:', err);
                // alert('An error occurred. Please try again later.');
            });
    };



    const handleResetPassword = () => {

        const data = {
            phone: Number(phoneValue),
            countryId: countryId,
            password: passwordValue,
            token: Number(tokenValue)
        };

        const apiUrl = 'https://formmaker-api.altynlogistics.com/api/password/reset'

        setLoading(true); // Start the loading state


        axios
            .post(apiUrl, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                setLoading(false); // End the loading state

                if (response.data.status) {

                    navigate('/');

                } else {
                    response.data.errors.length > 0 && toast(<CustomToast message={response.data.errors[0]} state={'error'} />)
                    setPhoneError(response.data.validations.phone && response.data.validations.phone[0])
                    // isSignUp ? toast(<CustomToast message={'ثبت نام انجام نشد لطفا دوباره امتحان کنید.'} state={'error'} />) : toast(<CustomToast message={'ورود ناموفق بود. لطفا اعتبار خود را بررسی کنید.'} state={'error'} />)
                }
            })
            .catch((err) => {
                setLoading(false); // End the loading state on error
                console.error('Error:', err);
                // alert('An error occurred. Please try again later.');
            });
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://formmaker-api.altynlogistics.com/api/countries');
                setCountry(response.data.output);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);





    return (
        <>
            <Grid display={loading ? 'flex' : 'none'} alignItems="center" justifyContent="center" height="100vh" width={'100vw'} bgcolor={'darkgray'} position={'fixed'} top={0} right={0} style={{ opacity: '0.8', zIndex: 1000 }} >
                <Circles color="#FF6600" height={80} width={80} /> {/* Show loading spinner */}
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'36px'}>
                <Grid width={'100px'} height={'100px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img src='/assets/images/AltynLogo.svg' alt='' style={{ width: '100%', height: '100%' }} />
                </Grid>
            </Grid>
            <Grid width={'90%'} maxWidth={'500px'} display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>
                {
                    reset ?
                        <Grid width={'100%'} mx={'auto'} mt={'30px'} bgcolor={'white'} p={{ xs: '30px 15px', sm: '30px' }} borderRadius={'8px'} mb={'48px'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'50px'}>
                                <Typography variant='h2' color={'#FDA058'} fontWeight={700}>{'تغییر رمز عبور'}</Typography>
                            </Grid>
                            <Grid>
                                <Grid mb={'24px'}>
                                    <form style={{ display: 'flex', flexDirection: 'column', marginBottom: '4px' }}>
                                        <label for="Phone" style={{ marginBottom: '4px' }}>رمز عبور جدید</label>
                                        <input
                                            type="password"
                                            id="Phone"
                                            name="Phone"
                                            value={passwordValue}
                                            onChange={handleChangePasswordValue}
                                            style={{
                                                padding: '12px',
                                                color: '#3C3D37',
                                                borderRadius: '4px',
                                                outline: 'none',
                                                fontSize: '16px'
                                            }}
                                        />
                                    </form>
                                    {phoneError !== null && <Typography variant='subtitle1' color={'#B8001F'} fontWeight={700}>{phoneError}</Typography>}
                                </Grid>
                                <Grid mb={'48px'}>
                                    <form style={{ display: 'flex', flexDirection: 'column', marginBottom: '4px' }}>
                                        <label for="Phone" style={{ marginBottom: '4px' }}>کد تایید</label>
                                        <input
                                            type="number"
                                            id="Phone"
                                            name="Phone"
                                            value={tokenValue}
                                            onChange={handleChangeTokenValue}
                                            style={{
                                                padding: '12px',
                                                color: '#3C3D37',
                                                borderRadius: '4px',
                                                outline: 'none',
                                                fontSize: '16px'
                                            }}
                                        />
                                    </form>
                                    {phoneError !== null && <Typography variant='subtitle1' color={'#B8001F'} fontWeight={700}>{phoneError}</Typography>}
                                </Grid>
                                <Button onClick={handleResetPassword} sx={{ bgcolor: '#FDA058', borderRadius: '12px', width: '100%', p: '12px 0px', color: 'white', fontSize: '16px', fontWeight: 700, boxShadow: 5, mb: '36px', '&:hover': { bgcolor: '#FF8343' } }}>
                                    {'تغییر رمز عبور'}
                                </Button>
                            </Grid>
                            <Link to={'/'}>
                                <Grid position={'fixed'} top={{ xs: '20px', sm: '40px' }} right={{ xs: '20px', sm: '100px' }} bgcolor={'white'} borderRadius={'20px'} p={'12px'} display={'flex'} alignItems={'center'} gap={'4px'}>
                                    <ArrowBackIcon style={{ rotate: '180deg', color: '#FDA058' }} fontSize='large' />
                                    <Grid display={{ xs: 'none', sm: 'block' }}>
                                        <Typography variant='h4' color={'#FDA058'} fontWeight={700}>بازگشت</Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                        :
                        <Grid width={'100%'} mx={'auto'} mt={'30px'} bgcolor={'white'} p={{ xs: '30px 15px', sm: '30px' }} borderRadius={'8px'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'50px'}>
                                <Typography variant='h2' color={'#FDA058'} fontWeight={700}>{'فراموشی رمز عبور'}</Typography>
                            </Grid>
                            <Grid>
                                <Grid mb={'48px'}>
                                    <Grid display={'flex'} alignItems={'center'} flexDirection={'row-reverse'} gap={'8px'} width={'100%'}>
                                        <Grid width={{ xs: '50%', xxs: '70%' }} mb={'0px'}>
                                            <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                <label for="Phone" style={{ marginBottom: '4px', fontSize: '16px' }}>تلفن</label>
                                                <input
                                                    type="number"
                                                    id="Phone"
                                                    name="Phone"
                                                    value={phoneValue}
                                                    onChange={handleChangePhoneValue}
                                                    style={{
                                                        padding: '12px',
                                                        color: '#3C3D37',
                                                        borderRadius: '4px',
                                                        outline: 'none',
                                                        fontSize: '16px',
                                                        height: '50px',
                                                        border: '2px solid rgb(60, 61, 55)'
                                                    }}
                                                />
                                            </form>
                                        </Grid>
                                        <Grid width={{ xs: '50%', xxs: '30%' }} height={'72px'}>
                                            <LoginSelect options={country} />
                                        </Grid>
                                    </Grid>
                                    {phoneError !== null && <Typography variant='subtitle1' color={'#B8001F'} fontWeight={700}>{phoneError}</Typography>}
                                    {countryError !== null && <Typography mt={'4px'} variant='subtitle1' color={'#B8001F'} fontWeight={700}>{countryError}</Typography>}
                                </Grid>


                                <Button onClick={handleForgetPassword} sx={{ bgcolor: '#FDA058', borderRadius: '12px', width: '100%', p: '12px 0px', color: 'white', fontSize: '16px', fontWeight: 700, boxShadow: 5, mb: '36px', '&:hover': { bgcolor: '#FF8343' } }}>
                                    {'بازیابی رمز عبور'}
                                </Button>
                            </Grid>
                            <Link to={'/'}>
                                <Grid position={'fixed'} top={{ xs: '20px', sm: '40px' }} right={{ xs: '20px', sm: '100px' }} bgcolor={'white'} borderRadius={'20px'} p={'12px'} display={'flex'} alignItems={'center'} gap={'4px'}>
                                    <ArrowBackIcon style={{ rotate: '180deg', color: '#FDA058' }} fontSize='large' />
                                    <Grid display={{ xs: 'none', sm: 'block' }}>
                                        <Typography variant='h4' color={'#FDA058'} fontWeight={700}>بازگشت</Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>

                }


            </Grid>
        </>

    )
}

export default ForgetPasswordComponent