import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { AllFormMessageIcon } from './Icons'
import { useNavigate } from 'react-router-dom'

const AllFormMessage = ({ search }) => {


    const navigate = useNavigate()


    const handleCreateForm = () => {
        navigate('/createForm')
    }


    return (
        <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'150px'} width={'100%'} mx={'auto'}>
            <Grid mb={'24px'}>
                {
                    search ?
                        <Grid width={'200px'} height={'200px'}>
                            <img src='./assets/images/notFound.svg' alt='' style={{ width: "100%", height: '100%' }} />
                        </Grid>
                        : <AllFormMessageIcon />

                }
            </Grid>
            {
                search ?
                    <Grid mb={'28px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='h5' color={'#000'} fontWeight={700} mb={'8px'}>فرم مورد نظر شما یافت نشد !</Typography>
                    </Grid>
                    :
                    <Grid mb={'28px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='h5' color={'#000'} fontWeight={700} mb={'8px'}>شما هنوز هیچ فرمی ندارید</Typography>
                        <Typography variant='h5' color={'#545f6f'} fontWeight={500}>فرم های شما در اینجا ظاهر می شود.</Typography>
                    </Grid>
            }

            <Grid width={'100%'} display={search ? 'none' : 'block'}>
                <Button
                    onClick={handleCreateForm}
                    variant='outlined'
                    sx={{
                        width: '100%',
                        bgcolor: '#fff',
                        border: '1px solid #ff6100',
                        color: '#ff6100',
                        fontSize: '16px',
                        p: '8px 0px',
                        "&:hover": {
                            bgcolor: '#ff6100',
                            color: 'white',
                            border: '1px solid #ff6100'
                        }
                    }}
                >
                    فرم ایجاد کنید
                </Button>
            </Grid>
        </Grid>
    )
}

export default AllFormMessage