import React, { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const SubmissionLimitSettingContext = createContext();

export const SubmissionLimitSettingProvider = ({ children }) => {
    const [limitSetting, setLimitSetting] = useLocalStorage('SubmissionLimitSetting', null);

    const handleChangeLimitSetting = (data) => {
        setLimitSetting(data);
    };

    return (
        <SubmissionLimitSettingContext.Provider value={{ limitSetting, handleChangeLimitSetting }}>
            {children}
        </SubmissionLimitSettingContext.Provider>
    );
};

export const useSubmissionLimitSetting = () => {
    return useContext(SubmissionLimitSettingContext);
};
